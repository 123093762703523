export default function routes(userType, pathname) {
  var title;
  var routes;
  routes = [
    { name: "Dashboard", href: "/dashboard", current: false, showInMenu: true },
    { name: "Products", href: "/products", current: false, showInMenu: true },
    {
      name: "Competitors",
      href: "/competitors",
      current: false,
      showInMenu: true,
    },
    {
      name: "Pricing Rules",
      href: "/pricing-rules",
      current: false,
      showInMenu: true,
    },
    // { name: "Reports", href: "/reports", current: false, showInMenu: true },
    {
      name: "Account Confirmation",
      href: "/account-confirmation",
      current: false,
      showInMenu: false,
    },
  ];

  routes.forEach((route) => {
    if (route.href === pathname) {
      route.current = true;

      title = route.name;
    }
  });

  var returnArray = {
    routes: routes.filter((route) => route.showInMenu),
    title: title,
  };

  return returnArray;
}
