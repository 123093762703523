import React, { useEffect } from "react";

import Input from "../../controls/Input";

import { useForm, Form } from "../../useForm";

const initialFValues = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};

export default function PasswordForm(props) {
  const { actionFunc, error, loading } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("newPassword" in fieldValues) {
      temp.newPassword = null;
      if (fieldValues.newPassword === "") {
        temp.newPassword = "This field is required.";
      }

      if (fieldValues.confirmPassword != values.newPassword) {
        temp.confirmPassword = "The two passwords do not match.";
      }
    }

    if ("confirmPassword" in fieldValues) {
      temp.confirmPassword = null;
      if (fieldValues.confirmPassword === "") {
        temp.confirmPassword = "This field is required.";
      }

      if (fieldValues.confirmPassword != values.newPassword) {
        temp.confirmPassword = "The two passwords do not match.";
      }
    }

    if ("currentPassword" in fieldValues) {
      temp.currentPassword = null;
      if (fieldValues.currentPassword === "") {
        temp.currentPassword = "This field is required.";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      actionFunc(values);
    }
  };

  return (
    <Form
      classes="divide-y divide-gray-200 lg:col-span-9"
      onSubmit={handleSubmit}
    >
      <div className="py-6 px-4 sm:p-6 lg:pb-8">
        <div className="mt-6 grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-6">
            <Input
              label="Current password"
              name="currentPassword"
              onChange={handleInputChange}
              value={values.currentPassword}
              labelOn={true}
              type="password"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              error={errors.currentPassword}
            />
          </div>
        </div>
        <div className="mt-6 grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-6">
            <Input
              label="New password"
              name="newPassword"
              onChange={handleInputChange}
              value={values.newPassword}
              labelOn={true}
              type="password"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              error={errors.newPassword}
            />
          </div>
        </div>
        <div className="mt-6 grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-6">
            <Input
              label="Confirm new password"
              name="confirmPassword"
              onChange={handleInputChange}
              value={values.confirmPassword}
              labelOn={true}
              type="password"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              error={errors.confirmPassword}
            />
          </div>
        </div>
      </div>

      <div className="pt-6 divide-y divide-gray-200">
        <div className="grid grid-cols-1">
          <div className="px-4 col-span-1 sm:col-span-1 flex justify-end font-medium text-red-500">
            {error ? error.subtitle : null}
          </div>
          <div className="mt-4 py-4 px-4 sm:px-6 col-span-1 sm:col-span-1 flex justify-end">
            <button
              type="submit"
              className="ml-5 bg-sky-700 border border-gray-300 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white bg-indigo-600 hover:bg-sky-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
              disabled={loading}
            >
              {loading ? (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : null}
              Save
            </button>
          </div>
        </div>
      </div>
    </Form>
  );
}
