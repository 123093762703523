import React, { useEffect, useRef, useState, Fragment } from "react";
import { connect } from "react-redux";
import { ChevronDownIcon, ClockIcon } from "@heroicons/react/solid";
import { PlusIcon, SearchIcon } from "@heroicons/react/outline";
import { Menu, Transition } from "@headlessui/react";
import { makeApiCall } from "../../shared/makeApiCall";

import ErrorModal from "../../components/ModalTemplates/ErrorModal";
import SidePanel from "../../components/SidePanel";
import SuccessNotification from "../../components/SuccessNotification";
import AssignCompetitorForm from "../../components/forms/AssignCompetitorForm";
import ProductQuickEditForm from "../../components/forms/ProductQuickEditForm";
import { updateObjectInArray } from "../../shared/utility";
import AssignPricingRuleToCompetitorForm from "../../components/forms/AssignPricingRuleToCompetitorForm";
import ActionModal from "../../components/ActionModal";

import ReportUrl from "../../components/ModalTemplates/ReportUrl";

import DeleteProductTemplate from "../../components/ModalTemplates/DeleteProductTemplate";
import EmptyModal from "../../components/ModalTemplates/EmptyModal";

import { formatDate } from "../../shared/utility";
import TooltipText from "../../components/TooltipText";
import ImportProductsModal from "../../components/ModalTemplates/ImportProductsModal";
import ImportCompetitorsModal from "../../components/ModalTemplates/ImportCompetitorsModal";
import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const tabs = [
  { name: "Active Products", href: "#" },
  { name: "Archived Products", href: "#" },
];

const Products = (props) => {
  const { checkRequestError, currency, userId } = props;

  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [emptyModal, setEmptyModal] = useState({
    open: false,
    childComponent: null,
  });
  const [sidePanelExtra, setSidePanelExtra] = useState({
    childComponent: null,
  });
  const [errorModalOpen, setErrorModalOpen] = useState({
    open: false,
    title: null,
    subtitle: null,
  });
  const [fileUploadError, setFileUploadError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openSidePanel, setOpenSidePanel] = useState(false);
  const [openActionModal, setOpenActionModal] = useState({
    open: false,
    title: null,
    subtitle: null,
  });
  const [products, setProducts] = useState([]);
  const [rules, setRules] = useState([]);
  const [show, setShow] = useState({ show: false });
  const [submitError, setSubmitError] = useState(null);
  const [totalProductsCountForTab, setTotalProductsCountForTab] = useState(0);

  const searchRef = useRef();

  var pageNumber = 1;
  var searchValue = null;
  var totalPages = 0;

  var isLoading = true;

  const fetchAllProducts = async function (status, refreshProducts) {
    if (refreshProducts) {
      totalPages = 0;
      pageNumber = 1;
      setTotalProductsCountForTab(0);
      setProducts([]);
    }

    if (pageNumber == 1) {
      if (searchValue != null) {
        setTotalProductsCountForTab(0);
        setProducts([]);
      }
    }

    setLoading(true);
    isLoading = true;
    const authData = {
      action: "fetchAllProducts",
      userId: userId,
      search: searchValue,
      pageNumber: pageNumber,
      status: status === 1 ? 3 : 0,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      if (pageNumber > 1) {
        if (callResult.data.products.length > 0) {
          setProducts((oldProducts) => [
            ...oldProducts,
            ...callResult.data.products,
          ]);
        }
      } else {
        totalPages = callResult.data.total;
        setTotalProductsCountForTab(callResult.data.totalProductsForTab);
        setProducts(callResult.data.products);
      }

      setLoading(false);
      isLoading = false;
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle: "We could not fetch the data, please refresh the page.",
        });
        isLoading = false;
        setLoading(false);
      }
    }
  };

  const fetchAllBrands = async function () {
    const authData = {
      action: "fetchAllBrandsForDropdown",
      userId: userId,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setBrands(callResult.data.brands);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle: "We could not fetch the data, please refresh the page.",
        });
      }
    }
  };

  const fetchAllCategories = async function () {
    const authData = {
      action: "fetchAllCategoriesForDropdown",
      userId: userId,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setCategories(callResult.data.categories);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle: "We could not fetch the data, please refresh the page.",
        });
      }
    }
  };

  const fetchAllRules = async function () {
    const authData = {
      action: "fetchAllPricingRulesByUserId",
      userId: userId,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setRules(callResult.data.pricingRules);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle: "We could not fetch the data, please refresh the page.",
        });
      }
    }
  };

  useEffect(() => {
    document.title = "Products";

    fetchAllProducts(0, false);
    fetchAllBrands();
    fetchAllCategories();
    fetchAllRules();
  }, []);

  const deleteProduct = async function (productId) {
    setOpenActionModal({
      ...openActionModal,
      buttonDisabled: true,
      productId: 0,
    });
    const authData = {
      action: "deleteProduct",
      userId: userId,
      productId: productId,
    };
    let callResult = await makeApiCall(authData);

    setOpenActionModal({
      actionFunc: deleteProduct,
      buttonDisabled: false,
      open: false,
      setOpen: setOpenActionModal,
    });

    if (callResult.success) {
      const updatedProducts = products.filter(
        (product) => product.productId !== productId
      );
      setProducts(updatedProducts);
      setTotalProductsCountForTab(totalProductsCountForTab - 1);
      setShow({ show: true, title: "Product deleted." });
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle:
            "We could not sync with your Shopify account, please try again.",
        });
      }
      setErrorModalOpen({
        open: true,
        title: "Sorry!",
        subtitle: "We could not delete the product, please try again.",
      });
    }
  };

  const updateNewProductCompetitor = (productId, newCompetitor, message) => {
    const currentProducts = [...products];
    const prodIndex = products.findIndex(
      (prod) => prod.productId === productId
    );

    if (prodIndex >= 0) {
      const newComp = {
        competitors: [...products[prodIndex].competitors, newCompetitor],
      };
      const payload = {
        index: prodIndex,
        item: newComp,
      };
      const updatedProducts = updateObjectInArray(currentProducts, payload);

      setProducts(updatedProducts);
      setTotalProductsCountForTab(totalProductsCountForTab);
      setOpenSidePanel(false);
      setShow({ show: true, title: message });
    } else {
      setShow({ show: true, title: "We had trouble adding competitor " });
    }
  };

  const updateSingleProductCompetitor = (
    rowId,
    productId,
    competitor,
    message
  ) => {
    const currentProducts = [...products];
    const prodIndex = products.findIndex(
      (prod) => prod.productId === productId
    );

    if (prodIndex >= 0) {
      const currentCompetitors = [...products[prodIndex].competitors];
      const compIndex = products[prodIndex].competitors.findIndex(
        (comp) => comp.urlId === rowId
      );

      const payload = {
        index: compIndex,
        item: competitor,
      };
      const updatedCompetitors = updateObjectInArray(
        currentCompetitors,
        payload
      );

      if (compIndex >= 0) {
        const prodPayload = {
          index: prodIndex,
          item: { competitors: updatedCompetitors },
        };

        const updatedProducts = updateObjectInArray(
          currentProducts,
          prodPayload
        );

        setProducts(updatedProducts);
        setTotalProductsCountForTab(totalProductsCountForTab);
        setShow({ show: true, title: message });
      } else {
        setShow({ show: true, title: "Competitor update failed." });
      }
    } else {
      setShow({ show: true, title: "Competitor update failed." });
    }
    setOpenSidePanel(false);
    setSidePanelExtra({ childComponent: null });
  };

  const scrapeSingleProduct = async function (productId, isUpdateApi) {
    const authData = {
      action: "scrapeSingleProduct",
      userId: userId,
      productId: productId,
      isUpdateApi: isUpdateApi,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setEmptyModal({
        childComponent: null,
        open: false,
      });
      setShow({ show: true, title: callResult.data.result });
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle:
            "We could not sync with your Shopify account, please try again.",
        });
      }
      setEmptyModal({ open: false });
      setErrorModalOpen({
        open: true,
        title: "Sorry!",
        subtitle: "We could not queue the audit, please try again.",
      });
    }
  };

  const updateProduct = (prodId, product) => {
    const currentProducts = [...products];

    const prodIndex = products.findIndex((prod) => prod.productId === prodId);

    const payload = {
      index: prodIndex,
      item: product,
    };
    const updatedProducts = updateObjectInArray(currentProducts, payload);

    setProducts(updatedProducts);
    setTotalProductsCountForTab(totalProductsCountForTab);
  };

  const setNewCompetitorsForProduct = (productId, competitors) => {
    const currentProducts = [...products];
    const prodIndex = products.findIndex(
      (prod) => prod.productId === productId
    );

    const newComp = {
      competitors: competitors,
    };
    const payload = {
      index: prodIndex,
      item: newComp,
    };
    const updatedProducts = updateObjectInArray(currentProducts, payload);

    setProducts(updatedProducts);
    setTotalProductsCountForTab(totalProductsCountForTab);
  };

  const deleteAssignedCompetitor = async function (rowId, urlId) {
    setOpenActionModal({
      ...openActionModal,
      buttonDisabled: true,
      itemId: 0,
    });
    const authData = {
      action: "deleteAssignedCompetitor",
      userId: userId,
      urlId: urlId,
    };
    let callResult = await makeApiCall(authData);

    setOpenActionModal({
      buttonDisabled: false,
      open: false,
      setOpen: setOpenActionModal,
    });

    if (callResult.success) {
      const productId = products[rowId].productId;
      const tempCompetitors = products[rowId].competitors;

      const updatedCompetitors = tempCompetitors.filter(
        (competitor) => competitor.urlId !== urlId
      );

      setNewCompetitorsForProduct(productId, updatedCompetitors);
      setShow({ show: true, title: "Competitor deleted." });
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle:
            "We could not sync with your Shopify account, please try again.",
        });
      }
      setErrorModalOpen({
        open: true,
        title: "Sorry!",
        subtitle: "We could not delete the competitor, please try again.",
      });
    }
  };

  const handleSearch = () => {
    searchValue = searchRef.current.value;

    pageNumber = 1;
    totalPages = 1;
    if (searchValue.length >= 0 || searchValue.length >= 3) {
      fetchAllProducts(currentTab, false);
    }
  };

  const handleScroll = () => {
    const bottom =
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.scrollHeight;

    if (bottom) {
      if (!isLoading && pageNumber < totalPages) {
        pageNumber++;
        fetchAllProducts(currentTab, false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, {
      passive: true,
    });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const archivedProductSuccessfully = (productId, message) => {
    setOpenSidePanel(false);
    const updatedProducts = products.filter(
      (product) => product.productId !== productId
    );
    setProducts(updatedProducts);
    setTotalProductsCountForTab(totalProductsCountForTab - 1);
    setShow({ show: true, title: message });
  };

  const refreshProductsList = () => {
    setProducts([]);
    setTotalProductsCountForTab(0);
    pageNumber = 1;
    searchValue = null;

    fetchAllProducts(currentTab, false);
    fetchAllBrands();
    fetchAllCategories();
    fetchAllRules();
  };

  return (
    <div className="bg-gray-50">
      {/* <AdminLayout /> */}
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div className="flex px-4 sm:px-12">
            <h1 className="text-3xl font-bold text-gray-900">Products</h1>
          </div>
          <div className="sm:flex-no-wrap px-4 sm:px-12">
            <div className="my-auto flex items-center">
              <TooltipText
                explanation="All the products in your account will be automatically audited. If you want to learn more, go to Settings -> Int"
                text="Account audit status: All"
              />
              <button
                type="button"
                className="relative inline-flex items-center mr-2 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-400 hover:bg-green-500 ml-2"
                onClick={() => {
                  refreshProductsList();
                }}
              >
                Refresh
              </button>
            </div>
          </div>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          {/* Tabs starts here */}
          <div className="grid grid-cols-2">
            <div className="sm:hidden sm:col-span-2">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              <select
                id="tabs"
                name="tabs"
                className="block w-full border-gray-300 rounded-md"
                defaultValue={tabs[currentTab].name}
              >
                {tabs.map((tab, index) => (
                  <option key={index}>
                    {index === currentTab
                      ? tab.name + " (" + totalProductsCountForTab + ")"
                      : tab.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block sm:col-span-1">
              <nav
                className="relative z-0 flex divide-x divide-gray-200"
                aria-label="Tabs"
              >
                {tabs.map((tab, index) => (
                  <a
                    key={tab.name}
                    href={tab.href}
                    className={classNames(
                      index === currentTab
                        ? "text-gray-900"
                        : "text-gray-500 hover:text-gray-700 bg-gray-100",
                      index === 0 ? "" : "",
                      index === tabs.length - 1 ? "" : "",
                      "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center focus:z-10"
                    )}
                    aria-current={index === currentTab ? "page" : undefined}
                    onClick={() => {
                      if (loading) {
                        return;
                      } else {
                        setProducts([]);
                        setTotalProductsCountForTab(0);
                        setLoading(true);
                        setCurrentTab(index);
                        fetchAllProducts(index, false);
                      }
                    }}
                  >
                    <span>
                      {index === currentTab
                        ? tab.name + " (" + totalProductsCountForTab + ")"
                        : tab.name}
                    </span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        index === currentTab ? "" : "bg-transparent",
                        "absolute inset-x-0 bottom-0 h-0.5"
                      )}
                    />
                  </a>
                ))}
              </nav>
            </div>
          </div>
          {/* Tabs end here */}
          {/* Start here */}
          <div className="px-4 sm:px-0">
            <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-12 rounded-xl">
              <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                <div className="ml-4 mt-2">
                  {/* search starts */}
                  <div className="mt-1 flex rounded-md">
                    <div className="relative flex items-stretch flex-grow focus-within:z-10">
                      <input
                        type="search"
                        name="searchProduct"
                        id="searchProduct"
                        className="block w-full rounded-lg rounded-l-md pl-2 sm:text-md border border-gray-300 rounded-tr-none rounded-br-none"
                        placeholder="Search"
                        onChange={handleSearch}
                        ref={searchRef}
                      />
                    </div>
                    <button
                      type="button"
                      className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1"
                      onClick={() => handleSearch()}
                    >
                      <SearchIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                  {/* search ends */}
                </div>
                <div className="ml-4 mt-2 flex-shrink-0">
                  <button
                    type="button"
                    className="relative inline-flex items-center mr-2 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                    onClick={() => {
                      setSidePanelExtra({
                        childComponent: (
                          <ProductQuickEditForm
                            checkRequestError={checkRequestError}
                            brands={brands}
                            categories={categories}
                            currency={currency}
                            fetchAllBrands={fetchAllBrands}
                            fetchAllCategories={fetchAllCategories}
                            isEdit={false}
                            product={null}
                            products={products}
                            refreshProductsList={refreshProductsList}
                            setOpen={setOpenSidePanel}
                            setOpenActionModal={setOpenActionModal}
                            setOpenSidePanel={setOpenSidePanel}
                            setShow={setShow}
                            setSidePanelExtra={setSidePanelExtra}
                            subtitle="Fill in the information below to add a new product."
                            title="Add new product"
                            updateProduct={updateProduct}
                            userId={userId}
                          />
                        ),
                      });
                      setOpenSidePanel(true);
                    }}
                  >
                    Add product
                  </button>

                  <span className="relative inline-flex shadow-sm rounded-md mr-2">
                    <Menu as="span" className="-ml-px relative block">
                      <Menu.Button className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-400 hover:bg-green-500">
                        <span className="sr-only">Open options</span>
                        Import Products
                        <ChevronDownIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="cursor-pointer origin-top-right absolute right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-150">
                          <div className="py-1 z-150">
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-700",
                                    "block px-4 py-2 text-sm "
                                  )}
                                  onClick={() => {
                                    setOpenActionModal({
                                      open: true,
                                      setOpen: setOpenActionModal,
                                      childComponent: (
                                        <ImportProductsModal
                                          fetchAllProducts={fetchAllProducts}
                                          importType={0}
                                          open={openActionModal}
                                          setOpenActionModal={
                                            setOpenActionModal
                                          }
                                          setShow={setShow}
                                          userId={userId}
                                        />
                                      ),
                                    });
                                  }}
                                >
                                  Import products
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-700",
                                    "block px-4 py-2 text-sm "
                                  )}
                                  onClick={() => {
                                    setOpenActionModal({
                                      open: true,
                                      setOpen: setOpenActionModal,
                                      childComponent: (
                                        <ImportProductsModal
                                          fetchAllProducts={fetchAllProducts}
                                          importType={1}
                                          open={openActionModal}
                                          setOpenActionModal={
                                            setOpenActionModal
                                          }
                                          setShow={setShow}
                                          userId={userId}
                                        />
                                      ),
                                    });
                                  }}
                                >
                                  Import costs only
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-700",
                                    "block px-4 py-2 text-sm "
                                  )}
                                  onClick={() => {
                                    setOpenActionModal({
                                      open: true,
                                      setOpen: setOpenActionModal,
                                      childComponent: (
                                        <ImportProductsModal
                                          fetchAllProducts={fetchAllProducts}
                                          importType={2}
                                          open={openActionModal}
                                          setOpenActionModal={
                                            setOpenActionModal
                                          }
                                          setShow={setShow}
                                          userId={userId}
                                        />
                                      ),
                                    });
                                  }}
                                >
                                  Import competitors
                                </a>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* table starts */}
          <div className="px-4 py-6 sm:px-0 rounded-xl">
            {loading && products.length == 0 ? (
              <>
                <div className="border h-40 shadow rounded-md p-4 w-full mx-auto">
                  <div className="animate-pulse flex space-x-4"></div>
                </div>
                <div className="border h-40 mt-5 shadow rounded-md p-4 w-full mx-auto">
                  <div className="animate-pulse flex space-x-4"></div>
                </div>
                <div className="border h-40 mt-5 shadow rounded-md p-4 w-full mx-auto">
                  <div className="animate-pulse flex space-x-4"></div>
                </div>
                <div className="border h-40 mt-5 shadow rounded-md p-4 w-full mx-auto">
                  <div className="animate-pulse flex space-x-4"></div>
                </div>
              </>
            ) : products.length > 0 ? (
              products.map((product, productIndex) => (
                <div
                  key={product.productId}
                  className="bg-white mb-6 px-4 py-5 border-b border-gray-200 sm:px-12"
                >
                  <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                    <div className="ml-4 mt-4">
                      <h3 className="text-2xl leading-6 font-medium text-gray-900">
                        {product.name}
                      </h3>
                      <div className="mt-3 flex">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Dynamic Price: {product.dynamicPrice}
                        </h3>
                      </div>{" "}
                      <div className="mt-1 flex">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Last audited:{" "}
                          <font className="text-gray-700">
                            {formatDate(product.addedDate)}
                          </font>
                        </h3>
                      </div>
                    </div>
                    <div className="ml-4 mt-4 flex-shrink-0">
                      <span className="relative inline-flex shadow-sm rounded-md">
                        <button
                          className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
                          onClick={() => {
                            setSidePanelExtra({
                              childComponent: (
                                <ProductQuickEditForm
                                  archivedProductSuccessfully={
                                    archivedProductSuccessfully
                                  }
                                  checkRequestError={checkRequestError}
                                  brands={brands}
                                  categories={categories}
                                  fetchAllBrands={fetchAllBrands}
                                  fetchAllCategories={fetchAllCategories}
                                  isEdit={true}
                                  product={product}
                                  products={products}
                                  refreshProductsList={refreshProductsList}
                                  setOpenSidePanel={setOpenSidePanel}
                                  setOpen={setOpenSidePanel}
                                  setShow={setShow}
                                  setSidePanelExtra={setSidePanelExtra}
                                  subtitle=""
                                  title={"Editing " + product.name}
                                  updateProduct={updateProduct}
                                  userId={userId}
                                />
                              ),
                            });
                            setOpenSidePanel(true);
                          }}
                        >
                          Quick edit
                        </button>
                        <Menu as="span" className="-ml-px relative block">
                          <Menu.Button className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none">
                            <span className="sr-only">Open options</span>
                            <ChevronDownIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="cursor-pointer origin-top-right absolute right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-150">
                              <div className="py-1 z-150">
                                <Menu.Item>
                                  {({ active }) => (
                                    <Link
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "block px-4 py-2 text-sm "
                                      )}
                                      to={"/products/" + product.productId}
                                    >
                                      Edit
                                    </Link>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "block px-4 py-2 text-sm "
                                      )}
                                      onClick={() => {
                                        setSidePanelExtra({
                                          childComponent: (
                                            <AssignCompetitorForm
                                              checkRequestError={
                                                checkRequestError
                                              }
                                              isEdit={false}
                                              product={{
                                                productId: product.productId,
                                              }}
                                              setShow={setOpenSidePanel}
                                              setOpenSidePanel={
                                                setOpenSidePanel
                                              }
                                              setOpen={setOpenSidePanel}
                                              submitError={submitError}
                                              subtitle="Fill in the information below to add a competitor."
                                              title={
                                                "Add new competitor to " +
                                                product.name
                                              }
                                              updateProductCompetitors={
                                                updateNewProductCompetitor
                                              }
                                              updateSingleProductCompetitor={
                                                updateSingleProductCompetitor
                                              }
                                              userId={userId}
                                            />
                                          ),
                                        });
                                        setOpenSidePanel(true);
                                      }}
                                    >
                                      Add Competitor
                                    </a>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "block px-4 py-2 text-sm "
                                      )}
                                      onClick={() => {
                                        setOpenActionModal({
                                          open: true,
                                          setOpen: setOpenActionModal,
                                          childComponent: (
                                            <ImportCompetitorsModal
                                              open={openActionModal}
                                              productId={product.productId}
                                              setNewCompetitorsForProduct={
                                                setNewCompetitorsForProduct
                                              }
                                              setOpenActionModal={
                                                setOpenActionModal
                                              }
                                              setShow={setShow}
                                              userId={userId}
                                            />
                                          ),
                                        });
                                      }}
                                    >
                                      Import competitors
                                    </a>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "block px-4 py-2 text-sm "
                                      )}
                                      onClick={() => {
                                        setOpenActionModal({
                                          open: true,
                                          setOpen: setOpenActionModal,
                                          childComponent: (
                                            <DeleteProductTemplate
                                              actionFunc={deleteProduct}
                                              disableSubmitButton={
                                                disableSubmitButton
                                              }
                                              itemId={product.productId}
                                              setOpen={setOpenActionModal}
                                              buttonDisabled={false}
                                              buttonMessage={"Delete"}
                                              message={
                                                "Type delete below to delete " +
                                                product.name +
                                                ". This action cannot be undone."
                                              }
                                            />
                                          ),
                                        });
                                      }}
                                    >
                                      Delete product
                                    </a>
                                  )}
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </span>
                      <Link
                        type="button"
                        className="ml-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                        to={
                          "/products/" +
                          product.productId +
                          "?redirect=audit-logs"
                        }
                      >
                        View latest audit
                      </Link>
                    </div>
                  </div>
                  {/* table starts */}
                  <div className="flex flex-col pt-6">
                    <div className="-my-2 overflow-x-visible sm:-mx-6 lg:-mx-8">
                      <div className="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-visible border-b border-gray-200 sm:rounded-lg  w-full">
                          <table className="w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50 break-words">
                              <tr>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  style={{ width: "25%" }}
                                >
                                  Competitor
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  style={{ width: "10%" }}
                                >
                                  Price
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  style={{ width: "10%" }}
                                >
                                  Change
                                </th>
                                {/* <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  style={{ width: "7.5%" }}
                                >
                                  Position
                                </th> */}
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  style={{ width: "7.5%" }}
                                >
                                  Stock
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  style={{ width: "15%" }}
                                >
                                  Pricing Rule
                                </th>
                                <th scope="col" className="relative px-6 py-3">
                                  <span className="sr-only">Edit</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                              {product.competitors.map((competitor) => (
                                <tr key={competitor.urlId}>
                                  <td
                                    className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                                    style={{ width: "25%" }}
                                  >
                                    <a
                                      href={competitor.competitorUrl}
                                      target="_blank"
                                    >
                                      {competitor.parentDomain}
                                    </a>
                                  </td>

                                  <td
                                    colSpan="3"
                                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center"
                                    style={{
                                      display:
                                        competitor.domainStatus === "0"
                                          ? "table-cell"
                                          : "none",
                                      width: "55%",
                                    }}
                                  >
                                    Domain is being configured, it will be
                                    audited automatically once configured.
                                  </td>

                                  <td
                                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                                    colSpan="1"
                                    style={{
                                      display:
                                        competitor.domainStatus === "0"
                                          ? "none"
                                          : "table-cell",
                                      width: "10%",
                                    }}
                                  >
                                    {competitor.scrapedPrice === null ? (
                                      competitor.scrapeError ? (
                                        competitor.scrapeError
                                      ) : (
                                        <ClockIcon
                                          className="mt-2 h-6 w-6 text-indigo-700"
                                          aria-hidden="true"
                                        />
                                      )
                                    ) : (
                                      competitor.scrapedPrice
                                    )}
                                  </td>
                                  <td
                                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                                    colSpan="1"
                                    style={{
                                      display:
                                        competitor.domainStatus === "0"
                                          ? "none"
                                          : "table-cell",
                                      width: "10%",
                                    }}
                                  >
                                    {competitor.compare === null ? (
                                      <ClockIcon
                                        className="mt-2 h-6 w-6 text-indigo-700"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      competitor.compare + "%"
                                    )}
                                  </td>
                                  {/* <td
                                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                                    colSpan="1"
                                    style={{
                                      display:
                                        competitor.domainStatus === "0"
                                          ? "none"
                                          : "table-cell",
                                      width: "7.5%",
                                    }}
                                  >
                                    {competitor.position === null ? (
                                      <ClockIcon
                                        className="mt-2 h-6 w-6 text-indigo-700"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      competitor.position
                                    )}
                                  </td> */}
                                  <td
                                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                                    colSpan="1"
                                    style={{
                                      display:
                                        competitor.domainStatus === "0"
                                          ? "none"
                                          : "table-cell",
                                      width: "7.5%",
                                    }}
                                  >
                                    {competitor.stock === null ? (
                                      <ClockIcon
                                        className="mt-2 h-6 w-6 text-indigo-700"
                                        aria-hidden="true"
                                      />
                                    ) : competitor.stock === "1" ? (
                                      "In stock"
                                    ) : (
                                      "Out of stock"
                                    )}
                                  </td>

                                  <td
                                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                                    colSpan="1"
                                    style={{ width: "15%" }}
                                  >
                                    {competitor.pr_product_ruleId > 0 ? (
                                      <TooltipText
                                        explanation={
                                          competitor.prod_rule_explanation
                                        }
                                        text={competitor.pr_product_name}
                                      />
                                    ) : competitor.pr_competitor_ruleId > 0 ? (
                                      <TooltipText
                                        explanation={
                                          competitor.comp_rule_explanation
                                        }
                                        text={competitor.pr_competitor_name}
                                      />
                                    ) : (
                                      <font className="text-red-500 font-medium">
                                        Pricing Rule not yet set
                                      </font>
                                    )}
                                  </td>
                                  <td
                                    className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                                    style={{ width: "10%" }}
                                  >
                                    <div className="ml-2 h-7 flex items-center">
                                      <span className="relative inline-flex shadow-sm rounded-md">
                                        <button
                                          className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
                                          onClick={() => {
                                            setSidePanelExtra({
                                              childComponent: (
                                                <AssignCompetitorForm
                                                  checkRequestError={
                                                    checkRequestError
                                                  }
                                                  isEdit={true}
                                                  product={competitor}
                                                  setShow={setOpenSidePanel}
                                                  setOpenSidePanel={
                                                    setOpenSidePanel
                                                  }
                                                  setOpen={setOpenSidePanel}
                                                  submitError={submitError}
                                                  subtitle="Fill in the information below to edit competitor."
                                                  title={
                                                    "Editing " +
                                                    competitor.parentDomain
                                                  }
                                                  updateProductCompetitors={
                                                    updateNewProductCompetitor
                                                  }
                                                  updateSingleProductCompetitor={
                                                    updateSingleProductCompetitor
                                                  }
                                                  userId={userId}
                                                />
                                              ),
                                            });
                                            setOpenSidePanel(true);
                                          }}
                                        >
                                          Edit
                                        </button>
                                        <Menu
                                          as="span"
                                          className="-ml-px relative block"
                                        >
                                          <Menu.Button className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none">
                                            <span className="sr-only">
                                              Open options
                                            </span>
                                            <ChevronDownIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </Menu.Button>
                                          <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                          >
                                            <Menu.Items className="cursor-pointer origin-top-right absolute right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-150">
                                              <div className="py-1 z-150 text-left">
                                                <Menu.Item>
                                                  {({ active }) => (
                                                    <a
                                                      className={classNames(
                                                        active
                                                          ? "bg-gray-100 text-gray-900"
                                                          : "text-gray-700",
                                                        "block px-4 py-2 text-sm"
                                                      )}
                                                      onClick={() => {
                                                        setSubmitError(null);
                                                        setSidePanelExtra({
                                                          childComponent: (
                                                            <AssignPricingRuleToCompetitorForm
                                                              checkRequestError={
                                                                checkRequestError
                                                              }
                                                              competitorName={
                                                                competitor.parentDomain
                                                              }
                                                              currency={
                                                                currency
                                                              }
                                                              disableSubmitButton={
                                                                disableSubmitButton
                                                              }
                                                              fetchAllRules={
                                                                fetchAllRules
                                                              }
                                                              fromProductsPage={
                                                                true
                                                              }
                                                              isEdit={
                                                                competitor.pr_product_ruleId >
                                                                0
                                                              }
                                                              competitor={{
                                                                rowId:
                                                                  competitor.urlId,
                                                                itemType: 0,
                                                                itemId:
                                                                  competitor.urlId,
                                                                ruleId:
                                                                  competitor.pr_product_ruleId,
                                                                explanation:
                                                                  competitor.prod_rule_explanation,
                                                                ruleAssignId:
                                                                  competitor.prodRuleAssignId,
                                                                assignLevel: 1,
                                                                assignItemId:
                                                                  product.productId,
                                                                ruleArray:
                                                                  competitor.ruleArray,
                                                                competitorId:
                                                                  competitor.competitorId,
                                                              }}
                                                              rules={rules}
                                                              setOpen={
                                                                setOpenSidePanel
                                                              }
                                                              submitError={
                                                                submitError
                                                              }
                                                              subtitle="Pricing rules affect the dynamic price of a product on a competitor level."
                                                              title={
                                                                "Assign a pricing rule to " +
                                                                product.name
                                                              }
                                                              updateSingleProductCompetitor={
                                                                updateSingleProductCompetitor
                                                              }
                                                              userId={userId}
                                                            />
                                                          ),
                                                          panelSize: "2xl",
                                                        });
                                                        setOpenSidePanel(true);
                                                      }}
                                                    >
                                                      {competitor.pr_product_ruleId >
                                                        0 ||
                                                      competitor.pr_competitor_ruleId >
                                                        0
                                                        ? "Edit rule"
                                                        : "Assign rule"}
                                                    </a>
                                                  )}
                                                </Menu.Item>
                                                <Menu.Item>
                                                  {({ active }) => (
                                                    <a
                                                      className={classNames(
                                                        active
                                                          ? "bg-gray-100 text-gray-900"
                                                          : "text-gray-700",
                                                        "block px-4 py-2 text-sm"
                                                      )}
                                                      disabled={
                                                        !competitor.scrapeId > 0
                                                      }
                                                      onClick={() => {
                                                        setEmptyModal({
                                                          childComponent: (
                                                            <ReportUrl
                                                              checkRequestError={
                                                                checkRequestError
                                                              }
                                                              explanation="Report this url if you its not auditing properly, please explain the issue in a few sentences below for us."
                                                              itemId={
                                                                competitor.urlId
                                                              }
                                                              setOpen={
                                                                setEmptyModal
                                                              }
                                                              setShow={setShow}
                                                              title="Report url"
                                                              userId={userId}
                                                            />
                                                          ),
                                                          open: true,
                                                        });
                                                      }}
                                                    >
                                                      Report url
                                                    </a>
                                                  )}
                                                </Menu.Item>
                                                <Menu.Item>
                                                  {({ active }) => (
                                                    <a
                                                      className={classNames(
                                                        active
                                                          ? "bg-gray-100 text-gray-900"
                                                          : "text-gray-700",
                                                        "block px-4 py-2 text-sm"
                                                      )}
                                                      onClick={() => {
                                                        setOpenActionModal({
                                                          open: true,
                                                          setOpen:
                                                            setOpenActionModal,
                                                          childComponent: (
                                                            <DeleteProductTemplate
                                                              actionFunc={
                                                                deleteAssignedCompetitor
                                                              }
                                                              disableSubmitButton={
                                                                disableSubmitButton
                                                              }
                                                              index={
                                                                productIndex
                                                              }
                                                              itemId={
                                                                competitor.urlId
                                                              }
                                                              setOpen={
                                                                setOpenActionModal
                                                              }
                                                              buttonDisabled={
                                                                false
                                                              }
                                                              buttonMessage={
                                                                "Delete"
                                                              }
                                                              message={
                                                                "Type delete below to delete " +
                                                                competitor.parentDomain +
                                                                " as competitor from " +
                                                                product.name +
                                                                "."
                                                              }
                                                            />
                                                          ),
                                                        });
                                                      }}
                                                    >
                                                      Delete competitor
                                                    </a>
                                                  )}
                                                </Menu.Item>
                                              </div>
                                            </Menu.Items>
                                          </Transition>
                                        </Menu>
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center">
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>
                <h3 className="mt-2 text-sm font-medium text-gray-900">
                  No products
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Get started by adding a new product
                </p>
                <div className="mt-6">
                  <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                    onClick={() => {
                      setSidePanelExtra({
                        childComponent: (
                          <ProductQuickEditForm
                            checkRequestError={checkRequestError}
                            brands={brands}
                            categories={categories}
                            fetchAllBrands={fetchAllBrands}
                            fetchAllCategories={fetchAllCategories}
                            isEdit={false}
                            product={null}
                            products={products}
                            refreshProductsList={refreshProductsList}
                            setOpen={setOpenSidePanel}
                            setOpenActionModal={setOpenActionModal}
                            setOpenSidePanel={setOpenSidePanel}
                            setShow={setShow}
                            setSidePanelExtra={setSidePanelExtra}
                            subtitle="Fill in the information below to add a new product."
                            title="Add new product"
                            updateProduct={updateProduct}
                            userId={userId}
                          />
                        ),
                      });
                      setOpenSidePanel(true);
                    }}
                  >
                    <PlusIcon
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    Add product
                  </button>
                </div>
              </div>
            )}
          </div>
          {/* table ends */}
          {loading && products.length > 0 ? (
            <div>
              <div className="border h-40 shadow rounded-md p-4 w-full mx-auto">
                <div className="animate-pulse flex space-x-4"></div>
              </div>
            </div>
          ) : null}
        </div>
        <SidePanel
          open={openSidePanel}
          setOpen={setOpenSidePanel}
          childComponent={sidePanelExtra.childComponent}
          size={sidePanelExtra.size}
        />
        <ErrorModal
          open={errorModalOpen.open}
          title={errorModalOpen.title}
          setOpen={setErrorModalOpen}
          subtitle={errorModalOpen.subtitle}
        />
        <SuccessNotification
          show={show.show}
          setShow={setShow}
          title={show.title}
        />
        <ActionModal
          childComponent={openActionModal.childComponent}
          open={openActionModal.open}
          setOpen={setOpenActionModal}
        />
        <EmptyModal
          childComponent={emptyModal.childComponent}
          open={emptyModal.open}
          setOpen={setEmptyModal}
        />
      </main>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currency: state.auth.currencyLabel,
    userId: state.auth.userId,
  };
};

export default connect(mapStateToProps)(Products);
