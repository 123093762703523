import * as actionTypes from "./actionTypes";

import { config } from "../../constants";

import axiosRegular from "../../axios";
import axios from "axios";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (
  jwt,
  refreshToken,
  expirationDate,
  userId,
  fname,
  lname,
  email,
  currency,
  currencyLabel,
  minValue,
  website,
  timezone,
  scrapeAllProductsEnabled,
  showOnboarding,
  userStatus,
  userType,
  stripePriceId
) => {
  localStorage.setItem("jwt", jwt);
  localStorage.setItem("refreshToken", refreshToken);
  localStorage.setItem("expirationDate", expirationDate);
  localStorage.setItem("userId", userId);
  localStorage.setItem("fname", fname);
  localStorage.setItem("lname", lname);
  localStorage.setItem("email", email);
  localStorage.setItem("currency", currency);
  localStorage.setItem("currencyLabel", currencyLabel);
  localStorage.setItem("minValue", minValue);
  localStorage.setItem("website", website);
  localStorage.setItem("timezone", timezone);
  localStorage.setItem("scrapeAllProductsEnabled", scrapeAllProductsEnabled);
  localStorage.setItem("userStatus", userStatus);
  localStorage.setItem("userType", userType);
  localStorage.setItem("stripePriceId", stripePriceId);

  return {
    type: actionTypes.AUTH_SUCCESS,
    jwt: jwt,
    refreshToken: refreshToken,
    userId: userId,
    fname: fname,
    lname: lname,
    email: email,
    currency: currency,
    currencyLabel: currencyLabel,
    minValue: minValue,
    website: website,
    timezone: timezone,
    scrapeAllProductsEnabled: scrapeAllProductsEnabled,
    showOnboarding: showOnboarding,
    userStatus: userStatus,
    userType: userType,
    stripePriceId: stripePriceId,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const requestFailed = (error) => {
  return {
    type: actionTypes.REQUEST_FAILED,
    error: error,
  };
};

export const logout = () => {
  localStorage.removeItem("jwt");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("expirationDate");
  localStorage.removeItem("userId");
  localStorage.removeItem("fname");
  localStorage.removeItem("lname");
  localStorage.removeItem("email");
  localStorage.removeItem("currency");
  localStorage.removeItem("currencyLabel");
  localStorage.removeItem("minValue");
  localStorage.removeItem("website");
  localStorage.removeItem("timezone");
  localStorage.removeItem("scrapeAllProductsEnabled");
  localStorage.removeItem("userStatus");
  localStorage.removeItem("userType");
  localStorage.removeItem("stripePriceId");

  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const checkAuthTimeout = (expirationDateout) => {
  //   return (dispatch) => {
  //     setTimeout(() => {
  //       dispatch(logout());
  //     }, expirationDateout * 1000);
  //   };
};

export const auth = (email, password, isForgotPassword) => {
  return (dispatch) => {
    dispatch(authStart());
    const authData = {
      email: email,
      password: password,
      action: isForgotPassword ? "forgotPassword" : "login",
    };
    let url = config.url.API_URL + "/apiEndPoint.php";

    axios
      .post(url, authData)
      .then((response) => {
        const expirationDate = new Date(
          new Date().getTime() + response.data.expiresIn * 1000
        );

        dispatch(
          authSuccess(
            response.data.jwt,
            response.data.refreshToken,
            response.data.expirationDate,
            response.data.userId,
            response.data.fname,
            response.data.lname,
            response.data.email,
            response.data.currency,
            response.data.currencyLabel,
            response.data.minValue,
            response.data.website,
            response.data.timezone,
            response.data.scrapeAllProductsEnabled,
            0,
            response.data.userStatus,
            response.data.userType,
            response.data.stripePriceId
          )
        );
      })
      .catch((err) => {
        dispatch(authFail(err.response));
      });
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const jwt = localStorage.getItem("jwt");
    const refreshToken = localStorage.getItem("refreshToken");
    if (!jwt) {
      console.log("authCheckState caused logout");
      dispatch(logout());
    } else {
      const expirationDate = new Date(localStorage.getItem("expirationDate"));
      // if (expirationDate <= new Date()) {
      //   dispatch(logout());
      // } else {
      const userId = localStorage.getItem("userId");
      const fname = localStorage.getItem("fname");
      const lname = localStorage.getItem("lname");
      const email = localStorage.getItem("email");
      const currency = localStorage.getItem("currency");
      const currencyLabel = localStorage.getItem("currencyLabel");
      const minValue = localStorage.getItem("minValue");
      const website = localStorage.getItem("website");
      const timezone = localStorage.getItem("timezone");
      const scrapeAllProductsEnabled = localStorage.getItem(
        "scrapeAllProductsEnabled"
      );
      const userStatus = localStorage.getItem("userStatus");
      const userType = localStorage.getItem("userType");
      const stripePriceId = localStorage.getItem("stripePriceId");

      dispatch(
        authSuccess(
          jwt,
          refreshToken,
          expirationDate,
          userId,
          fname,
          lname,
          email,
          currency,
          currencyLabel,
          minValue,
          website,
          timezone,
          scrapeAllProductsEnabled,
          0,
          userStatus,
          userType,
          stripePriceId
        )
      );
      // dispatch(
      //   checkAuthTimeout(
      //     (expirationDate.getTime() - new Date().getTime()) / 1000
      //   )
      // );
      // }
    }
  };
};

export const myAccountUpdateRequestStart = () => {
  return {
    type: actionTypes.MY_ACCOUNT_UPDATE_REQUEST_START,
  };
};

export const myAccountUpdateRequestSuccess = (data) => {
  return {
    type: actionTypes.MY_ACCOUNT_UPDATE_REQUEST_SUCCESS,
    data: data,
  };
};

export const makeMyAccountUpdateRequest = (userId, jwt, data) => {
  return (dispatch) => {
    dispatch(myAccountUpdateRequestStart());
    const authData = {
      userId: userId,
      jwt: jwt,
      data: data,
      action: "myAccountUpdate",
    };
    let url = config.url.API_URL + "/apiEndPoint.php";
    axios
      .post(url, authData)
      .then((response) => {
        localStorage.setItem("fname", response.data.returnData.fname);
        localStorage.setItem("lname", response.data.returnData.lname);
        localStorage.setItem("email", response.data.returnData.email);
        localStorage.setItem("website", response.data.returnData.website);
        localStorage.setItem("timezone", response.data.returnData.timezone);
        localStorage.setItem(
          "scrapeAllProductsEnabled",
          response.data.returnData.scrapeAllProductsEnabled
        );
        localStorage.setItem("userStatus", response.data.returnData.userStatus);
        localStorage.setItem("userType", response.data.returnData.userType);
        localStorage.setItem(
          "stripePriceId",
          response.data.returnData.stripePriceId
        );

        dispatch(myAccountUpdateRequestSuccess(response.data));
      })
      .catch((err) => {
        dispatch(requestFailed(err.response));
      });
  };
};

export const onUpdateCurrencyRequestSuccess = (data) => {
  return {
    type: actionTypes.ON_UPDATE_CURRENCY_SUCCESS,
    data: data,
  };
};

export const onUpdateCurrencyRequest = (currency, currencyLabel) => {
  return (dispatch) => {
    localStorage.setItem("currency", currency);
    localStorage.setItem("currencyLabel", currencyLabel);

    const data = {
      currency,
      currencyLabel,
    };
    dispatch(onUpdateCurrencyRequestSuccess(data));
  };
};

export const makeBillingUpdateRequest = (userId, jwt, data) => {};

export const makeIntegrationsUpdateRequestStart = () => {
  return {
    type: actionTypes.INTEGRATIONS_UPDATE_REQUEST_START,
  };
};

export const makeIntegrationsUpdateRequestSuccess = (data) => {
  return {
    type: actionTypes.INTEGRATIONS_UPDATE_REQUEST_SUCCESS,
    data: data,
  };
};

export const makePasswordUpdateRequestStart = () => {
  return {
    type: actionTypes.PASSWORD_UPDATE_REQUEST_START,
  };
};

export const makePasswordUpdateRequestSuccess = (data) => {
  return {
    type: actionTypes.PASSWORD_UPDATE_REQUEST_SUCCESS,
    data: data,
  };
};

export const makePasswordUpdateRequest = (userId, jwt, data) => {
  return (dispatch) => {
    dispatch(makePasswordUpdateRequestStart());
    const authData = {
      userId: userId,
      jwt: jwt,
      data: data,
      action: "updatePassword",
    };
    let url = config.url.API_URL + "/apiEndPoint.php";
    axios
      .post(url, authData)
      .then((response) => {
        dispatch(makePasswordUpdateRequestSuccess(response.data));
      })
      .catch((err) => {
        dispatch(requestFailed(err.response));
      });
  };
};

export const onUpdateUserStripeSubscriptionStart = () => {
  return {
    type: actionTypes.UPDATE_USER_SUBSCRIPTION_START,
  };
};

export const onUpdateUserStripeSubscriptionSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_USER_SUBSCRIPTION_SUCCESS,
    data: data,
  };
};

export const onUpdateUserStripeSubscription = (data) => {
  return (dispatch) => {
    localStorage.setItem("userType", data.userType);
    localStorage.setItem("stripePriceId", data.stripePriceId);

    dispatch(onUpdateUserStripeSubscriptionSuccess(data));
  };
};

export const setNotificationMessageNull = () => {
  return {
    type: actionTypes.SET_AUTH_NOTIFY_MESSAGE_NULL,
  };
};
