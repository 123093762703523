import { useEffect } from "react";

import { makeApiCall } from "../../../shared/makeApiCall";

// const stripePromise = loadStripe(
//   "pk_test_51LP6C3IARlAQy7PPHDFKd0aagDUianifqufsZ4aHnncYrY8mb4r5lPBFECAsVcUzr2vNP7MRfJGvvDQUERnrgeZa00yyOtBi42"
// );

export default function PaymentMethod(props) {
  const { checkRequestError, userId } = props;

  const generateStripeSession = async function () {
    const authData = {
      action: "createBillingPortalSession",
      userId: userId,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      window.location = callResult.data.session.sessionUrl;
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
      }
    }
  };

  useEffect(() => {
    // generateStripeSession();
  }, []);

  return (
    <div className="lg:col-span-9 px-5 bg-gray-50">
      <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-12 rounded-xl sm:mt-3">
        <div className="-ml-4 -mt-2 items-center justify-between">
          <div className="ml-4 mt-2">
            <div className="mt-1">
              <p>Update Payment Method - Powered by Stripe</p>
              {/* <Elements stripe={stripePromise}>
                <CardSetupForm clientSecret={amountDue.clientSecret} />
              </Elements> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
