import React, { useEffect } from "react";

import Input from "../../controls/Input";

import CustomSelect from "../../controls/CustomSelect";
import Select from "react-select";

import ConfirmActionTemplate from "../../ModalTemplates/ConfirmActionTemplate";

import { useForm, Form } from "../../useForm";

const initialFValues = {
  fname: "",
  lname: "",
  email: "",
  website: "",
  currency: "",
  respectMinPlusOrMinus: 0,
  respectMinAmount: 0,
  respectMinCurrencyOrPercentage: 0,
  respectMaxPlusOrMinus: 0,
  respectMaxAmount: 0,
  respectMaxCurrencyOrPercentage: 0,
  preferenceEndWith: 0,
  scrapeAllProductsEnabled: 0,
};

const scrapeAllProductsOptions = [
  { name: "Don't audit", value: 0 },
  { name: "Audit only", value: 1 },
  { name: "Audit and update prices on my website", value: 2 },
];

export default function MyAccountForm(props) {
  const {
    actionFunc,
    email,
    error,
    fname,
    lname,
    loading,
    possibleTimezones,
    setOpenActionModal,
    scrapeAllProductsEnabled,
    timezone,
    website,
  } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("currency" in fieldValues) {
      temp.currency = null;
      if (fieldValues.currency === "") {
        temp.currency = "This field is required.";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      actionFunc(values);
    }
  };

  useEffect(() => {
    setValues({
      fname: fname,
      lname: lname,
      email: email,
      scrapeAllProductsEnabled: scrapeAllProductsEnabled,
      timezone: timezone,
      website: website,
    });
  }, []);

  const convertToDefEventPara = (name, value) => {
    return {
      target: {
        name,
        value,
      },
    };
  };

  const saveAccountAuditSettings = (newValue) => {
    handleInputChange(
      convertToDefEventPara("scrapeAllProductsEnabled", newValue)
    );
    setOpenActionModal({
      open: false,
      setOpen: null,
      childComponent: null,
    });
    const dataToSend = { ...values, scrapeAllProductsEnabled: newValue };
    actionFunc(dataToSend);
  };

  const checkUserWantsToChangeScrapeAllStatus = (e) => {
    const { value } = e.target;

    var message = "Are you sure you want to ";
    if (value === "0") {
      message += "stop scraping your products?";
    } else if (value === "1") {
      message += "start scraping AyourLL products?";
    } else if (value === "2") {
      message += "start scraping and prices on your website for your products?";
    }

    setOpenActionModal({
      open: true,
      setOpen: setOpenActionModal,
      childComponent: (
        <ConfirmActionTemplate
          actionFunc={saveAccountAuditSettings}
          setOpen={setOpenActionModal}
          buttonDisabled={false}
          buttonMessage={"Yes, update."}
          message={message}
          callData={value}
        />
      ),
    });
  };

  return (
    <Form
      classes="divide-y divide-gray-200 lg:col-span-9"
      onSubmit={handleSubmit}
    >
      <div className="py-6 px-4 sm:p-6 lg:pb-8">
        <div className="mt-6 grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Account details
            </h3>
          </div>
        </div>

        <div className="mt-3 grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-6">
            <Input
              label="First name"
              name="fname"
              onChange={handleInputChange}
              value={values.fname}
              labelOn={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>

          <div className="col-span-12 sm:col-span-6">
            <Input
              label="Last name"
              name="lname"
              onChange={handleInputChange}
              value={values.lname}
              labelOn={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
        </div>

        <div className="mt-6 grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-6">
            <Input
              label="Email"
              name="email"
              onChange={handleInputChange}
              value={values.email}
              labelOn={true}
              type="email"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
        </div>

        <div className="mt-6 grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-6">
            <Input
              label="Website"
              name="website"
              onChange={handleInputChange}
              value={values.website}
              labelOn={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
        </div>

        <div className="mt-6 grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-6">
            <div className="flex space-x-2 pb-1">
              <label
                htmlFor="timezone"
                className="block text-sm font-medium text-gray-900"
              >
                Timezone
              </label>
            </div>
            <Select
              name="timezone"
              options={possibleTimezones}
              onChange={(e) =>
                handleInputChange(
                  convertToDefEventPara("timezone", {
                    label: e.label,
                    value: e.label,
                  })
                )
              }
              value={values.timezone}
              placeholder=""
            />
          </div>
        </div>

        <div className="mt-6 grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-6">
            <CustomSelect
              label="Account audit setting"
              name="scrapeAllProducts"
              value={values.scrapeAllProductsEnabled}
              onChange={checkUserWantsToChangeScrapeAllStatus}
              labelOn={true}
              options={scrapeAllProductsOptions}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
        </div>
      </div>

      <div className="pt-6 divide-y divide-gray-200">
        <div className="grid grid-cols-1">
          <div className="px-4 col-span-1 sm:col-span-1 flex justify-end font-medium text-red-500">
            {error ? error.subtitle : null}
          </div>
          <div className="mt-4 py-4 px-4 sm:px-6 col-span-1 sm:col-span-1 flex justify-end">
            <button
              type="submit"
              className="ml-5 bg-sky-700 border border-gray-300 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white bg-indigo-600 hover:bg-sky-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
              disabled={loading}
            >
              {loading ? (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : null}
              Save
            </button>
          </div>
        </div>
      </div>
    </Form>
  );
}
