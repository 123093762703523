import { useEffect, useState } from "react";

import { useForm } from "../useForm";
import axios from "../../axios";
import { config } from "../../constants";

import Dropzone from "react-dropzone";

const initialFValues = {
  importFile: null,
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ImportProductsModal(props) {
  const {
    fetchAllProducts,
    importType,
    open,
    setOpenActionModal,
    setShow,
    userId,
  } = props;

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [error, setError] = useState(null);

  const validate = (fieldValues = values) => {
    let localError = null;
    if ("importFile" in fieldValues) {
      if (!fieldValues.importFile) {
        localError = "Please upload a file";
      }
    }

    setError(localError);

    if (fieldValues === values) {
      return localError === null;
    }
  };

  const { values, setErrors, handleInputChange, resetForm, setValues } =
    useForm(initialFValues, true, validate);

  useEffect(() => {
    if (open) {
      setValues({ importFile: null });
      setErrors({});
    }
  }, [open]);

  const importProductsFromCsv = async function (file) {
    setError(null);
    setDisableSubmitButton(true);
    if (file) {
      const authData = new FormData();
      authData.append("action", "importProductsFromCsv");
      authData.append("userId", userId);
      authData.append("file", file[0]);
      authData.append("importType", importType);

      axios
        .post("products/importProductsFromCsv.php", authData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((response) => {
          fetchAllProducts(0, true);
          setDisableSubmitButton(false);

          setOpenActionModal({
            open: false,
            setOpen: setOpenActionModal,
            childComponent: null,
          });
          setShow({ show: true, title: response.data.message });
        })
        .catch((error) => {
          if (error.data != null) {
            if (error.data.subtitle != null) {
              setError(error.data.subtitle);
            } else {
              setError("Sorry, import failed please try again.");
            }
          } else {
            setError("Sorry, import failed please try again.");
          }
          setDisableSubmitButton(false);
        });
    } else {
      setError("Please upload a file.");
      setDisableSubmitButton(false);
    }
  };

  const massImportCompetitorsFromCsv = async function (file) {
    setError(null);
    setDisableSubmitButton(true);
    if (file) {
      const authData = new FormData();
      authData.append("action", "importMassCompetitorsFromCsv");
      authData.append("userId", userId);
      authData.append("file", file[0]);
      authData.append("importType", importType);

      axios
        .post("competitors/importMassCompetitorsFromCsv.php", authData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((response) => {
          fetchAllProducts("");
          setDisableSubmitButton(false);

          setOpenActionModal({
            open: false,
            setOpen: setOpenActionModal,
            childComponent: null,
          });
          setShow({ show: true, title: response.data.message });
        })
        .catch((error) => {
          setError(error.data.subtitle);
          setDisableSubmitButton(false);
        });
    } else {
      setError("Please upload a file.");
      setDisableSubmitButton(false);
    }
  };

  const handleSubmit = () => {
    if (validate()) {
      resetForm();

      if (importType === 2) {
        massImportCompetitorsFromCsv(values.importFile);
      } else {
        importProductsFromCsv(values.importFile);
      }
    }
  };

  const convertToDefEventPara = (name, value) => {
    return {
      target: {
        name,
        value,
      },
    };
  };

  return (
    <div>
      {disableSubmitButton ? (
        <div className="w-full text-center flex">
          <h3 className="text-lg leading-6 font-medium text-gray-500 mr-5">
            You can close this popup, we will import in the background...
          </h3>
          <svg
            className="animate-spin -ml-1 mr-3 h-7 w-7 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="rgb(79 70 229)"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="rgb(79 70 229)"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      ) : (
        <>
          <div className="text-center p-4">
            <h3 className="text-lg leading-6 font-medium text-gray-500 ">
              Read our guide below to import products.
            </h3>
            <a
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 sm:col-start-2 sm:text-sm m-4"
              onClick={() =>
                importType === 0
                  ? window.open(
                      config.url.API_URL + "/sampleImportFile.php",
                      "_blank"
                    )
                  : importType === 1
                  ? window.open(
                      config.url.API_URL + "/sampleImportCostsFile.php",
                      "_blank"
                    )
                  : window.open(
                      config.url.API_URL +
                        "/sampleMassImportCompetitorsFile.php",
                      "_blank"
                    )
              }
            >
              Read guide
            </a>
          </div>
          <div>
            <Dropzone
              onDrop={(acceptedFile) => {
                if (acceptedFile.length > 0) {
                  handleInputChange(
                    convertToDefEventPara("importFile", acceptedFile)
                  );
                } else {
                  setError(
                    "Could not upload file, please choose the correct file."
                  );
                }
              }}
              maxFiles={1}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div
                    {...getRootProps({
                      className:
                        "relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400",
                    })}
                  >
                    <input {...getInputProps()} />
                    <p>Drop or upload the products import file here.</p>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          <div
            className={classNames(
              values.importFile ? "text-center p-4" : "hidden"
            )}
          >
            <h3 className="text-lg leading-6 font-medium text-gray-500">
              File to upload:{" "}
              {values.importFile
                ? values.importFile[0].name
                : "Error uploading file"}
            </h3>
          </div>
          <div className={classNames(error ? "text-center p-4" : "hidden")}>
            <h3 className="text-lg leading-6 font-medium text-red-500">
              {error}
            </h3>
          </div>
          <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700  sm:col-start-2 sm:text-sm"
              disabled={disableSubmitButton}
              onClick={() => handleSubmit()}
            >
              {disableSubmitButton ? (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                "Upload file"
              )}
            </button>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
              onClick={() => setOpenActionModal({ open: false })}
            >
              Cancel
            </button>
          </div>
        </>
      )}
    </div>
  );
}
