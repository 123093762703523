export default function TakeUserToSubscriptionsTemplate(props) {
  const { setOpen } = props;

  return (
    <div className="w-full">
      <div>
        <div>
          <div className="text-center p-4">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              It seems like you are trying to add too many products.
            </h3>
            <h3 className="text-lg leading-6 font-medium text-gray-900 mt-2">
              Would you like to upgrade?
            </h3>
          </div>
        </div>
        <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 sm:col-start-2 sm:text-sm"
            onClick={() => (window.location = "/settings/subscription-plans")}
          >
            Yes, upgrade!
          </button>
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
            onClick={() => setOpen({ open: false })}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}
