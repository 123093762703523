const prod = {
  url: {
    API_URL: "https://api.prycehub.com/api/",
  },
};

const dev = {
  url: {
    API_URL: "https://stg-api.prycehub.com/api/",
  },
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;
