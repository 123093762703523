import React, { useEffect, useState } from "react";

import { useForm, Form } from "../useForm";
import { makeApiCall } from "../../shared/makeApiCall";

import { updateObjectInArray } from "../../shared/utility";

import SidePanelHeader from "../SidePanelHeader";

import Input from "../controls/Input";

import "wc-spinners/dist/hollow-dots-spinner.js";

const initialFValues = {
  ruleId: 0,
  name: "",
  howMuch: 0,
  currencyOrPercentage: 0,
  highOrLow: 0,
};

export default function PriceRuleForm(props) {
  const {
    checkRequestError,
    currency,
    isEdit,
    rules,
    rule,
    setOpen,
    setOpenSidePanel,
    setRules,
    setShow,
    setSidePanelExtra,
    subtitle,
    title,
    userId,
  } = props;

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const addOrUpdateRuleFunc = async function (isEdit, data) {
    setSubmitError(null);
    setDisableSubmitButton(true);
    const authData = {
      action: isEdit ? "updatePricingRule" : "addPricingRule",
      userId: userId,
      data: data,
    };

    let callResult = await makeApiCall(authData);
    setDisableSubmitButton(false);
    if (callResult.success) {
      if (isEdit) {
        const currentRules = [...rules];
        const ruleId = callResult.data.rule.ruleId;

        const ruleIndex = rules.findIndex((prod) => prod.ruleId === ruleId);

        const payload = {
          index: ruleIndex,
          item: callResult.data.rule,
        };
        const updatedRules = updateObjectInArray(currentRules, payload);

        setRules(updatedRules);
        setOpenSidePanel(false);
        setShow({ show: true, title: "Rule updated." });
        setSidePanelExtra({ childComponent: null });
      } else {
        setRules((rules) => [callResult.data.rule, ...rules]);
        setOpenSidePanel(false);
        setShow({ show: true, title: "New Rule added." });
      }
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setSubmitError("Your session expired, please log back in.");
      }
      setSubmitError(callResult.data.subtitle);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      addOrUpdateRuleFunc(isEdit, values);
    }
  };

  useEffect(() => {
    if (rule != null) {
      setValues(rule);
    }
  }, [isEdit, rule]);

  return (
    <Form onSubmit={handleSubmit}>
      <div className="flex-1 h-0 overflow-y-auto">
        <SidePanelHeader subtitle={subtitle} title={title} setOpen={setOpen} />
        <div className="flex-1 flex flex-col justify-between p-7">
          <div className="grid grid-cols-2 p-2">
            <div className="sm:col-span-2">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Name
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Give a name to your pricing rule so its easy to remember
              </p>
            </div>
            <div className="sm:col-span-1">
              <Input
                label="Name of the rule"
                name="name"
                onChange={handleInputChange}
                value={values.name}
                type="text"
              />
            </div>
          </div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4">
            <div className="grid grid-cols-6 p-2">
              <div className="sm:col-span-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Position
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Choose the position you would like to be
                </p>
              </div>
              <div className="sm:col-span-1">
                <Input
                  label="How much higher or lower"
                  name="howMuch"
                  onChange={handleInputChange}
                  value={values.howMuch}
                  type="number"
                  step="0.01"
                  min="0"
                />
              </div>
              <div className="sm:col-span-1 ml-0.5">
                <select
                  id="currencyOrPercentage"
                  name="currencyOrPercentage"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  onChange={handleInputChange}
                  value={values.currencyOrPercentage}
                >
                  <option value={0}>{currency}</option>
                  <option value={1}>%</option>
                </select>
              </div>
              <div className="sm:col-span-1 ml-0.5">
                <select
                  id="highOrLow"
                  name="highOrLow"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  value={values.highOrLow}
                  onChange={handleInputChange}
                >
                  <option value={0}>higher</option>
                  <option value={1}>lower</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      {submitError ? (
        <div className="flex-shrink-0 px-4 py-4 flex">
          <span className="text-red-700">{submitError}</span>
        </div>
      ) : null}
      <div className="flex-shrink-0 px-4 py-4 flex justify-end">
        <button
          type="button"
          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
          onClick={() => setOpen(false)}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 items-center"
          disabled={disableSubmitButton}
        >
          {disableSubmitButton ? (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            "Save"
          )}
        </button>
      </div>
    </Form>
  );
}
