import React, { useEffect, useState } from "react";

import SidePanelHeader from "../SidePanelHeader";

import { makeApiCall } from "../../shared/makeApiCall";

import { formatDate } from "../../shared/utility";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ViewQueueDetails(props) {
  const { checkRequestError, queueId, setOpen, userId } = props;

  const [queueDetails, setQueueDetails] = useState(null);
  const [queueProducts, setQueueProducts] = useState([]);

  const fetchQueueDetails = async function () {
    const authData = {
      action: "fetchQueueDetails",
      userId: userId,
      queueId: queueId,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setQueueDetails(callResult.data.queueDetails);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
      }
    }
  };

  const fetchQueueProducts = async function (id) {
    const authData = {
      action: "fetchQueueProducts",
      userId: userId,
      queueId: queueId,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setQueueProducts(callResult.data.queueProducts);
    } else {
      //   setSubmitError(callResult.data.subtitle);
    }
  };

  useEffect(() => {
    fetchQueueDetails();
    fetchQueueProducts();
  }, []);

  return (
    <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
      <div className="flex-1 h-0 overflow-y-auto">
        <SidePanelHeader subtitle={null} title={null} setOpen={setOpen} />
        <div className="justify-between">
          <div className="px-4 divide-y divide-gray-500 sm:px-6">
            <div className="grid grid-cols-1 gap-4 space-y-2 pt-6 pb-5">
              {queueDetails !== null ? (
                <>
                  <div>
                    <p className="mt-1 max-w-2xl text-md text-gray-900">
                      Subject:{" "}
                      {queueDetails.scrapeType === "1" ? (
                        "Account wide audit has been queued."
                      ) : (
                        <>
                          <font className="font-heavy">
                            {queueDetails.productName}
                          </font>{" "}
                          has been queued for queue.
                        </>
                      )}
                    </p>
                  </div>{" "}
                  <div>
                    <p className="mt-1 max-w-2xl text-md text-gray-900">
                      Date: {formatDate(queueDetails.queueDate)}
                    </p>
                  </div>{" "}
                  <div>
                    <p className="mt-1 max-w-2xl text-md text-gray-900">
                      Status: {queueDetails.queueStatus}
                    </p>
                  </div>
                </>
              ) : (
                <div>
                  <p className="mt-1 max-w-2xl text-md text-gray-900">
                    Loading details...
                  </p>
                </div>
              )}
              <div className="align-middle inline-block w-full">
                <div className="shadow border-b border-gray-200 sm:rounded-lg">
                  <table className="divide-y divide-gray-200 table-auto w-full">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          width="20%"
                        >
                          Product
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          width="20%"
                        >
                          Dynamic price
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          width="20%"
                        >
                          Api update
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          width="20%"
                        ></th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {queueProducts.map((product) => (
                        <tr key={product.productId}>
                          <td
                            className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400"
                            width="20%"
                          >
                            {product.productName}
                          </td>
                          <td
                            className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400"
                            width="20%"
                          >
                            {product.dynamicPrice}
                          </td>
                          {product.error ? (
                            <td
                              className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400"
                              width="20%"
                              colSpan={2}
                            >
                              {product.error}
                            </td>
                          ) : null}
                          <td
                            className={classNames(
                              product.error ? "hidden" : null,
                              "border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400"
                            )}
                            width="20%"
                          >
                            {queueDetails != null
                              ? queueDetails.apiUpdateStatus === "1" &&
                                product.apiSuccess === "1"
                                ? "The price for this product was updated."
                                : product.apiSuccess === "0"
                                ? "The price for this product was not updated as the api call failed."
                                : "The price for this product was not updated as it was not turned on."
                              : ""}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-gray-500">
                            <a
                              className="relative inline-flex items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10"
                              href={
                                "/products/" +
                                product.productId +
                                "?redirect=audit-logs"
                              }
                              target="_blank"
                            >
                              Go to product
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
