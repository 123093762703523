import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { makeApiCall } from "../../../shared/makeApiCall";
import ErrorModal from "../../ModalTemplates/ErrorModal";

export default function AccountConfirmationForm(props) {
  const { authSuccess, confirmationId, jwt, loggedInUserId, userEmail } = props;

  const navigate = useNavigate();

  const [errorModalOpen, setErrorModalOpen] = useState({
    open: false,
    title: null,
    subtitle: null,
  });

  const confirmAccount = async function (values) {
    const authData = {
      action: "confirmNewAccount",
      jwt: jwt,
      confirmationId: confirmationId,
      email: userEmail,
      userId: loggedInUserId,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setTimeout(() => {}, 1000);
      if (callResult.data.userIsLoggedIn) {
        authSuccess(
          callResult.data.jwt,
          callResult.data.refreshToken,
          callResult.data.expirationDate,
          callResult.data.userId,
          callResult.data.fname,
          callResult.data.lname,
          callResult.data.email,
          callResult.data.currency,
          callResult.data.currencyLabel,
          callResult.data.minValue,
          callResult.data.website,
          callResult.data.timezone,
          callResult.data.scrapeAllProductsEnabled,
          callResult.data.showOnboarding,
          callResult.data.userStatus,
          callResult.data.userType,
          callResult.data.stripePriceId
        );
        navigate("/dashboard?message=account-confirmed");
      } else {
        navigate("/login?message=account-confirmed");
      }
    } else {
      setErrorModalOpen({
        open: true,
        title: "Oops.",
        subtitle: callResult.data.subtitle,
      });
    }
  };

  useEffect(() => {
    confirmAccount();
  }, []);

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-5xl font-extrabold text-gray-900"></h2>
        <p className="mt-3 text-center text-xl text-gray-600">
          Confirming your email, it will only take a few seconds...
        </p>
      </div>

      <ErrorModal
        open={errorModalOpen.open}
        title={errorModalOpen.title}
        setOpen={setErrorModalOpen}
        subtitle={errorModalOpen.subtitle}
      />
    </div>
  );
}
