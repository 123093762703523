import React, { useEffect, useState } from "react";

import { useForm, Form } from "../useForm";

import Input from "../controls/Input";
import SidePanelHeader from "../SidePanelHeader";

import { makeApiCall } from "../../shared/makeApiCall";

const initialFValues = {
  id: "",
  productId: "",
  competitorUrl: "",
};

export default function AssignCompetitorForm(props) {
  const {
    competitor,
    updateCompetitorParentDomain,
    checkRequestError,
    isEdit,
    product,
    setOpen,
    subtitle,
    title,
    updateProductCompetitors,
    updateSingleProductCompetitor,
    userId,
  } = props;

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const addCompetitorFunc = async function (isEdit, data) {
    setSubmitError(null);
    setDisableSubmitButton(true);
    const authData = {
      action: updateCompetitorParentDomain
        ? "updateCompetitorParentDomain"
        : isEdit
        ? "updateCompetitorForProduct"
        : "addCompetitor",
      userId: userId,
      data: data,
    };
    let callResult = await makeApiCall(authData);
    setDisableSubmitButton(false);
    if (callResult.success) {
      if (isEdit) {
        if (updateCompetitorParentDomain) {
          const newComp = { ...competitor, parentDomain: values.competitorUrl };
          updateSingleProductCompetitor(
            competitor.competitorId,
            newComp,
            "Competitor updated."
          );
        } else {
          updateSingleProductCompetitor(
            callResult.data.competitor.urlId,
            callResult.data.competitor.productId,
            callResult.data.competitor,
            "Competitor updated."
          );
        }
      } else {
        updateProductCompetitors(
          callResult.data.newCompetitor.productId,
          callResult.data.newCompetitor,
          "New competitor added."
        );
      }
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setSubmitError("Your session expired, please log out and log back in.");
      }
      setSubmitError(callResult.data.subtitle);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      addCompetitorFunc(isEdit, values);
    }
  };

  useEffect(() => {
    if (product != null) {
      setValues(product);
    }
  }, [product]);

  useEffect(() => {
    if (competitor != null) {
      setValues({
        assignedCompetitorId: competitor.competitorId,
        competitorUrl: competitor.parentDomain,
      });
    }
  }, [competitor]);

  return (
    <Form onSubmit={handleSubmit}>
      <div className="flex-1 h-0 overflow-y-auto">
        <SidePanelHeader subtitle={subtitle} title={title} setOpen={setOpen} />
        <div className="flex-1 flex flex-col justify-between">
          <div className="px-4 divide-y divide-gray-500 sm:px-6">
            <div className="pt-6 pb-5">
              <Input
                label="Competitor Url"
                labelOn={true}
                name="competitorUrl"
                onChange={handleInputChange}
                value={values.competitorUrl}
                placeholder="https://example.com"
                explanation="The website link/url of your competitor's product"
              />
            </div>
          </div>
        </div>
      </div>
      {submitError ? (
        <div className="flex-shrink-0 px-4 py-4 flex">
          <span className="text-red-700">{submitError}</span>
        </div>
      ) : null}
      <div className="flex-shrink-0 px-4 py-4 flex justify-end">
        <button
          type="button"
          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
          onClick={() => setOpen(false)}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
          disabled={disableSubmitButton}
        >
          {disableSubmitButton ? (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            "Save"
          )}
        </button>
      </div>
    </Form>
  );
}
