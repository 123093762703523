import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import ProductDetailsForm from "../../components/forms/ProductDetailsForm";
import ActionModal from "../../components/ActionModal";
import EmptyModal from "../../components/ModalTemplates/EmptyModal";
import ErrorModal from "../../components/ModalTemplates/ErrorModal";
import SidePanel from "../../components/SidePanel";
import SuccessNotification from "../../components/SuccessNotification";

import { getLastItem } from "../../shared/utility";
import { makeApiCall } from "../../shared/makeApiCall";
import ProductCompetitors from "./product/ProductCompetitors";
import ProductScrapes from "./product/ProductScrapes";

import { updateObjectInArray } from "../../shared/utility";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const tabs = [
  { name: "Product Details", href: "#" },
  { name: "Competitors", href: "#" },
  { name: "Audit Log", href: "#" },
  { name: "Analytics", href: "#" },
];

const EditProduct = (props) => {
  const { checkRequestError, currency, userId } = props;

  const { pathname } = useLocation();

  const [brands, setBrands] = useState([]);
  const [brandsLoaded, setBrandsLoaded] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoriesLoaded, setCategoriesLoaded] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [emptyModal, setEmptyModal] = useState({
    open: false,
    childComponent: null,
  });
  const [errorModalOpen, setErrorModalOpen] = useState({
    open: false,
    title: null,
    subtitle: null,
  });
  const [loading, setLoading] = useState(true);
  const [openActionModal, setOpenActionModal] = useState({
    open: false,
    title: null,
    subtitle: null,
  });
  const [openSidePanel, setOpenSidePanel] = useState(false);
  const [product, setProduct] = useState({
    id: 0,
    competitors: [],
  });
  const [rules, setRules] = useState([]);
  const [scrapes, setScrapes] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [sidePanelExtra, setSidePanelExtra] = useState({
    childComponent: null,
  });
  const [show, setShow] = useState({ show: false });
  const [submitError, setSubmitError] = useState(null);

  const productId = getLastItem(pathname);

  const fetchProduct = async function () {
    setLoading(true);
    const authData = {
      action: "fetchProductById",
      userId: userId,
      productId: productId,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setProduct(callResult.data.product);
      document.title = "Editing - " + callResult.data.product.name;
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle: "We could not load the product, please try again.",
        });
        document.title = "Could not load page...";
      }
    }
    setLoading(false);
  };

  const fetchAllBrands = async function () {
    const authData = {
      action: "fetchAllBrandsForDropdown",
      userId: userId,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setBrands(callResult.data.brands);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle: "We could not fetch the data, please refresh the page.",
        });
      }
    }
    setBrandsLoaded(true);
  };

  const fetchAllCategories = async function () {
    const authData = {
      action: "fetchAllCategoriesForDropdown",
      userId: userId,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setCategories(callResult.data.categories);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle: "We could not fetch the data, please refresh the page.",
        });
      }
    }
    setCategoriesLoaded(true);
  };

  const fetchAllRules = async function () {
    const authData = {
      action: "fetchAllPricingRulesByUserId",
      userId: userId,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setRules(callResult.data.pricingRules);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle: "We could not fetch the data, please refresh the page.",
        });
      }
    }
  };

  const fetchProductScrapes = async function () {
    const authData = {
      action: "fetchProductScrapes",
      userId: userId,
      productId: productId,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setScrapes(callResult.data.scrapes);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle: "We could not load the audits, please try again.",
        });
      }
    }
  };

  useEffect(() => {
    document.title = "Loading...";

    fetchAllBrands();
    fetchAllCategories();
    fetchAllRules();
    fetchProductScrapes();

    const redirect = searchParams.get("redirect");
    if (redirect == "audit-logs") {
      setCurrentTab(2);
    }
  }, [productId]);

  useEffect(() => {
    if (brandsLoaded && categoriesLoaded) {
      fetchProduct();
    }
  }, [brandsLoaded, categoriesLoaded]);

  const updateProductFunc = async function (data) {
    setSubmitError(null);
    setDisableSubmitButton(true);
    const authData = {
      action: "updateProduct",
      userId: userId,
      data: data,
    };
    let callResult = await makeApiCall(authData);
    setDisableSubmitButton(false);
    if (callResult.success) {
      setProduct({ ...product, ...callResult.data.product });
      setShow({ show: true, title: "Product updated." });

      if (callResult.data.refreshBrands) {
        fetchAllBrands();
      }

      if (callResult.data.refreshCategories) {
        fetchAllCategories();
      }
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle:
            "We could not sync with your Shopify account, please try again.",
        });
      }
      setSubmitError(callResult.data.subtitle);
    }
  };

  const deleteProduct = () => {};

  const updateSingleProductCompetitor = (urlId, competitor, message) => {
    const currentCompetitors = [...product.competitors];
    const compIndex = product.competitors.findIndex(
      (comp) => comp.urlId === urlId
    );

    if (compIndex >= 0) {
      const payload = {
        index: compIndex,
        item: competitor,
      };
      const updatedCompetitors = updateObjectInArray(
        currentCompetitors,
        payload
      );

      setProduct({ ...product, competitors: updatedCompetitors });
      setShow({ show: true, title: message });

      setOpenSidePanel(false);
      setSidePanelExtra({ childComponent: null });
    } else {
      setShow({
        show: true,
        title: "We had trouble updating, please try again",
      });

      setOpenSidePanel(false);
      setSidePanelExtra({ childComponent: null });
    }
  };

  const updateProductCompetitors = (productId, newCompetitor) => {
    const updatedCompetitors = [...product.competitors, newCompetitor];

    setProduct({ ...product, competitors: updatedCompetitors });
    setShow({ show: true, title: "Competitors updated." });

    setOpenSidePanel(false);
    setSidePanelExtra({ childComponent: null });
  };

  const setProductCompetitors = (productId, newCompetitors) => {
    setProduct({ ...product, competitors: newCompetitors });
    setShow({ show: true, title: "Competitors updated." });

    setOpenSidePanel(false);
    setSidePanelExtra({ childComponent: null });
  };

  const deleteAssignedCompetitor = async function (urlId) {
    setOpenActionModal({
      ...openActionModal,
      buttonDisabled: true,
      itemId: 0,
    });
    const authData = {
      action: "deleteAssignedCompetitor",
      userId: userId,
      urlId: urlId,
    };
    let callResult = await makeApiCall(authData);

    setOpenActionModal({
      buttonDisabled: false,
      open: false,
      setOpen: setOpenActionModal,
    });

    if (callResult.success) {
      const tempCompetitors = product.competitors;

      const updatedCompetitors = tempCompetitors.filter(
        (competitor) => competitor.urlId !== urlId
      );

      setProductCompetitors(null, updatedCompetitors);
      setShow({ show: true, title: "Competitor deleted." });
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle:
            "We could not sync with your Shopify account, please try again.",
        });
      }
      setErrorModalOpen({
        open: true,
        title: "Sorry!",
        subtitle: "We could not delete the competitor, please try again.",
      });
    }
  };

  const scrapeSingleProduct = async function (productId, isUpdateApi) {
    const authData = {
      action: "scrapeSingleProduct",
      userId: userId,
      productId: productId,
      isUpdateApi: isUpdateApi,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setEmptyModal({
        childComponent: null,
        open: false,
      });
      setShow({ show: true, title: callResult.data.result });
    } else {
      setEmptyModal({ open: false });
      setErrorModalOpen({
        open: true,
        title: "Sorry!",
        subtitle: "We could not audit this product, please try again.",
      });
    }
  };

  const updateProductScrapeStatusFunc = async function (
    scrapeStatus,
    productId
  ) {
    setSubmitError(null);
    setDisableSubmitButton(true);
    const authData = {
      action: "updateProductScrapeStatus",
      userId: userId,
      productId: productId,
      scrapeStatus: scrapeStatus,
    };
    let callResult = await makeApiCall(authData);
    setDisableSubmitButton(false);
    if (callResult.success) {
      setProduct({ ...product, ...callResult.data.product });
      setOpenSidePanel(false);
      setShow({ show: true, title: "Product audit setting updated." });
      setSidePanelExtra({ childComponent: null });
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle:
            "We could not sync with your Shopify account, please try again.",
        });
      }
      setSubmitError(callResult.data.subtitle);
    }
  };

  const renderComponent = (index) => {
    switch (index) {
      case 0:
        return (
          <div key={0} className="-mt-2 bg-white">
            {loading ? (
              "loading"
            ) : (
              <ProductDetailsForm
                actionFunc={updateProductFunc}
                brands={brands}
                categories={categories}
                currency={currency}
                disableSubmitButton={disableSubmitButton}
                product={product}
                setDisableSubmitButton={setDisableSubmitButton}
                setProduct={setProduct}
                setShow={setShow}
                userId={userId}
              />
            )}
          </div>
        );

      case 1:
        return (
          <div key={1} className="-mt-2 bg-white">
            <ProductCompetitors
              checkRequestError={checkRequestError}
              competitors={product.competitors}
              currency={currency}
              deleteAssignedCompetitor={deleteAssignedCompetitor}
              disableSubmitButton={disableSubmitButton}
              fetchAllRules={fetchAllRules}
              product={product}
              rules={rules}
              setOpenActionModal={setOpenActionModal}
              setOpenSidePanel={setOpenSidePanel}
              setShow={setShow}
              setSidePanelExtra={setSidePanelExtra}
              setSubmitError={setSubmitError}
              setEmptyModal={setEmptyModal}
              setNewCompetitorsForProduct={setProductCompetitors}
              submitError={submitError}
              updateSingleProductCompetitor={updateSingleProductCompetitor}
              updateProductCompetitors={updateProductCompetitors}
              userId={userId}
            />
          </div>
        );

      case 2:
        return (
          <div key={2} className="-mt-2 bg-white">
            <ProductScrapes
              checkRequestError={checkRequestError}
              product={product}
              productId={productId}
              scrapes={scrapes}
              scrapeSingleProduct={scrapeSingleProduct}
              searchParams={searchParams}
              setEmptyModal={setEmptyModal}
              setOpenSidePanel={setOpenSidePanel}
              setShow={setShow}
              setSidePanelExtra={setSidePanelExtra}
              updateProductScrapeStatusFunc={updateProductScrapeStatusFunc}
              userId={userId}
            />
          </div>
        );

      default:
        return (
          <div key={1000} className="-mt-2 bg-white">
            Could not load page, please try again
          </div>
        );
    }
  };

  return (
    <div className="bg-gray-50">
      <header className="bg-white shadow sticky top-0 z-50">
        <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div className="flex px-4 sm:px-12">
            <h1 className="text-3xl font-bold text-gray-900">
              {loading ? "Loading..." : product.name}
            </h1>
          </div>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          {/* Content starts here */}

          {/* tab here */}
          <div>
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              <select
                id="tabs"
                name="tabs"
                className="block w-full  border-gray-300 rounded-md"
                defaultValue={tabs[currentTab].name}
              >
                {tabs.map((tab, index) => (
                  <option key={index}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <nav
                className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200"
                aria-label="Tabs"
              >
                {tabs.map((tab, index) => (
                  <a
                    key={tab.name}
                    href={tab.href}
                    className={classNames(
                      index === currentTab
                        ? "text-gray-900"
                        : "text-gray-500 hover:text-gray-700",
                      index === 0 ? "rounded-l-lg" : "",
                      index === tabs.length - 1 ? "rounded-r-lg" : "",
                      "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                    )}
                    aria-current={index === currentTab ? "page" : undefined}
                    onClick={() => setCurrentTab(index)}
                  >
                    <span>{tab.name}</span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        index === currentTab
                          ? "bg-indigo-500"
                          : "bg-transparent",
                        "absolute inset-x-0 bottom-0 h-0.5"
                      )}
                    />
                  </a>
                ))}
              </nav>
            </div>
          </div>

          {/* {tab content starts below} */}
          <div className="px-4 py-6 sm:px-0">
            <div className="sm:col-span-9 py-5 border-b border-gray-200">
              {tabs.map((item, index) =>
                index === currentTab ? renderComponent(index) : null
              )}
            </div>
          </div>
        </div>
        <SidePanel
          open={openSidePanel}
          setOpen={setOpenSidePanel}
          childComponent={sidePanelExtra.childComponent}
          size={sidePanelExtra.size}
        />
        <ErrorModal
          open={errorModalOpen.open}
          title={errorModalOpen.title}
          setOpen={setErrorModalOpen}
          subtitle={errorModalOpen.subtitle}
        />
        <SuccessNotification
          show={show.show}
          setShow={setShow}
          title={show.title}
        />
        <ActionModal
          actionFunc={deleteProduct}
          childComponent={openActionModal.childComponent}
          disableSubmitButton={openActionModal.buttonDisabled}
          buttonMessage={openActionModal.buttonMessage}
          message={openActionModal.message}
          open={openActionModal.open}
          itemId={openActionModal.productId}
          setOpen={setOpenActionModal}
        />

        <EmptyModal
          childComponent={emptyModal.childComponent}
          open={emptyModal.open}
          setOpen={setEmptyModal}
        />
      </main>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currency: state.auth.currencyLabel,
    userId: state.auth.userId,
  };
};

export default connect(mapStateToProps)(EditProduct);
