import React, { useEffect } from "react";
import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import { useSearchParams, Link } from "react-router-dom";

const ShopifyRegistration = (props) => {
  const { authSuccess } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const hmac = searchParams.get("hmac");
  const shop = searchParams.get("shop");

  var newlink = "";

  useEffect(() => {
    if (hmac != null && shop != null) {
      newlink =
        "https://" +
        shop +
        "/admin/oauth/authorize?client_id=f5c6ff3df9d0ee494aa80b6106354f69&scope=write_product_listings,read_product_listings,write_products,read_products,write_inventory,read_inventory&redirect_uri=http://localhost:3000/registration&state={nonce}&grant_options[]={access_mode}";
    }
  }, [hmac, shop]);

  const goToNewAccountPage = () => {
    window.location = newlink;
  };

  return (
    <div className="bg-gray-50">
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div className="flex px-4 sm:px-12">
            <a
              href="https://app.prycehub.com"
              className="text-3xl font-bold text-gray-900"
            >
              PryceHub
            </a>
          </div>
          <div className="sm:flex-no-wrap px-4 sm:px-12">
            <div className="my-auto flex items-center">
              Already have an account?&nbsp;
              <a href="/login" className="text-indigo-700 font-bold">
                Login
              </a>
            </div>
          </div>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          {/* Start here */}
          <div className="px-4 py-6 sm:px-0">
            <div className="space-y-6 mt-5">
              <div className="px-4 py-5 sm:rounded-lg sm:p-6">
                <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                  <div className="sm:mx-auto sm:w-full sm:max-w-xl">
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                      Welcome to PryceHub!
                    </h2>
                    <p className="mt-3 text-center text-xl text-gray-600">
                      Having a PryceHub account, you will have better service.
                    </p>
                    <p className="mt-3 text-center text-xl text-gray-600">
                      Link your account now to complete store authorization.
                    </p>
                    <div className="grid grid-cols-2 gap-4 mt-5">
                      <div className="sm:col-span-1">
                        <Link
                          type="button"
                          className="w-full justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 text-center"
                          to="/login"
                        >
                          Log in
                        </Link>
                      </div>
                      <div className="sm:col-span-1">
                        <button
                          type="button"
                          className="w-full justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 text-center"
                          onClick={() => goToNewAccountPage()}
                        >
                          Create new account
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    registrationSuccess: state.auth.registrationSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authSuccess: (
      jwt,
      refreshToken,
      userId,
      fname,
      lname,
      email,
      currency,
      currencyLabel,
      minValue,
      website,
      timezone,
      scrapeAllProductsEnabled
    ) =>
      dispatch(
        actions.authSuccess(
          jwt,
          refreshToken,
          userId,
          fname,
          lname,
          email,
          currency,
          currencyLabel,
          minValue,
          website,
          timezone,
          scrapeAllProductsEnabled
        )
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShopifyRegistration);
