import GlobalSettingsForm from "../../../components/forms/Settings/GlobalSettingsForm";

export default function GlobalSettings(props) {
  const { actionFunc, currency, error, globalSettings, loading } = props;
  return (
    <GlobalSettingsForm
      actionFunc={actionFunc}
      currency={currency}
      error={error}
      globalSettings={globalSettings}
      loading={loading}
    />
  );
}
