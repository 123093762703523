import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";

import { makeApiCall } from "../../shared/makeApiCall";

import ErrorModal from "../../components/ModalTemplates/ErrorModal";
import { formatDate } from "../../shared/utility";
import ViewQueueDetails from "../../components/Templates/ViewQueueDetails";
import SidePanel from "../../components/SidePanel";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Queues = (props) => {
  const { checkRequestError, currency, userId } = props;

  const [queues, setQueues] = useState([]);
  const [errorModalOpen, setErrorModalOpen] = useState({
    open: false,
    title: null,
    subtitle: null,
  });
  const [openSidePanel, setOpenSidePanel] = useState(false);
  const [sidePanelExtra, setSidePanelExtra] = useState({
    childComponent: null,
  });

  const fetchAllQueues = async function () {
    const authData = {
      action: "fetchAllQueuesForUser",
      userId: userId,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setQueues(callResult.data.queues);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle: "We could not fetch the data, please refresh the page.",
        });
      }
    }
  };

  useEffect(() => {
    document.title = "Queue";

    fetchAllQueues();
  }, []);

  return (
    <div className="bg-gray-50">
      {/* <AdminLayout /> */}
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900">Queue</h1>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          {/* Start here */}
          <div className="px-4 py-6 sm:px-0">
            {/* <div className="bg-white px-4 py-5 sm:px-12">
              <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                <div className="ml-4 mt-2">
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <div className="relative flex items-stretch flex-grow focus-within:z-10">
                      <input
                        type="text"
                        name="searchCompetitor"
                        id="searchCompetitor"
                        className="focus:ring-indigo-50 focus:border-indigo-500 block w-full rounded-lg rounded-l-md pl-2 sm:text-md border border-gray-300 rounded-tr-none rounded-br-none"
                        placeholder="Search"
                        onChange={handleSearch}
                      />
                    </div>
                    <button
                      type="button"
                      className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 "
                    >
                      <SearchIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </div>
                <div className="ml-4 mt-2 flex-shrink-0">
                  <button
                    type="button"
                    className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-400 hover:bg-indigo-700"
                  >
                    <FilterIcon
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    Filter
                  </button>
                </div>
              </div>
            </div> */}

            {/* table starts */}
            <div className="bg-white mb-6 px-4 py-5 border-b border-gray-200 sm:px-12">
              <div className="flex flex-col pt-6">
                <div className="-my-2 overflow-x-visible sm:-mx-6 lg:-mx-8">
                  <div className="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-auto border-b border-gray-200 sm:rounded-lg z-10 w-full">
                      <table className="w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Subject
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Date
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Status
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {queues.map((queue) => (
                            <tr key={queue.queueId}>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                <div className="block font-medium text-gray-900">
                                  {queue.scrapeType === "1" ? (
                                    "Account wide audit has been queued."
                                  ) : (
                                    <>
                                      <font className="font-heavy">
                                        {queue.productName}
                                      </font>{" "}
                                      has been queued for queue.
                                    </>
                                  )}
                                </div>
                                <div className="block">
                                  {queue.apiUpdateStatus === "1"
                                    ? "The price for the product(s) in this queue have been updated."
                                    : "The price for the product(s) in this queue have not been updated as it was not turned on."}
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {formatDate(queue.queueDate)}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {queue.queueStatus}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-gray-500">
                                <button
                                  className="relative inline-flex items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10"
                                  onClick={() => {
                                    setSidePanelExtra({
                                      childComponent: (
                                        <ViewQueueDetails
                                          checkRequestError={checkRequestError}
                                          queueId={queue.queueId}
                                          setOpen={setOpenSidePanel}
                                          userId={userId}
                                        />
                                      ),
                                      size: "7xl",
                                    });
                                    setOpenSidePanel(true);
                                  }}
                                >
                                  View
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ErrorModal
          open={errorModalOpen.open}
          title={errorModalOpen.title}
          setOpen={setErrorModalOpen}
          subtitle={errorModalOpen.subtitle}
        />
        <SidePanel
          open={openSidePanel}
          setOpen={setOpenSidePanel}
          childComponent={sidePanelExtra.childComponent}
          size={sidePanelExtra.size}
        />
      </main>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currency: state.auth.currencyLabel,
    userId: state.auth.userId,
  };
};

export default connect(mapStateToProps)(Queues);
