import React, { useEffect, useState } from "react";

import SidePanelHeader from "../SidePanelHeader";

import { makeApiCall } from "../../shared/makeApiCall";

import { formatDate } from "../../shared/utility";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ViewScrapeDetails(props) {
  const {
    checkRequestError,
    isRedirect,
    productId,
    scrape,
    setOpen,
    subtitle,
    userId,
    title,
  } = props;

  const [scrapeDetails, setScrapeDetails] = useState(null);
  const [scrapeCompetitors, setScrapeCompetitors] = useState([]);

  const fetchScrapeDetails = async function () {
    const authData = {
      action: "fetchScrapeDetails",
      userId: userId,
      productId: productId,
      scrapeId: scrape,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setScrapeDetails(callResult.data.scrapeDetails);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
      }
    }
  };

  const fetchScrapeSubScrapes = async function (id) {
    const authData = {
      action: "fetchScrapeSubScrapes",
      userId: userId,
      productId: productId,
      scrapeId: id,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setScrapeCompetitors(callResult.data.scrapes);
    } else {
      //   setSubmitError(callResult.data.subtitle);
    }
  };

  useEffect(() => {
    if (isRedirect) {
      fetchScrapeDetails();
      fetchScrapeSubScrapes(scrape);
    } else {
      setScrapeDetails(scrape);
      fetchScrapeSubScrapes(scrape.id);
    }
  }, []);

  return (
    <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
      <div className="flex-1 h-0 overflow-y-auto">
        <SidePanelHeader subtitle={subtitle} title={title} setOpen={setOpen} />
        <div className="justify-between">
          <div className="px-4 divide-y divide-gray-500 sm:px-6">
            <div className="grid grid-cols-1 gap-4 space-y-2 pt-6 pb-5">
              {scrapeDetails !== null ? (
                <>
                  <div>
                    <p className="mt-1 max-w-2xl text-md text-gray-900">
                      Date: {formatDate(scrapeDetails.addedDate)}
                    </p>
                  </div>{" "}
                  <div>
                    <p className="mt-1 max-w-2xl text-md text-gray-900">
                      Product Dynamic Price: {scrapeDetails.dynamicPrice}
                    </p>
                  </div>{" "}
                  <div>
                    <p className="mt-1 max-w-2xl text-md text-gray-900">
                      {scrapeDetails.updatePriceTurnedOn === "1" ? (
                        scrapeDetails.apiSuccess === "1" ? (
                          <font className="text-green-600 font-bold">
                            Successfully updated price on website
                          </font>
                        ) : (
                          <font className="text-red-500 font-bold">
                            Error while updating price on website
                          </font>
                        )
                      ) : (
                        <font className="text-indigo-600 font-bold">
                          Updating price on website not turned on
                        </font>
                      )}
                    </p>{" "}
                  </div>
                </>
              ) : (
                <div>
                  <p className="mt-1 max-w-2xl text-md text-gray-900">
                    Loading details...
                  </p>
                </div>
              )}
              <div className="align-middle inline-block w-full">
                <div className="shadow border-b border-gray-200 sm:rounded-lg">
                  <table className="divide-y divide-gray-200 table-auto w-full">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          width="17%"
                        >
                          Competitor
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          width="17%"
                        >
                          Pricing rule
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          width="16%"
                        >
                          Price of competitor
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          width="16%"
                        >
                          Dynamic Price
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          width="16%"
                        >
                          In-Stock
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {scrapeCompetitors.map((singleScrape) => (
                        <tr key={singleScrape.id}>
                          <td
                            className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400"
                            width="20%"
                          >
                            <a
                              href={singleScrape.competitorUrl}
                              target="_blank"
                            >
                              {singleScrape.parentDomain}
                            </a>
                          </td>
                          <td
                            className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400"
                            width="20%"
                          >
                            {singleScrape.pricingRuleExplanation
                              ? singleScrape.pricingRuleExplanation
                              : "Pricing Rule was not set"}
                          </td>
                          {singleScrape.error ? (
                            <td
                              className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400"
                              width="20%"
                              colSpan={2}
                            >
                              {singleScrape.error}
                            </td>
                          ) : null}

                          <td
                            className={classNames(
                              singleScrape.error ? "hidden" : null,
                              "border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400"
                            )}
                            width="20%"
                          >
                            {singleScrape.scrapedPrice}
                          </td>
                          <td
                            className={classNames(
                              singleScrape.error ? "hidden" : null,
                              "border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400"
                            )}
                            width="20%"
                          >
                            {singleScrape.dynamicPrice}
                          </td>
                          <td
                            className={
                              "border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400"
                            }
                            width="20%"
                          >
                            {singleScrape.stock === "1"
                              ? "In stock"
                              : "Out of stock"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
