import axiosRegular from "../axios";

export async function makeApiCall(authData) {
  const authToSend = { ...authData, jwt: localStorage.getItem("jwt") };
  return axiosRegular
    .post("/apiEndPoint.php", authToSend)
    .then((response) => {
      return {
        success: true,
        data: response.data,
      };
    })
    .catch((err) => {
      console.log("err", err.response);
      const emptyData = {
        data: {
          title: "Oops",
          subtitle: "We could not complete the action.",
          errorStatus: err.response.status,
        },
      };

      if (err.response) {
        if (err.response.data) {
          return {
            success: false,
            data: err.response.data,
            errorStatus: err.response.status,
          };
        }
        return emptyData;
      } else {
        return {
          success: false,
          data: emptyData,
          errorStatus: err.response.status,
        };
      }
    });
}
