import React from "react";
import { createPopper } from "@popperjs/core";

const TooltipText = (props) => {
  const { explanation, text, textColor } = props;

  const [tooltipShow, setTooltipShow] = React.useState(false);
  const btnRef = React.createRef();
  const tooltipRef = React.createRef();
  const openLeftTooltip = () => {
    createPopper(btnRef.current, tooltipRef.current, {
      placement: "top",
    });
    setTooltipShow(true);
  };
  const closeLeftTooltip = () => {
    setTooltipShow(false);
  };
  return (
    <>
      <div
        onMouseEnter={openLeftTooltip}
        onMouseLeave={closeLeftTooltip}
        ref={btnRef}
        className={textColor ? textColor : ""}
      >
        {text}
      </div>

      <div
        className={
          (tooltipShow ? "" : "hidden ") +
          "border-0 mt-3 block font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg"
        }
        ref={tooltipRef}
        style={{ backgroundColor: "#676B75" }}
      >
        <div>
          <div className="text-white p-3 text-center">{explanation}</div>
        </div>
      </div>
    </>
  );
};

export default TooltipText;
