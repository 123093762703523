import React from "react";
import { connect } from "react-redux";

import { useSearchParams } from "react-router-dom";

import * as actions from "../../store/actions/index";

import Step1 from "../../components/forms/RegistrationForms/Step1";

const Registration = (props) => {
  const { authSuccess } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className="bg-gray-50">
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div className="flex px-4 sm:px-12">
            <a
              href="https://app.prycehub.com"
              className="text-3xl font-bold text-gray-900"
            >
              PryceHub
            </a>
          </div>
          <div className="sm:flex-no-wrap px-4 sm:px-12">
            <div className="my-auto flex items-center">
              Already have an account?&nbsp;
              <a href="/login" className="text-indigo-700 font-bold">
                Login
              </a>
            </div>
          </div>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          {/* Start here */}
          <div className="px-4 py-6 sm:px-0">
            <div className="space-y-6 mt-5">
              <div className="px-4 py-5 sm:rounded-lg sm:p-6">
                <Step1 authSuccess={authSuccess} searchParams={searchParams} />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    registrationSuccess: state.auth.registrationSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authSuccess: (
      jwt,
      refreshToken,
      expirationDate,
      userId,
      fname,
      lname,
      email,
      currency,
      currencyLabel,
      minValue,
      website,
      timezone,
      scrapeAllProductsEnabled,
      showOnboarding,
      userStatus,
      userType,
      stripePriceId
    ) =>
      dispatch(
        actions.authSuccess(
          jwt,
          refreshToken,
          expirationDate,
          userId,
          fname,
          lname,
          email,
          currency,
          currencyLabel,
          minValue,
          website,
          timezone,
          scrapeAllProductsEnabled,
          showOnboarding,
          userStatus,
          userType,
          stripePriceId
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
