import React, { useEffect } from "react";

import Input from "../../controls/Input";
import CustomSelect from "../../controls/CustomSelect";

import { useForm, Form } from "../../useForm";

const currencyOptions = [
  { name: "Select currency", value: "" },
  { name: "CAD", value: 1 },
  { name: "USD", value: 2 },
];

const initialFValues = {
  currency: "",
  respectMinPlusOrMinus: 0,
  respectMinAmount: 0,
  respectMinCurrencyOrPercentage: 0,
  respectMaxPlusOrMinus: 0,
  respectMaxAmount: 0,
  respectMaxCurrencyOrPercentage: 0,
  preferenceEndWith: 0,
  abbreviation: "",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function GlobalSettingsForm(props) {
  const { actionFunc, currency, error, globalSettings, loading } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("currency" in fieldValues) {
      temp.currency = null;
      if (fieldValues.currency === "") {
        temp.currency = "This field is required.";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  useEffect(() => {
    if (globalSettings != null) {
      setValues({
        ...globalSettings,
      });
    }
  }, [globalSettings]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      actionFunc(values);
    }
  };

  return (
    <Form
      classes="divide-y divide-gray-200 lg:col-span-9"
      onSubmit={handleSubmit}
    >
      <div className="py-6 px-4 sm:p-6 lg:pb-8">
        <div className="grid grid-cols-6 p-2 mt-2">
          <div className="sm:col-span-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Margin/markup
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Choose what the dynamic price should reflect
            </p>
          </div>
          <div className="sm:col-span-1 mt-2">Minimum: </div>
          <div className="sm:col-span-1 ml-0.5 mt-2">
            <select
              id="respectMinPlusOrMinus"
              name="respectMinPlusOrMinus"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              value={values.respectMinPlusOrMinus}
              onChange={handleInputChange}
            >
              <option value={0}>Cost +</option>
            </select>
          </div>
          <div className="sm:col-span-1 ml-0.5 mt-2">
            <Input
              label="Respect Minimum amount"
              name="respectMinAmount"
              onChange={handleInputChange}
              value={values.respectMinAmount}
              type="number"
              step="0.01"
              min="0"
            />
          </div>
          <div className="sm:col-span-1 ml-0.5 mt-2">
            <select
              id="respectMinCurrencyOrPercentage"
              name="respectMinCurrencyOrPercentage"
              value={values.respectMinCurrencyOrPercentage}
              onChange={handleInputChange}
              className={classNames(
                values.respectMinPlusOrMinus === "1"
                  ? "hidden"
                  : "mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              )}
            >
              <option value={0}>{values.abbreviation}</option>
              <option value={1}>%</option>
            </select>
          </div>
          <div className="sm:col-span-2"></div>
          <div className="sm:col-span-1 mt-3">Maximum: </div>
          <div className="sm:col-span-1 ml-0.5 mt-3">
            <select
              id="respectMaxPlusOrMinus"
              name="respectMaxPlusOrMinus"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              value={values.respectMaxPlusOrMinus}
              onChange={handleInputChange}
            >
              <option value={0}>Cost +</option>
              <option value={2}>Not set</option>
            </select>
          </div>
          <div
            className={classNames(
              values.respectMaxPlusOrMinus === "1" ||
                values.respectMaxPlusOrMinus === "2"
                ? "hidden"
                : "sm:col-span-1 ml-0.5 mt-3"
            )}
          >
            <Input
              label="Respect Maximum amount"
              name="respectMaxAmount"
              onChange={handleInputChange}
              value={values.respectMaxAmount}
              type="number"
              step="0.01"
              min="0"
            />
          </div>
          <div
            className={classNames(
              values.respectMaxPlusOrMinus === "1" ||
                values.respectMaxPlusOrMinus === "2"
                ? "hidden"
                : "sm:col-span-1 ml-0.5 mt-3"
            )}
          >
            <select
              id="respectMaxCurrencyOrPercentage"
              name="respectMaxCurrencyOrPercentage"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              value={values.respectMaxCurrencyOrPercentage}
              onChange={handleInputChange}
            >
              <option value={0}>{values.abbreviation}</option>
              <option value={1}>%</option>
            </select>
          </div>
          <div className="sm:col-span-2"></div>
        </div>
        <div className="mt-6 grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-6 p-2">
            <CustomSelect
              label="Currency"
              name="currency"
              onChange={handleInputChange}
              value={values.currency}
              labelOn={true}
              options={currencyOptions}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              error={errors.currency}
            />
          </div>
        </div>
        <div className="mt-2 grid grid-cols-12 gap-x-4 p-2">
          <div className="sm:col-span-12 mt-3">
            <label>Always end decimal prices with</label>
          </div>

          <div className="sm:col-span-2">
            <select
              id="preferenceEndWith"
              name="preferenceEndWith"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              value={values.preferenceEndWith}
              onChange={handleInputChange}
            >
              <option value={0}>00</option>
              <option value={1}>49</option>
              <option value={2}>50</option>
              <option value={3}>90</option>
              <option value={4}>95</option>
              <option value={5}>99</option>
            </select>
          </div>
        </div>
      </div>

      <div className="pt-6 divide-y divide-gray-200">
        <div className="grid grid-cols-1">
          <div className="px-4 col-span-1 sm:col-span-1 flex justify-end font-medium text-red-500">
            {error ? error.subtitle : null}
          </div>
          <div className="mt-4 py-4 px-4 sm:px-6 col-span-1 sm:col-span-1 flex justify-end">
            <button
              type="submit"
              className="ml-5 bg-sky-700 border border-gray-300 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white bg-indigo-600 hover:bg-sky-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
              disabled={loading}
            >
              {loading ? (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : null}
              Save
            </button>
          </div>
        </div>
      </div>
    </Form>
  );
}
