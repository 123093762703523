const prod = [
  {
    name: "Free",
    priceMonthly: "Free",
    description: "Free Forever",
    includedFeatures: [
      "Potenti felis, in cras at at ligula nunc.",
      "Orci neque eget pellentesque.",
    ],
    type: 0,
    priceId: "price_1Lcg4VIARlAQy7PPDoT6LXhE",
    isFree: true,
  },
  {
    name: "Standard",
    priceMonthly: 39,
    description: "per month",
    includedFeatures: [
      "Potenti felis, in cras at at ligula nunc. ",
      "Orci neque eget pellentesque.",
      "Donec mauris sit in eu tincidunt etiam.",
    ],
    type: 0,
    priceId: "price_1Lcg5gIARlAQy7PP6cDtwWUk",
    isFree: false,
  },
  {
    name: "Pro",

    priceMonthly: 99,
    description: "per month",
    includedFeatures: [
      "Potenti felis, in cras at at ligula nunc. ",
      "Orci neque eget pellentesque.",
      "Donec mauris sit in eu tincidunt etiam.",
      "Faucibus volutpat magna.",
    ],
    type: 0,
    priceId: "price_1Lcg65IARlAQy7PP3ice32qe",
    isFree: false,
  },
  {
    name: "Business",

    priceMonthly: 129,
    description: "per month",
    includedFeatures: [
      "Potenti felis, in cras at at ligula nunc. ",
      "Orci neque eget pellentesque.",
      "Donec mauris sit in eu tincidunt etiam.",
      "Faucibus volutpat magna.",
      "Id sed tellus in varius quisque.",
      "Risus egestas faucibus.",
      "Risus cursus ullamcorper.",
    ],
    type: 0,
    priceId: "price_1Lcg6wIARlAQy7PP1rBut3RX",
    isFree: false,
  },
  {
    name: "Free",

    priceMonthly: "Free",
    description: "Free Forever",
    includedFeatures: [
      "Potenti felis, in cras at at ligula nunc.",
      "Orci neque eget pellentesque.",
    ],
    type: 1,
    priceId: "price_1Lcg4VIARlAQy7PPyVxZQi67",
    isFree: true,
  },
  {
    name: "Standard",

    priceMonthly: 429,
    description: "per month",
    includedFeatures: [
      "Potenti felis, in cras at at ligula nunc. ",
      "Orci neque eget pellentesque.",
      "Donec mauris sit in eu tincidunt etiam.",
    ],
    type: 1,
    priceId: "price_1Lcg5gIARlAQy7PPJufTADxk",
    isFree: false,
  },
  {
    name: "Pro",

    priceMonthly: 879,
    description: "per month",
    includedFeatures: [
      "Potenti felis, in cras at at ligula nunc. ",
      "Orci neque eget pellentesque.",
      "Donec mauris sit in eu tincidunt etiam.",
      "Faucibus volutpat magna.",
    ],
    type: 1,
    priceId: "price_1Lcg65IARlAQy7PPiYsHmKjU",
    isFree: false,
  },
  {
    name: "Business",

    priceMonthly: 999,
    description: "per month",
    includedFeatures: [
      "Potenti felis, in cras at at ligula nunc. ",
      "Orci neque eget pellentesque.",
      "Donec mauris sit in eu tincidunt etiam.",
      "Faucibus volutpat magna.",
      "Id sed tellus in varius quisque.",
      "Risus egestas faucibus.",
      "Risus cursus ullamcorper.",
    ],
    type: 1,
    priceId: "price_1Lcg6wIARlAQy7PP5n3qlojq",
    isFree: false,
  },
];

const dev = [
  {
    name: "Free",
    priceMonthly: "Free",
    description: "Free Forever",
    includedFeatures: [
      "Potenti felis, in cras at at ligula nunc.",
      "Orci neque eget pellentesque.",
    ],
    type: 0,
    priceId: "price_1LPxHIIARlAQy7PPJjBBj5uZ",
    isFree: true,
  },
  {
    name: "Standard",
    priceMonthly: 39,
    description: "per month",
    includedFeatures: [
      "Potenti felis, in cras at at ligula nunc. ",
      "Orci neque eget pellentesque.",
      "Donec mauris sit in eu tincidunt etiam.",
    ],
    type: 0,
    priceId: "price_1LPQ8uIARlAQy7PPEZByuzgE",
    isFree: false,
  },
  {
    name: "Pro",

    priceMonthly: 99,
    description: "per month",
    includedFeatures: [
      "Potenti felis, in cras at at ligula nunc. ",
      "Orci neque eget pellentesque.",
      "Donec mauris sit in eu tincidunt etiam.",
      "Faucibus volutpat magna.",
    ],
    type: 0,
    priceId: "price_1LPQ7zIARlAQy7PPtCnzU9zi",
    isFree: false,
  },
  {
    name: "Business",

    priceMonthly: 129,
    description: "per month",
    includedFeatures: [
      "Potenti felis, in cras at at ligula nunc. ",
      "Orci neque eget pellentesque.",
      "Donec mauris sit in eu tincidunt etiam.",
      "Faucibus volutpat magna.",
      "Id sed tellus in varius quisque.",
      "Risus egestas faucibus.",
      "Risus cursus ullamcorper.",
    ],
    type: 0,
    priceId: "price_1LPQ9eIARlAQy7PPys8ymKbs",
    isFree: false,
  },
  {
    name: "Free",

    priceMonthly: "Free",
    description: "Free Forever",
    includedFeatures: [
      "Potenti felis, in cras at at ligula nunc.",
      "Orci neque eget pellentesque.",
    ],
    type: 1,
    priceId: "price_1LPxHYIARlAQy7PPtMnEBquO",
    isFree: true,
  },
  {
    name: "Standard",

    priceMonthly: 429,
    description: "per month",
    includedFeatures: [
      "Potenti felis, in cras at at ligula nunc. ",
      "Orci neque eget pellentesque.",
      "Donec mauris sit in eu tincidunt etiam.",
    ],
    type: 1,
    priceId: "price_1LPQ8bIARlAQy7PPKwtvXvPW",
    isFree: false,
  },
  {
    name: "Pro",

    priceMonthly: 879,
    description: "per month",
    includedFeatures: [
      "Potenti felis, in cras at at ligula nunc. ",
      "Orci neque eget pellentesque.",
      "Donec mauris sit in eu tincidunt etiam.",
      "Faucibus volutpat magna.",
    ],
    type: 1,
    priceId: "price_1LPQ7zIARlAQy7PPovDyxzPt",
    isFree: false,
  },
  {
    name: "Business",

    priceMonthly: 999,
    description: "per month",
    includedFeatures: [
      "Potenti felis, in cras at at ligula nunc. ",
      "Orci neque eget pellentesque.",
      "Donec mauris sit in eu tincidunt etiam.",
      "Faucibus volutpat magna.",
      "Id sed tellus in varius quisque.",
      "Risus egestas faucibus.",
      "Risus cursus ullamcorper.",
    ],
    type: 1,
    priceId: "price_1LPQ9eIARlAQy7PPaGsdX5WU",
    isFree: false,
  },
];
export const pricePlans = process.env.NODE_ENV === "development" ? dev : prod;
