import React, { useEffect } from "react";
import { connect } from "react-redux";

import { useSearchParams } from "react-router-dom";

const ShopifyRegistrationLoggedIn = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const hmac = searchParams.get("hmac");
  const shop = searchParams.get("shop");

  var newlink = "";

  useEffect(() => {
    if (hmac != null && shop != null) {
      window.location =
        "https://" +
        shop +
        "/admin/oauth/authorize?client_id=f5c6ff3df9d0ee494aa80b6106354f69&scope=write_product_listings,read_product_listings,write_products,read_products,write_inventory,read_inventory&redirect_uri=http://localhost:3000/dashboard&state={nonce}&grant_options[]={access_mode}";
    }
  }, [hmac, shop]);

  const goToNewAccountPage = () => {
    window.location = newlink;
  };

  return (
    <div className="bg-gray-50">
      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          {/* Start here */}
          <div className="px-4 py-6 sm:px-0">
            <div className="space-y-6 mt-5">
              <div className="px-4 py-5 sm:rounded-lg sm:p-6">
                <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                  <div className="sm:mx-auto sm:w-full sm:max-w-xl">
                    Loading
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

const mapStateToProps = (state) => {};

export default connect(mapStateToProps)(ShopifyRegistrationLoggedIn);
