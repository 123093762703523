import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import {
  useLocation,
  useNavigate,
  Link,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import * as actions from "../store/actions/index";

import { Disclosure, Menu, Popover, Transition } from "@headlessui/react";
import {
  BellIcon,
  ExclamationCircleIcon,
  MenuIcon,
  UserCircleIcon,
  XIcon,
} from "@heroicons/react/outline";

import AccountConfirmation from "../pages/registration/AccountConfirmation";
import Competitors from "../pages/competitors/Competitors";
import Dashboard from "../pages/dashboard/Dashboard";
import EditProduct from "../pages/products/EditProduct";
import Products from "../pages/products/Products";
import Reports from "../pages/reports/Reports";
import Settings from "../pages/settings/Settings";
import PricingRules from "../pages/pricingRules/PricingRules";

import routes from "./routes";
import { makeApiCall } from "../shared/makeApiCall";
import Audits from "../pages/audits/Audits";
import ShopifyRegistrationLoggedIn from "../pages/registration/ShopifyRegistrationLoggedIn";

const userNavigation = [
  { name: "Settings", href: "/settings/account" },
  { name: "Log out", href: "#" },
];

const socialLinks = [
  {
    name: "Facebook",
    href: "#",
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    name: "Instagram",
    href: "https://www.instagram.com/prycehubapp/",
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    name: "Twitter",
    href: "https://twitter.com/PrycehubApp",
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
      </svg>
    ),
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Admin = (props) => {
  const { userId, userStatus, userType } = props;

  let navigate = useNavigate();

  const { onLogout } = props;
  const location = useLocation();

  const [notifications, setNotifications] = useState([]);

  const changeDestination = (destination) => {
    navigate(destination);
  };

  const fetchAllNotifications = async function () {
    const authData = {
      action: "fetchAllNotifications",
      userId: userId,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setNotifications(callResult.data.notifications);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
      }
    }
  };

  useEffect(() => {
    fetchAllNotifications();
  }, []);

  const getRoutes = routes(userType, location.pathname);
  const routesArray = getRoutes["routes"];
  const pageHeader = getRoutes["title"];

  const checkRequestError = (errorStatus) => {
    if (errorStatus === 401) {
      onLogout();
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Disclosure as="nav" className="bg-gray-800">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex items-center justify-between h-16">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img
                      className="h-12 w-12"
                      src="https://prycehub.com/wp-content/uploads/2022/08/PryceHub-Logo-500-%C3%97-500-px-Transparent-e1660664657695.png"
                      alt="PryceHub logo"
                    />
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                      {routesArray.map((item) => (
                        <Link
                          key={item.name}
                          to={userStatus > 0 ? item.href : "#"}
                          className={classNames(
                            item.current
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "px-3 py-2 rounded-md text-sm font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="hidden md:block">
                  <div className="ml-4 flex items-center md:ml-6">
                    {/* Notifications start */}
                    <Popover className="ml-2 relative">
                      {({ open }) => (
                        <>
                          <Popover.Button className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white">
                            <BellIcon className="h-6 w-6" aria-hidden="true" />
                          </Popover.Button>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel className="absolute z-150 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0">
                              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                  {notifications.length > 0 ? (
                                    notifications.map((item) => (
                                      <a
                                        key={item.id}
                                        href={
                                          "/products/" +
                                          item.productId +
                                          "?redirect=audit-logs&audit=" +
                                          item.auditId
                                        }
                                        className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150 cursor-pointer"
                                      >
                                        {item.successStatus === "1" ? (
                                          <BellIcon
                                            className="flex-shrink-0 h-6 w-6 text-indigo-600"
                                            aria-hidden="true"
                                          />
                                        ) : (
                                          <ExclamationCircleIcon
                                            className="flex-shrink-0 h-6 w-6 text-red-600"
                                            aria-hidden="true"
                                          />
                                        )}

                                        <div className="ml-4">
                                          <p className="text-base font-medium text-gray-900">
                                            {item.title}
                                          </p>
                                          <p className="mt-1 text-sm text-gray-500">
                                            {item.description}
                                          </p>
                                        </div>
                                      </a>
                                    ))
                                  ) : (
                                    <p>No new notifications!</p>
                                  )}
                                </div>
                                <div className="px-5 py-5 bg-gray-50 sm:px-8 sm:py-8">
                                  <div className="text-sm">
                                    <a
                                      href="#"
                                      className="font-medium text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150"
                                    >
                                      View all notifications
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                    {/* Notifications end */}

                    {/* Profile dropdown */}
                    <Menu as="div" className="ml-2 relative">
                      <div>
                        <Menu.Button className="max-w-xs bg-gray-800 rounded-full flex items-center text-gray-400 text-sm">
                          <span className="sr-only">Open user menu</span>
                          <UserCircleIcon
                            className="h-6 w-6"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-150">
                          <Menu.Item key={0}>
                            {({ active }) => (
                              <button
                                type="button"
                                className="block px-4 py-2 text-sm text-gray-700"
                                onClick={() =>
                                  changeDestination("/settings/account")
                                }
                              >
                                Settings
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item key={1}>
                            {({ active }) => (
                              <button
                                type="button"
                                className="block px-4 py-2 text-sm text-gray-700"
                                onClick={onLogout}
                              >
                                Log out
                              </button>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
                <div className="-mr-2 flex md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {routesArray.map((item) => (
                  <a
                    key={item.name}
                    href={userStatus > 0 ? item.href : "#"}
                    className={classNames(
                      item.current
                        ? "bg-gray-900 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "block px-3 py-2 rounded-md text-base font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="pt-4 pb-3 border-t border-gray-700">
                <div className="flex items-center px-5">
                  <div className="flex-shrink-0">
                    {/* <img
                      className="h-10 w-10 rounded-full"
                      src={user.imageUrl}
                      alt=""
                    /> */}
                  </div>
                  {/* <div className="ml-3">
                    <div className="text-base font-medium leading-none text-white">
                      {user.name}
                    </div>
                    <div className="text-sm font-medium leading-none text-gray-400">
                      {user.email}
                    </div>
                  </div> */}
                  <button
                    type="button"
                    className="ml-auto bg-gray-800 flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-3 px-2 space-y-1">
                  {userNavigation.map((item) => (
                    <a
                      key={item.name}
                      href={userStatus > 0 ? item.href : "#"}
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <Routes>
        <Route
          path="/queue"
          element={<Audits checkRequestError={checkRequestError} />}
        />
        <Route
          path="/dashboard"
          element={<Dashboard checkRequestError={checkRequestError} />}
        />
        <Route
          path="/products/:productId"
          element={<EditProduct checkRequestError={checkRequestError} />}
        />
        <Route
          path="/products"
          element={<Products checkRequestError={checkRequestError} />}
        />
        <Route
          path="/competitors"
          element={<Competitors checkRequestError={checkRequestError} />}
        />
        <Route
          path="/pricing-rules"
          element={<PricingRules checkRequestError={checkRequestError} />}
        />
        <Route
          path="/reports"
          element={<Reports checkRequestError={checkRequestError} />}
        />
        <Route
          path="/settings/:tab"
          element={<Settings checkRequestError={checkRequestError} />}
        />
        <Route
          path="/shopify-registration"
          element={
            <ShopifyRegistrationLoggedIn
              checkRequestError={checkRequestError}
            />
          }
        />
        <Route path="/settings" element={<Settings />} />
        <Route
          path="/account-confirmation"
          element={
            <AccountConfirmation checkRequestError={checkRequestError} />
          }
        />

        <Route path="*" element={<Navigate to="/dashboard" replace />} />
      </Routes>
      <footer className="">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
          <div className="flex justify-center space-x-6 md:order-2">
            {socialLinks.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
          <div className="mt-8 md:mt-0 md:order-1">
            <p className="text-center text-base text-gray-900">
              &copy; 2022 PryceHub, Inc. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
    userStatus: state.auth.userStatus,
    userType: state.auth.userType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { onLogout: () => dispatch(actions.logout()) };
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
