import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  jwt: null,
  refreshToken: null,
  userId: null,
  error: null,
  fname: "",
  lname: "",
  loading: false,
  email: "",
  currency: "",
  currencyLabel: "",
  minValue: "",
  scrapeAllProductsEnabled: 0,
  timezone: "",
  website: "",
  notifyMessage: null,
  showOnboarding: 0,
  userStatus: 0,
  userType: 0,
  stripePriceId: "",
};

const authStart = (state) => {
  return updateObject(state, { error: null, loading: true });
};

const authSuccess = (state, action) => {
  console.log("authSuccess_action: ", action);
  return updateObject(state, {
    jwt: action.jwt,
    refreshToken: action.refreshToken,
    userId: action.userId,
    error: null,
    loading: false,
    fname: action.fname,
    lname: action.lname,
    email: action.email,
    currency: action.currency,
    currencyLabel: action.currencyLabel,
    minValue: action.minValue,
    website: action.website,
    timezone: action.timezone,
    scrapeAllProductsEnabled: action.scrapeAllProductsEnabled,
    showOnboarding: action.showOnboarding,
    userStatus: action.userStatus,
    userType: action.userType,
    stripePriceId: action.stripePriceId,
  });
};

const authFail = (state, action) => {
  if (action.error.data) {
    return updateObject(state, {
      error: {
        title: action.error.data.title,
        subtitle: action.error.data.subtitle,
      },
      loading: false,
    });
  }
  return updateObject(state, {
    error: {
      title: "",
      subtitle: "We could not complete your request, please try again.",
    },
    loading: false,
  });
};

const authLogout = (state) => {
  return updateObject(state, {
    jwt: null,
    refreshToken: null,
    userId: null,
    userStatus: 0,
    userType: 0,
    stripePriceId: 0,
    email: null,
    fname: null,
    lname: null,
    currency: null,
  });
};

const myAccountUpdateRequestStart = (state) => {
  return updateObject(state, {
    error: null,
    notifyMessage: null,
    loading: true,
  });
};

const myAccountUpdateRequestSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    notifyMessage: action.data.message,
    fname: action.data.returnData.fname,
    lname: action.data.returnData.lname,
    email: action.data.returnData.email,
    website: action.data.returnData.website,
    loading: false,
    notifyMessage: action.data.message,
    timezone: action.data.returnData.timezone,
    scrapeAllProductsEnabled: action.data.returnData.scrapeAllProductsEnabled,
  });
};

const updateRequestFail = (state, action) => {
  let error = {
    title: "Oops.",
    subtitle: "We could not complete your request. Please try again.",
  };

  console.log("action", action);
  if (action.error.data) {
    error = {
      title: action.error.data.title,
      subtitle: action.error.data.subtitle,
    };
  }

  return updateObject(state, {
    error: error,
    notifyMessage: null,
    loading: false,
  });
};

const setNotificationMessageNull = (state) => {
  const updatedState = {
    notifyMessage: null,
    error: null,
  };

  return updateObject(state, updatedState);
};

const setCurrency = (state, action) => {
  return updateObject(state, {
    currency: action.data.currency,
    currencyLabel: action.data.currencyLabel,
  });
};

const updateUserSubscription = (state, action) => {
  console.log("data", action.data);
  return updateObject(state, {
    userType: action.data.userType,
    stripePriceId: action.data.stripePriceId,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state);

    case actionTypes.MY_ACCOUNT_UPDATE_REQUEST_START:
      return myAccountUpdateRequestStart(state, action);
    case actionTypes.MY_ACCOUNT_UPDATE_REQUEST_SUCCESS:
      return myAccountUpdateRequestSuccess(state, action);
    case actionTypes.REQUEST_FAILED:
      return updateRequestFail(state, action);

    case actionTypes.SET_AUTH_NOTIFY_MESSAGE_NULL:
      return setNotificationMessageNull(state);

    case actionTypes.ON_UPDATE_CURRENCY_SUCCESS:
      return setCurrency(state, action);

    case actionTypes.UPDATE_USER_SUBSCRIPTION_SUCCESS:
      return updateUserSubscription(state, action);

    default:
      return state;
  }
};

export default reducer;
