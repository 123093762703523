import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";

import { makeApiCall } from "../../shared/makeApiCall";

import { ChevronDownIcon } from "@heroicons/react/solid";
import ErrorModal from "../../components/ModalTemplates/ErrorModal";
import SidePanel from "../../components/SidePanel";
import SuccessNotification from "../../components/SuccessNotification";
import DeleteProductTemplate from "../../components/ModalTemplates/DeleteProductTemplate";

import {
  CogIcon,
  FilterIcon,
  PlusIcon,
  SearchIcon,
} from "@heroicons/react/outline";

import { Menu, Transition } from "@headlessui/react";

import PriceRuleForm from "../../components/forms/PriceRuleForm";

import ActionModal from "../../components/ActionModal";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Pricing = (props) => {
  const { checkRequestError, currency, userId } = props;

  const [sidePanelExtra, setSidePanelExtra] = useState({
    childComponent: null,
  });
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState({
    open: false,
    title: null,
    subtitle: null,
  });
  const [openSidePanel, setOpenSidePanel] = useState(false);
  const [openActionModal, setOpenActionModal] = useState({
    open: false,
    title: null,
    subtitle: null,
  });
  const [show, setShow] = useState({ show: false });
  const [rules, setRules] = useState([]);

  const fetchAllRules = async function (search) {
    const authData = {
      action: "fetchAllPricingRulesByUserId",
      userId: userId,
      search: search,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setRules(callResult.data.pricingRules);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle:
            "We could not sync with your Shopify account, please try again.",
        });
      }
      setErrorModalOpen({
        open: true,
        title: "Sorry!",
        subtitle: "We could not fetch the data, please refresh the page.",
      });
    }
  };

  useEffect(() => {
    document.title = "Pricing Rules";

    fetchAllRules("");
  }, []);

  const deleteRule = async function (ruleId) {
    setOpenActionModal({
      ...openActionModal,
      buttonDisabled: true,
      itemId: 0,
    });
    const authData = {
      action: "deleteRule",
      userId: userId,
      ruleId: ruleId,
    };
    let callResult = await makeApiCall(authData);

    setOpenActionModal({
      actionFunc: deleteRule,
      buttonDisabled: false,
      open: false,
      setOpen: setOpenActionModal,
    });

    if (callResult.success) {
      const updatedRules = rules.filter((rule) => rule.ruleId !== ruleId);
      setRules(updatedRules);
      setShow({ show: true, title: "Rule deleted." });
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle:
            "We could not sync with your Shopify account, please try again.",
        });
      }
      setErrorModalOpen({
        open: true,
        title: "Sorry!",
        subtitle: "We could not delete the rule, please try again.",
      });
    }
  };

  const handleSearch = (e) => {
    fetchAllRules(e.target.value);
  };

  return (
    <div className="bg-gray-50">
      {/* <AdminLayout /> */}
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900">Manage Rules</h1>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          {/* Start here */}
          <div className="px-4 py-6 sm:px-0">
            <div className="bg-white px-4 py-5 sm:px-12">
              <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                <div className="ml-4 mt-2">
                  {/* search starts */}
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <div className="relative flex items-stretch flex-grow focus-within:z-10">
                      <input
                        type="text"
                        name="searchRule"
                        id="searchRule"
                        className=" block w-full rounded-lg rounded-l-md pl-2 sm:text-md border border-gray-300 rounded-tr-none rounded-br-none"
                        placeholder="Search"
                        onChange={handleSearch}
                      />
                    </div>
                    <button
                      type="button"
                      className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none"
                    >
                      <SearchIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                  {/* search ends */}
                </div>
                <div className="ml-4 mt-2 flex-shrink-0">
                  <button
                    type="button"
                    className="relative inline-flex items-center mr-2 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                    onClick={() => {
                      setSidePanelExtra({
                        childComponent: (
                          <PriceRuleForm
                            checkRequestError={checkRequestError}
                            currency={currency}
                            isEdit={false}
                            rule={null}
                            rules={rules}
                            setOpen={setOpenSidePanel}
                            setOpenSidePanel={setOpenSidePanel}
                            setRules={setRules}
                            setShow={setShow}
                            setSidePanelExtra={setSidePanelExtra}
                            subtitle="Fill in the information below to add a new pricing rule."
                            title="Add new pricing rule"
                            userId={userId}
                          />
                        ),
                        panelSize: "2xl",
                      });
                      setOpenSidePanel(true);
                    }}
                  >
                    <PlusIcon
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    Add Rule
                  </button>
                  <button
                    type="button"
                    className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-400 hover:bg-indigo-700"
                  >
                    <FilterIcon
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    Filter
                  </button>
                </div>
              </div>
            </div>

            {/* table starts */}
            <div className="bg-white mb-6 px-4 py-5 border-b border-gray-200 sm:px-12">
              <div className="flex flex-col pt-6">
                <div className="-my-2 overflow-x-visible sm:-mx-6 lg:-mx-8">
                  <div className="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-visible border-b border-gray-200 sm:rounded-lg  w-full">
                      <table className="w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Rule name
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Explanation
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            ></th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {rules.map((rule) => (
                            <tr key={rule.ruleId}>
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                {rule.name}
                              </td>

                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {rule.explanation}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                <div className="ml-2 h-7 flex items-center">
                                  <span className="relative inline-flex shadow-sm rounded ">
                                    <Menu
                                      as="span"
                                      className="-ml-px relative block"
                                    >
                                      <Menu.Button className="relative inline-flex items-center px-2 py-2 rounded border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none">
                                        <span>Edit</span>
                                        <ChevronDownIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </Menu.Button>
                                      <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                      >
                                        <Menu.Items className="cursor-pointer origin-top-right absolute right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-150">
                                          <div className="py-1 z-150 text-left">
                                            <Menu.Item>
                                              {({ active }) => (
                                                <a
                                                  className={classNames(
                                                    active
                                                      ? "bg-gray-100 text-gray-900"
                                                      : "text-gray-700",
                                                    "block px-4 py-2 text-sm "
                                                  )}
                                                  onClick={() => {
                                                    setSidePanelExtra({
                                                      childComponent: (
                                                        <PriceRuleForm
                                                          checkRequestError={
                                                            checkRequestError
                                                          }
                                                          currency={currency}
                                                          isEdit={true}
                                                          rule={rule}
                                                          rules={rules}
                                                          setOpenSidePanel={
                                                            setOpenSidePanel
                                                          }
                                                          setRules={setRules}
                                                          setShow={setShow}
                                                          setSidePanelExtra={
                                                            setSidePanelExtra
                                                          }
                                                          setOpen={
                                                            setOpenSidePanel
                                                          }
                                                          subtitle="Fill in the information below to add a new pricing rule."
                                                          title="Add new pricing rule"
                                                          userId={userId}
                                                        />
                                                      ),
                                                      panelSize: "2xl",
                                                    });
                                                    setOpenSidePanel(true);
                                                  }}
                                                >
                                                  Edit Rule
                                                </a>
                                              )}
                                            </Menu.Item>
                                            <Menu.Item>
                                              {({ active }) => (
                                                <a
                                                  className={classNames(
                                                    active
                                                      ? "bg-gray-100 text-gray-900"
                                                      : "text-gray-700",
                                                    "block px-4 py-2 text-sm "
                                                  )}
                                                  onClick={() => {
                                                    setOpenActionModal({
                                                      open: true,
                                                      setOpen:
                                                        setOpenActionModal,
                                                      childComponent: (
                                                        <DeleteProductTemplate
                                                          actionFunc={
                                                            deleteRule
                                                          }
                                                          disableSubmitButton={
                                                            disableSubmitButton
                                                          }
                                                          itemId={rule.ruleId}
                                                          setOpen={
                                                            setOpenActionModal
                                                          }
                                                          buttonDisabled={false}
                                                          buttonMessage={
                                                            "Delete"
                                                          }
                                                          message={
                                                            "Type delete below to delete " +
                                                            rule.name +
                                                            ". This action cannot be undone."
                                                          }
                                                        />
                                                      ),
                                                    });
                                                  }}
                                                >
                                                  Delete Rule
                                                </a>
                                              )}
                                            </Menu.Item>
                                          </div>
                                        </Menu.Items>
                                      </Transition>
                                    </Menu>
                                  </span>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SidePanel
          open={openSidePanel}
          setOpen={setOpenSidePanel}
          childComponent={sidePanelExtra.childComponent}
          size={sidePanelExtra.panelSize}
        />
        <ErrorModal
          open={errorModalOpen.open}
          title={errorModalOpen.title}
          setOpen={setErrorModalOpen}
          subtitle={errorModalOpen.subtitle}
        />
        <SuccessNotification
          show={show.show}
          setShow={setShow}
          title={show.title}
        />
        <ActionModal
          childComponent={openActionModal.childComponent}
          open={openActionModal.open}
          setOpen={setOpenActionModal}
        />
      </main>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currency: state.auth.currencyLabel,
    userId: state.auth.userId,
  };
};

export default connect(mapStateToProps)(Pricing);
