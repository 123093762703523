import { useState } from "react";
import IntegrationsForm from "../../../components/forms/Settings/IntegrationsForm";

export default function Integrations(props) {
  const {
    actionFunc,
    apiDetails,
    connectionLoading,
    error,
    loading,
    testApiConnection,
  } = props;

  return (
    <IntegrationsForm
      actionFunc={actionFunc}
      apiDetails={apiDetails}
      connectionLoading={connectionLoading}
      error={error}
      loading={loading}
      testApiConnection={testApiConnection}
    />
  );
}
