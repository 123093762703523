import React, { Fragment } from "react";

import { Menu, Transition } from "@headlessui/react";
import { ClockIcon } from "@heroicons/react/solid";

import AssignCompetitorForm from "../../../components/forms/AssignCompetitorForm";
import AssignPricingRuleToCompetitorForm from "../../../components/forms/AssignPricingRuleToCompetitorForm";
import ReportUrl from "../../../components/ModalTemplates/ReportUrl";

import { formatDate } from "../../../shared/utility";
import ImportCompetitorsModal from "../../../components/ModalTemplates/ImportCompetitorsModal";
import { CogIcon } from "@heroicons/react/outline";
import DeleteProductTemplate from "../../../components/ModalTemplates/DeleteProductTemplate";
import TooltipText from "../../../components/TooltipText";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ProductCompetitors(props) {
  const {
    checkRequestError,
    competitors,
    currency,
    deleteAssignedCompetitor,
    disableSubmitButton,
    fetchAllRules,
    openActionModal,
    product,
    rules,
    setOpenActionModal,
    setOpenSidePanel,
    setShow,
    setSidePanelExtra,
    setSubmitError,
    setEmptyModal,
    setNewCompetitorsForProduct,
    submitError,
    updateSingleProductCompetitor,
    updateProductCompetitors,
    userId,
  } = props;

  return (
    <div className="justify-between px-4">
      <div className="divide-y divide-gray-500">
        <div className="flex items-center justify-between flex-wrap sm:flex-nowrap space-y-3 pt-6 pb-5">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Competitors
          </h3>

          <div className="sm:flex-no-wrap px-4 sm:px-12">
            <button
              type="submit"
              className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-400 hover:bg-indigo-700 items-center"
              disabled={disableSubmitButton}
              onClick={() => {
                setOpenActionModal({
                  open: true,
                  setOpen: setOpenActionModal,
                  childComponent: (
                    <ImportCompetitorsModal
                      open={openActionModal}
                      productId={product.productId}
                      setNewCompetitorsForProduct={setNewCompetitorsForProduct}
                      setOpenActionModal={setOpenActionModal}
                      setShow={setShow}
                      userId={userId}
                    />
                  ),
                });
              }}
            >
              Import competitors
            </button>
            <button
              type="submit"
              className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 items-center"
              disabled={disableSubmitButton}
              onClick={() => {
                setSidePanelExtra({
                  childComponent: (
                    <AssignCompetitorForm
                      checkRequestError={checkRequestError}
                      isEdit={false}
                      product={{
                        productId: product.productId,
                      }}
                      setShow={setOpenSidePanel}
                      setOpenSidePanel={setOpenSidePanel}
                      setOpen={setOpenSidePanel}
                      submitError={submitError}
                      subtitle="Fill in the information below to add a competitor."
                      title={"Add new competitor to " + product.name}
                      updateProductCompetitors={updateProductCompetitors}
                      updateSingleProductCompetitor={
                        updateSingleProductCompetitor
                      }
                      userId={userId}
                    />
                  ),
                });
                setOpenSidePanel(true);
              }}
            >
              Add competitor
            </button>
          </div>
        </div>
        <div className="space-y-3 pt-6 pb-5">
          <div className="">
            <div className="">
              <div className="align-middle inline-block w-full">
                <div className="shadow border-b border-gray-200 sm:rounded-lg">
                  <table className="divide-y divide-gray-200 table-auto w-full">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          width="20%"
                        >
                          Competitor
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          width="20%"
                        >
                          Price
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          width="20%"
                        >
                          Last updated
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          width="20%"
                        >
                          Pricing rule
                        </th>
                        <th scope="col" className="relative px-6 py-3"></th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {competitors.map((competitor) => (
                        <tr key={competitor.urlId}>
                          <td
                            className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400"
                            width="20%"
                          >
                            <a href={competitor.competitorUrl} target="_blank">
                              {competitor.parentDomain}
                            </a>
                          </td>

                          <td
                            colSpan="2"
                            className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center"
                            style={{
                              display:
                                competitor.domainStatus === "0"
                                  ? "table-cell"
                                  : "none",
                            }}
                          >
                            Domain is being configured, it will be audited
                            automatically once configured.
                          </td>

                          <td
                            className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400"
                            width="20%"
                            style={{
                              display:
                                competitor.domainStatus === "0"
                                  ? "none"
                                  : "table-cell",
                            }}
                          >
                            {competitor.price === null ? (
                              <ClockIcon
                                className="mt-2 h-6 w-6 text-indigo-700"
                                aria-hidden="true"
                              />
                            ) : (
                              competitor.price
                            )}
                          </td>
                          <td
                            className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400"
                            width="20%"
                            style={{
                              display:
                                competitor.domainStatus === "0"
                                  ? "none"
                                  : "table-cell",
                            }}
                          >
                            {competitor.scrapeId > 0
                              ? formatDate(competitor.lastUpdated)
                              : "Will update in a few hours"}
                          </td>
                          <td
                            className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400"
                            width="20%"
                          >
                            {competitor.pr_product_ruleId > 0 ? (
                              <TooltipText
                                explanation={competitor.prod_rule_explanation}
                                text={competitor.pr_product_name}
                              />
                            ) : competitor.pr_competitor_ruleId > 0 ? (
                              <TooltipText
                                explanation={competitor.comp_rule_explanation}
                                text={competitor.pr_competitor_name}
                              />
                            ) : (
                              <font className="text-red-500 font-medium">
                                Pricing Rule not yet set
                              </font>
                            )}
                          </td>
                          <td
                            className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                            width="20%"
                          >
                            <div className="ml-2 h-7 flex items-center">
                              <Menu
                                as="div"
                                className="relative inline-block text-left z-50"
                              >
                                <div>
                                  <Menu.Button className="rounded-full flex items-center text-gray-700 hover:text-gray-600">
                                    <span className="sr-only">
                                      Open options
                                    </span>
                                    <CogIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </Menu.Button>
                                </div>

                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="origin-top-right absolute right-5 mt-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-150">
                                    <div className="py-1 z-150">
                                      <Menu.Item>
                                        {({ active }) => (
                                          <a
                                            className={classNames(
                                              active
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700",
                                              "block px-4 py-2 text-sm cursor-pointer"
                                            )}
                                            onClick={() => {
                                              setSidePanelExtra({
                                                childComponent: (
                                                  <AssignCompetitorForm
                                                    checkRequestError={
                                                      checkRequestError
                                                    }
                                                    isEdit={true}
                                                    product={competitor}
                                                    setOpen={setOpenSidePanel}
                                                    subtitle="Fill in the information below to edit competitor."
                                                    setShow={setShow}
                                                    setOpenSidePanel={
                                                      setOpenSidePanel
                                                    }
                                                    title={
                                                      "Editing " +
                                                      competitor.parentDomain
                                                    }
                                                    updateProductCompetitors={
                                                      updateProductCompetitors
                                                    }
                                                    updateSingleProductCompetitor={
                                                      updateSingleProductCompetitor
                                                    }
                                                    userId={userId}
                                                  />
                                                ),
                                              });
                                              setOpenSidePanel(true);
                                            }}
                                          >
                                            Edit Competitor
                                          </a>
                                        )}
                                      </Menu.Item>
                                      <Menu.Item>
                                        {({ active }) => (
                                          <a
                                            className={classNames(
                                              active
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700",
                                              "block px-4 py-2 text-sm cursor-pointer"
                                            )}
                                            onClick={() => {
                                              setSubmitError(null);
                                              setSidePanelExtra({
                                                childComponent: (
                                                  <AssignPricingRuleToCompetitorForm
                                                    checkRequestError={
                                                      checkRequestError
                                                    }
                                                    competitorName={
                                                      competitor.parentDomain
                                                    }
                                                    currency={currency}
                                                    disableSubmitButton={
                                                      disableSubmitButton
                                                    }
                                                    fetchAllRules={
                                                      fetchAllRules
                                                    }
                                                    isEdit={
                                                      competitor.pr_product_ruleId >
                                                      0
                                                    }
                                                    competitor={{
                                                      rowId: competitor.urlId,
                                                      itemType: 0,
                                                      itemId: competitor.urlId,
                                                      ruleId:
                                                        competitor.pr_product_ruleId,
                                                      explanation:
                                                        competitor.prod_rule_explanation,
                                                      ruleAssignId:
                                                        competitor.prodRuleAssignId,
                                                      assignLevel: 1,
                                                      assignItemId:
                                                        product.productId,
                                                      ruleArray:
                                                        competitor.ruleArray,
                                                      competitorId:
                                                        competitor.competitorId,
                                                    }}
                                                    setOpen={setOpenSidePanel}
                                                    submitError={submitError}
                                                    subtitle="Pricing rules affect the dynamic price of a product on a competitor level."
                                                    title={
                                                      <span>
                                                        Assign a pricing rule to{" "}
                                                        <span className="text-red-600">
                                                          {
                                                            competitor.parentDomain
                                                          }
                                                        </span>{" "}
                                                        for product:{" "}
                                                        <span className="text-red-600">
                                                          {product.name}
                                                        </span>
                                                      </span>
                                                    }
                                                    rules={rules}
                                                    updateSingleProductCompetitor={
                                                      updateSingleProductCompetitor
                                                    }
                                                    userId={userId}
                                                  />
                                                ),
                                                panelSize: "2xl",
                                              });
                                              setOpenSidePanel(true);
                                            }}
                                          >
                                            {competitor.pr_product_ruleId ||
                                            competitor.pr_competitor_ruleId > 0
                                              ? "Edit rule"
                                              : "Assign rule"}
                                          </a>
                                        )}
                                      </Menu.Item>
                                      <Menu.Item>
                                        {({ active }) => (
                                          <a
                                            className={classNames(
                                              active
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700",
                                              "block px-4 py-2 text-sm cursor-pointer"
                                            )}
                                            disabled={!competitor.scrapeId > 0}
                                            onClick={() => {
                                              setEmptyModal({
                                                childComponent: (
                                                  <ReportUrl
                                                    checkRequestError={
                                                      checkRequestError
                                                    }
                                                    action="reportUrl"
                                                    explanation="Report this url if you its not auditing properly, please explain
                                                    the issue in a few sentences below for us."
                                                    itemId={competitor.urlId}
                                                    setOpen={setEmptyModal}
                                                    setShow={setShow}
                                                    title="Report url"
                                                    userId={userId}
                                                  />
                                                ),
                                                open: true,
                                              });
                                            }}
                                          >
                                            Report url
                                          </a>
                                        )}
                                      </Menu.Item>
                                      <Menu.Item>
                                        {({ active }) => (
                                          <a
                                            className={classNames(
                                              active
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700",
                                              "block px-4 py-2 text-sm cursor-pointer"
                                            )}
                                            onClick={() => {
                                              setOpenActionModal({
                                                open: true,
                                                setOpen: setOpenActionModal,
                                                childComponent: (
                                                  <DeleteProductTemplate
                                                    actionFunc={
                                                      deleteAssignedCompetitor
                                                    }
                                                    disableSubmitButton={
                                                      disableSubmitButton
                                                    }
                                                    itemId={competitor.urlId}
                                                    setOpen={setOpenActionModal}
                                                    buttonDisabled={false}
                                                    buttonMessage={"Delete"}
                                                    message={
                                                      "Type delete below to delete " +
                                                      competitor.parentDomain +
                                                      " as competitor from " +
                                                      product.name +
                                                      "."
                                                    }
                                                  />
                                                ),
                                              });
                                            }}
                                          >
                                            Delete competitor
                                          </a>
                                        )}
                                      </Menu.Item>
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
