export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";

export const AUTH_RESET_REQUEST_START = "AUTH_RESET_REQUEST_START";
export const AUTH_RESET_REQUEST_SUCCESS = "AUTH_RESET_REQUEST_SUCCESS";

export const MY_ACCOUNT_UPDATE_REQUEST_START =
  "MY_ACCOUNT_UPDATE_REQUEST_START";
export const MY_ACCOUNT_UPDATE_REQUEST_SUCCESS =
  "MY_ACCOUNT_UPDATE_REQUEST_SUCCESS";

export const PASSWORD_UPDATE_REQUEST_START = "PASSWORD_UPDATE_REQUEST_START";
export const PASSWORD_UPDATE_REQUEST_SUCCESS =
  "PASSWORD_UPDATE_REQUEST_SUCCESS";

export const INTEGRATIONS_UPDATE_REQUEST_START =
  "INTEGRATIONS_UPDATE_REQUEST_START";
export const INTEGRATIONS_UPDATE_REQUEST_SUCCESS =
  "INTEGRATIONS_UPDATE_REQUEST_SUCCESS";

export const SET_AUTH_NOTIFY_MESSAGE_NULL = "SET_AUTH_NOTIFY_MESSAGE_NULL";

export const ON_UPDATE_CURRENCY_START = "ON_UPDATE_CURRENCY_START";
export const ON_UPDATE_CURRENCY_SUCCESS = "ON_UPDATE_CURRENCY_SUCCESS";

export const UPDATE_USER_SUBSCRIPTION_START = "UPDATE_USER_SUBSCRIPTION_START";
export const UPDATE_USER_SUBSCRIPTION_SUCCESS =
  "UPDATE_USER_SUBSCRIPTION_SUCCESS";

export const REQUEST_FAILED = "REQUEST_FAILED";
