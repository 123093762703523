import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import DashboardPriceChangesCard from "./components/DashboardPriceChangesCard";
import DashboardAccountSummaryCard from "./components/DashboardAccountSummaryCard";

import { makeApiCall } from "../../shared/makeApiCall";

import { useSearchParams } from "react-router-dom";

import ErrorModal from "../../components/ModalTemplates/ErrorModal";
import SuccessNotification from "../../components/SuccessNotification";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const posts = [
  {
    title: "1. Import products",
    href: "/products",
    description:
      "Import your products to start auditing them. You can either import one by one or via batch. Read more by going through our quick and easy guide!",
  },
  {
    title: "2. Setup competitors",
    href: "#",
    description: "Setup the competitors you want to monitor for your products.",
  },
  {
    title: "3. Setup pricing rules",
    href: "#",
    description:
      "Setup the pricing rules you want to apply in order to generate the smart prices.",
  },
  {
    title: "4. Let PryceHub Automation do the rest!",
    href: "#",
    description:
      "PryceHub's automation will automatically kick in and audit your products for you! Please make sure to go through our quick setup guide.",
  },
];

const Dashboard = (props) => {
  const { checkRequestError, userId, userStatus, userType } = props;

  const [accountSummary, setAccountSummary] = useState([]);
  const [errorModalOpen, setErrorModalOpen] = useState({
    open: false,
    title: null,
    subtitle: null,
  });
  const [show, setShow] = useState({ show: false });
  const [priceChangesSummary, setPriceChangesSummary] = useState([]);
  const [summaryLoading, setSummaryLoading] = useState(true);
  const [userAccountExplanation, setUserAccountExplanation] = useState({
    div1: null,
    div2: null,
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const code = searchParams.get("code");
  const hmac = searchParams.get("hmac");
  const shop = searchParams.get("shop");

  const getShopifyPermanentToken = async function () {
    setErrorModalOpen({
      hideButton: true,
      hideIcon: true,
      open: true,
      title: "Syncing account with Shopify...",
    });
    const authData = {
      action: "generateShopifyPermanentToken",
      userId: userId,
      shop: shop,
      code: code,
      hmac: hmac,
      syncAccount: true,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setErrorModalOpen({
        open: false,
      });
      const priceChangeCall = getPriceChangeSummary();
      const accountSummaryCall = getAccountSummary();
      const finalResult = [await priceChangeCall, await accountSummaryCall];
      setShow({
        show: true,
        title: "Account synced with Shopify, you're set!",
      });
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle:
            "We could not sync with your Shopify account, please try again.",
        });
      }
    }
  };

  useEffect(() => {
    if (code != null && hmac != null && shop != null) {
      getShopifyPermanentToken();
    }
  }, [code, shop]);

  const getPriceChangeSummary = async function () {
    const authData = {
      action: "getPriceChangeSummary",
      userId: userId,
    };
    let callResult = await makeApiCall(authData);
    if (callResult.success) {
      setPriceChangesSummary(callResult.data);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Oops.",
          subtitle: callResult.data.subtitle,
        });
      }
    }
  };

  const getAccountSummary = async function () {
    const authData = {
      action: "getAccountSummary",
      userId: userId,
    };
    let callResult = await makeApiCall(authData);
    if (callResult.success) {
      setAccountSummary(callResult.data);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Oops.",
          subtitle: callResult.data.subtitle,
        });
      }
    }
  };

  const getTheSummaries = async function () {
    const priceChangeCall = getPriceChangeSummary();
    const accountSummaryCall = getAccountSummary();
    const finalResult = [await priceChangeCall, await accountSummaryCall];
    setSummaryLoading(false);
  };

  useEffect(() => {
    document.title = "Dashboard";

    getUserPlanExplanation();
    getTheSummaries();
  }, []);

  const getUserPlanExplanation = () => {
    switch (userType) {
      case "1":
        setUserAccountExplanation({
          div1: "You are on the Free plan.",
          div2: "Your plan includes 10 products, 3 competitors and more features forever!",
        });
        break;
      case "2":
        setUserAccountExplanation({
          div1: "You are on the Standard plan.",
          div2: "Your plan includes 50 products, unlimited competitors and more features!",
        });
        break;
      case "3":
        setUserAccountExplanation({
          div1: "You are on the Standard plan.",
          div2: "Your plan includes 2000 products, unlimited competitors and more features!",
        });
        break;
      case "4":
        setUserAccountExplanation({
          div1: "You are on the Standard plan.",
          div2: "Your plan includes 10000 products, unlimited competitors and more features!",
        });
        break;
    }
  };

  return (
    <div className="bg-gray-50">
      {/* <AdminLayout /> */}
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900">Dashboard</h1>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          {userStatus == 2 ? (
            <div>
              <div className="px-4 py-6 sm:px-0 bg-white rounded-xl">
                <div>
                  <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 px-5">
                    <DashboardPriceChangesCard
                      priceChangesSummary={priceChangesSummary}
                    />
                    <DashboardAccountSummaryCard
                      accountSummary={accountSummary}
                    />
                  </dl>
                </div>
              </div>
              <div className="mt-10 px-4 sm:px-0 bg-white rounded-xl">
                {/* Thank you area starts */}
                <div className="bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-12 lg:pb-28 lg:px-8">
                  <div className="relative max-w-lg mx-auto divide-y-2 divide-gray-200 lg:max-w-7xl">
                    <div>
                      <h2 className="text-3xl tracking-tight font-extrabold text-indigo-600 sm:text-4xl">
                        Thank you for using PryceHub!
                      </h2>
                      <div className="mt-3 sm:mt-4 lg:grid lg:gap-1 lg:items-center">
                        <p className="text-xl text-gray-500">
                          {userAccountExplanation.div1}
                        </p>
                        <p className="text-xl text-gray-500">
                          {userAccountExplanation.div2}
                        </p>
                        <p className="text-xl text-gray-500">
                          Feel free at any point to ask questions to our support
                          team via the live chat.
                        </p>
                      </div>
                    </div>
                    <div className="mt-6 pt-10 grid gap-16 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">
                      <div className="sm:col-span-2">
                        <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
                          Getting started!
                        </h2>
                        <p className="text-xl text-gray-500">
                          Jump right away to importing your products and enjoy!
                        </p>
                      </div>
                      {posts.map((post, index) => (
                        <div key={index}>
                          {/* <div className="">
                        <UsersIcon
                          className="h-10 w-10 text-white bg-indigo-500 rounded-md p-3"
                          aria-hidden="true"
                        />
                      </div> */}
                          <div className="mt-2 block">
                            <p className="text-xl font-semibold text-gray-900">
                              {post.title}
                            </p>
                            <p className="mt-3 text-base text-gray-500">
                              {post.description}
                            </p>
                          </div>
                          <div
                            className={classNames(
                              index === 0 ? "mt-3" : "hidden",
                              ""
                            )}
                          >
                            <a
                              href={post.href}
                              className="text-base font-semibold text-indigo-600 hover:text-indigo-500"
                            >
                              Read quick setup guide
                            </a>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {/* Thank you area ends */}
              </div>
            </div>
          ) : (
            "Please confirm your email address before moving on. You should have gotten an email from us."
          )}
        </div>
        <ErrorModal
          hideButton={errorModalOpen.hideButton}
          hideIcon={errorModalOpen.hideIcon}
          open={errorModalOpen.open}
          title={errorModalOpen.title}
          setOpen={setErrorModalOpen}
          subtitle={errorModalOpen.subtitle}
        />
        <SuccessNotification
          show={show.show}
          setShow={setShow}
          title={show.title}
        />
      </main>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
    userStatus: state.auth.userStatus,
    userType: state.auth.userType,
  };
};

export default connect(mapStateToProps)(Dashboard);
