import React from "react";

export default function CustomSelect(props) {
  const {
    className,
    error,
    label,
    name,
    onChange,
    options,
    labelOn,
    value,
    ...other
  } = props;

  return (
    <div>
      {labelOn ? (
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-900"
        >
          {label}
        </label>
      ) : null}
      <div className="mt-1">
        <select
          name={name}
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none sm:text-sm rounded-md"
          value={value}
          onChange={onChange}
          {...other}
        >
          {options.map((item) => (
            <option key={item.value} value={item.value}>
              {item.name}
            </option>
          ))}
        </select>
        {error ? <p className="font-medium text-red-500">{error}</p> : null}
      </div>
    </div>
  );
}
