import { useState, useEffect } from "react";

import { connect } from "react-redux";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";

import {
  BellIcon,
  CogIcon,
  CreditCardIcon,
  GlobeIcon,
  KeyIcon,
  NewspaperIcon,
  RefreshIcon,
  ViewGridAddIcon,
} from "@heroicons/react/outline";

import SuccessNotification from "../../components/SuccessNotification";

import ActionModal from "../../components/ActionModal";

import GlobalSettings from "./tabs/GlobalSettings";
import Integrations from "./tabs/Integrations";
import MyAccount from "./tabs/MyAccount";
import Notifications from "./tabs/Notifications";
import Password from "./tabs/Password";
import SubscriptionPlans from "./tabs/SubscriptionPlans";

import ErrorModal from "../../components/ModalTemplates/ErrorModal";

import { makeApiCall } from "../../shared/makeApiCall";

import * as actions from "../../store/actions/index";
import SidePanel from "../../components/SidePanel";
import SubscriptionInvoices from "./tabs/SubscriptionInvoices";
import PaymentMethod from "./tabs/PaymentMethod";

const subNavigation = [
  {
    name: "Account",
    icon: CogIcon,
    path: "/account",
  },
  {
    name: "Password",
    icon: KeyIcon,
    path: "/password",
  },
  {
    name: "Notifications",
    icon: BellIcon,
    path: "/notifications",
  },
  {
    name: "Subscription",
    icon: RefreshIcon,
    path: "/subscription-plans",
  },
  {
    name: "Payment Method",
    icon: CreditCardIcon,
    path: "/payment-method",
  },
  {
    name: "Invoices",
    icon: NewspaperIcon,
    path: "/invoices",
  },
  {
    name: "Integrations",
    icon: ViewGridAddIcon,
    path: "/integrations",
  },
  {
    name: "Global Settings",
    icon: GlobeIcon,
    path: "/global-settings",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Settings = (props) => {
  const {
    checkRequestError,
    currency,
    email,
    error,
    jwt,
    fname,
    lname,
    loading,
    notifyMessage,
    scrapeAllProductsEnabled,
    timezone,
    userId,
    website,
    onMakeMyAccountUpdateRequest,
    onSetNotificationMessageNull,
    onUpdateCurrencyRequest,
    stripePriceId,
    onUpdateUserStripeSubscription,
  } = props;

  const { tab } = useParams();

  const [apiDetails, setApiDetails] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [connectionLoading, setConnectionLoading] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState({
    open: false,
    title: null,
    subtitle: null,
  });
  const [formLoading, setFormLoading] = useState(false);
  const [globalSettings, setGlobalSettings] = useState(null);
  const [openActionModal, setOpenActionModal] = useState({
    open: false,
    title: null,
    subtitle: null,
  });
  const [openSidePanel, setOpenSidePanel] = useState(false);
  const [possibleTimezones, setPossibleTimezones] = useState([]);
  const [show, setShow] = useState({ show: false });
  const [searchParams, setSearchParams] = useSearchParams();
  const [sidePanelExtra, setSidePanelExtra] = useState({
    childComponent: null,
  });

  const updateMyAccount = (data) => {
    onMakeMyAccountUpdateRequest(userId, jwt, data);
  };

  const returnTabIndex = (tabString) => {
    var returnIndex = 0;
    switch (tabString) {
      case "account":
        returnIndex = 0;
        break;
      case "password":
        returnIndex = 1;
        break;
      case "notifications":
        returnIndex = 2;
        break;
      case "subscription-plans":
        returnIndex = 3;
        break;
      case "payment-method":
        returnIndex = 4;
        break;
      case "invoices":
        returnIndex = 5;
        break;
      case "integrations":
        returnIndex = 6;
        break;
      case "global-settings":
        returnIndex = 7;
        break;
    }
    return returnIndex;
  };

  useEffect(() => {
    if (error) {
      setErrorModalOpen({
        open: true,
        title: error.title,
        subtitle: error.subtitle,
      });
      onSetNotificationMessageNull();
    }
  }, [error]);

  useEffect(() => {
    if (notifyMessage) {
      setShow({ show: true, title: notifyMessage });
      onSetNotificationMessageNull();
    }
  }, [notifyMessage]);

  useEffect(() => {
    setCurrentTab(returnTabIndex(tab));
    fetchGlobalSettings();
    fetchUserIntegrationSettings();
    getPossibleTimezones();
  }, []);

  const fetchUserIntegrationSettings = async function () {
    const authData = {
      action: "fetchUserIntegrationSettings",
      userId: userId,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setApiDetails(callResult.data);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          subtitle: "We could not fetch the data, please refresh the page.",
        });
      }
    }
  };

  const fetchGlobalSettings = async function () {
    const authData = {
      action: "fetchGlobalSettings",
      userId: userId,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setGlobalSettings(callResult.data);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          subtitle: "We could not fetch the data, please refresh the page.",
        });
      }
    }
  };

  const updatePasswordFunc = async function (data) {
    setFormLoading(true);
    const authData = {
      action: "updatePassword",
      userId: userId,
      data: data,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setShow({ show: true, title: callResult.data.message });
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle:
            "We could not sync with your Shopify account, please try again.",
        });
      }
      setErrorModalOpen({
        open: true,
        title: callResult.data.title,
        subtitle: callResult.data.subtitle,
      });
    }
    setFormLoading(false);
  };

  const updateIntegrationsFunc = async function (data) {
    setFormLoading(true);
    const authData = {
      action: "updateUserIntegrationSettings",
      userId: userId,
      data: data,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setApiDetails(callResult.data.updatedInfoArray);
      setShow({ show: true, title: callResult.data.message });
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle:
            "We could not sync with your Shopify account, please try again.",
        });
      }
      setErrorModalOpen({
        open: true,
        title: callResult.data.title,
        subtitle: callResult.data.subtitle,
      });
    }
    setFormLoading(false);
  };

  const updateGlobalSettingsFunc = async function (data) {
    setFormLoading(true);
    const authData = {
      action: "updateGlobalSettings",
      userId: userId,
      data: data,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setGlobalSettings(data);
      onUpdateCurrencyRequest(
        callResult.data.currency,
        callResult.data.currencyLabel
      );
      setShow({ show: true, title: callResult.data.message });
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle:
            "We could not sync with your Shopify account, please try again.",
        });
      }
      setErrorModalOpen({
        open: true,
        title: callResult.data.title,
        subtitle: callResult.data.subtitle,
      });
    }
    setFormLoading(false);
  };

  const getPossibleTimezones = async function () {
    const authData = {
      action: "getPossibleTimezones",
      userId: userId,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setPossibleTimezones(callResult.data.timezones);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: callResult.data.title,
          subtitle: callResult.data.subtitle,
        });
      }
    }
  };

  const testApiConnection = async function (
    apiUrl,
    apiType,
    apiUserName,
    apiPassword
  ) {
    setConnectionLoading(true);
    const authData = {
      action: "testUserIntegrationSettings",
      userId: userId,
      apiUrl: apiUrl,
      apiType: apiType,
      apiUserName: apiUserName,
      apiPassword: apiPassword,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setShow({ show: true, title: callResult.data.message });
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle:
            "We could not sync with your Shopify account, please try again.",
        });
      }
      setErrorModalOpen({
        open: true,
        title: callResult.data.title,
        subtitle: callResult.data.subtitle,
      });
    }
    setConnectionLoading(false);
  };

  const renderComponent = (index) => {
    switch (index) {
      case 0:
        return (
          <MyAccount
            key={0}
            actionFunc={updateMyAccount}
            fname={fname}
            lname={lname}
            loading={loading}
            possibleTimezones={possibleTimezones}
            email={email}
            scrapeAllProductsEnabled={scrapeAllProductsEnabled}
            searchParams={searchParams}
            setOpenActionModal={setOpenActionModal}
            timezone={{ value: timezone, label: timezone }}
            userId={userId}
            website={website}
          />
        );
      case 1:
        return (
          <Password
            key={1}
            actionFunc={updatePasswordFunc}
            loading={formLoading}
            searchParams={searchParams}
          />
        );
      case 2:
        return <Notifications key={2} searchParams={searchParams} />;
      case 3:
        return (
          <SubscriptionPlans
            checkRequestError={checkRequestError}
            currentStripePriceId={stripePriceId}
            jwt={jwt}
            onUpdateUserStripeSubscription={onUpdateUserStripeSubscription}
            key={3}
            searchParams={searchParams}
            setErrorModalOpen={setErrorModalOpen}
            setShow={setShow}
            setOpenActionModal={setOpenActionModal}
            setOpenSidePanel={setOpenSidePanel}
            setSidePanelExtra={setSidePanelExtra}
            userId={userId}
          />
        );
      case 4:
        return (
          <PaymentMethod
            checkRequestError={checkRequestError}
            onUpdateUserStripeSubscription={onUpdateUserStripeSubscription}
            key={4}
            searchParams={searchParams}
            currentStripePriceId={stripePriceId}
            userId={userId}
          />
        );
      case 5:
        return <SubscriptionInvoices key={5} userId={userId} />;
      case 6:
        return (
          <Integrations
            key={6}
            actionFunc={updateIntegrationsFunc}
            apiDetails={apiDetails}
            connectionLoading={connectionLoading}
            loading={formLoading}
            searchParams={searchParams}
            testApiConnection={testApiConnection}
          />
        );
      case 7:
        return (
          <GlobalSettings
            key={7}
            actionFunc={updateGlobalSettingsFunc}
            currency={currency}
            globalSettings={globalSettings}
            loading={formLoading}
            searchParams={searchParams}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="bg-gray-50">
        {/* <AdminLayout /> */}
        <header className="bg-white shadow">
          <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold text-gray-900">Settings</h1>
          </div>
        </header>
        <main>
          <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
            <div className="px-4 py-6 sm:px-0">
              <div className="bg-white rounded-lg shadow overflow-hidden">
                <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                  <aside className="py-6 lg:col-span-3">
                    <nav className="space-y-1">
                      {subNavigation.map((item, index) => (
                        <a
                          key={item.name}
                          className={classNames(
                            index === currentTab
                              ? "bg-teal-50 border-teal-500 text-teal-700 hover:bg-teal-50 hover:text-teal-700 bg-gray-200"
                              : "border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900",
                            "group border-l-4 px-3 py-2 flex items-center text-sm font-medium w-full"
                          )}
                          aria-current={
                            index === currentTab ? "page" : undefined
                          }
                          href={"/settings" + item.path}
                        >
                          <item.icon
                            className={classNames(
                              index === currentTab
                                ? "text-teal-500 group-hover:text-teal-500"
                                : "text-gray-400 group-hover:text-gray-500",
                              "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                            )}
                            aria-hidden="true"
                          />
                          <span className="truncate">{item.name}</span>
                        </a>
                      ))}
                    </nav>
                  </aside>
                  {subNavigation.map((item, index) =>
                    index === currentTab ? renderComponent(index) : null
                  )}
                </div>
              </div>
            </div>
          </div>
          <ActionModal
            childComponent={openActionModal.childComponent}
            open={openActionModal.open}
            setOpen={setOpenActionModal}
          />
          <ErrorModal
            open={errorModalOpen.open}
            title={errorModalOpen.title}
            setOpen={setErrorModalOpen}
            subtitle={errorModalOpen.subtitle}
          />
          <SidePanel
            open={openSidePanel}
            setOpen={setOpenSidePanel}
            childComponent={sidePanelExtra.childComponent}
            size={sidePanelExtra.size}
          />
          <SuccessNotification
            show={show.show}
            setShow={setShow}
            title={show.title}
          />
        </main>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currency: state.auth.currency,
    email: state.auth.email,
    error: state.auth.error,
    jwt: state.auth.jwt,
    fname: state.auth.fname,
    lname: state.auth.lname,
    loading: state.auth.loading,
    notifyMessage: state.auth.notifyMessage,
    scrapeAllProductsEnabled: state.auth.scrapeAllProductsEnabled,
    timezone: state.auth.timezone,
    stripePriceId: state.auth.stripePriceId,
    userId: state.auth.userId,
    website: state.auth.website,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onMakeMyAccountUpdateRequest: (userId, jwt, data) =>
      dispatch(actions.makeMyAccountUpdateRequest(userId, jwt, data)),
    onUpdateCurrencyRequest: (currency, currencyLabel) =>
      dispatch(actions.onUpdateCurrencyRequest(currency, currencyLabel)),
    onSetNotificationMessageNull: () =>
      dispatch(actions.setNotificationMessageNull()),
    onUpdateUserStripeSubscription: (data) =>
      dispatch(actions.onUpdateUserStripeSubscription(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
