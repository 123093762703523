import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { CheckIcon } from "@heroicons/react/solid";

import { makeApiCall } from "../../../shared/makeApiCall";

import UpgradeSubscriptionTemplate from "../../../components/ModalTemplates/UpgradeSubscriptionTemplate";
import CancelSubscriptionTemplate from "../../../components/ModalTemplates/CancelSubscriptionTemplate";

import { pricePlans } from "../../../shared/pricePlans";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function SubscriptionPlans(props) {
  const {
    currentStripePriceId,
    onUpdateUserStripeSubscription,
    searchParams,
    setErrorModalOpen,
    setOpenActionModal,
    setShow,
    userId,
  } = props;

  const navigate = useNavigate();

  const [chosenTab, setChosenTab] = useState(0);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const session_id = searchParams.get("session_id");
  const status = searchParams.get("status");
  const pid = searchParams.get("pid");
  const message = searchParams.get("pid");

  const generateStripeSession = async function (priceId) {
    const authData = {
      action: "createStripeCheckoutSession",
      currentStripePriceId: currentStripePriceId,
      userId: userId,
      priceId: priceId,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      window.location = callResult.data.session.sessionUrl;
    } else {
      setErrorModalOpen({
        open: true,
        subtitle: "We could not load the page, please refresh your page.",
      });
    }
  };

  const makeUpdateUserStripeSubscription = async function () {
    const authData = {
      action: "retrieveStripeCheckoutSession",
      userId: userId,
      session_id: session_id,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      if (callResult.data.updateRequired) {
        onUpdateUserStripeSubscription(callResult.data);
        navigate(
          "/settings/subscription-plans?message=" + callResult.data.message
        );
      }
    } else {
      setErrorModalOpen({
        open: true,
        subtitle: "We could not update your subscription, please try again.",
      });
    }
  };

  const cancelSubscription = async function () {
    setDisableSubmitButton(true);
    const authData = {
      action: "cancelStripeSubscription",
      userId: userId,
    };
    let callResult = await makeApiCall(authData);

    setOpenActionModal({
      open: false,
      childComponent: null,
    });
    if (callResult.success) {
      onUpdateUserStripeSubscription(callResult.data);
      setShow({ show: true, title: callResult.data.message });
    } else {
      setErrorModalOpen({
        open: true,
        subtitle: "We could not update your subscription, please try again.",
      });
    }
    setDisableSubmitButton(false);
  };

  useEffect(() => {
    if (status == "completed" && session_id != null && pid != null) {
      makeUpdateUserStripeSubscription();
    }
  }, [session_id]);

  useEffect(() => {
    if (message != null) {
      setShow({ show: true, title: message });
    }
  }, [message]);

  return (
    <div className="lg:col-span-9 px-5">
      <div className="sm:flex sm:flex-col sm:align-center">
        <h1 className="text-5xl font-extrabold text-gray-900 sm:text-center">
          Pricing Plans
        </h1>
        <p className="mt-5 text-xl text-gray-500 sm:text-center">
          Start building for free, then add a site plan to go live. Account
          plans unlock additional features.
        </p>
        <div className="relative self-center mt-6 bg-gray-100 rounded-lg p-0.5 flex sm:mt-8">
          <button
            type="button"
            className={classNames(
              chosenTab === 0
                ? "bg-white border-gray-200 shadow-sm text-gray-900"
                : "border border-transparent text-gray-700",
              "relative w-1/2 rounded-md py-2 text-sm font-medium whitespace-nowrap focus:outline-none focus:z-10 sm:w-auto sm:px-8"
            )}
            onClick={() => setChosenTab(0)}
          >
            Monthly billing
          </button>
          <button
            type="button"
            className={classNames(
              chosenTab === 1
                ? "bg-white border-gray-200 shadow-sm text-gray-900"
                : "border border-transparent text-gray-700",
              "ml-0.5 relative w-1/2 rounded-md py-2 text-sm font-medium whitespace-nowrap focus:outline-none focus:z-10 sm:w-auto sm:px-8"
            )}
            onClick={() => setChosenTab(1)}
          >
            Yearly billing
          </button>
        </div>
      </div>
      <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-4">
        {pricePlans.map((tier) => {
          if (tier.type == chosenTab) {
            return (
              <div
                key={tier.name}
                className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200"
              >
                <div className="p-6">
                  <h2 className="text-lg leading-6 font-medium text-gray-900">
                    {tier.name}
                  </h2>
                  <div className="mt-8">
                    <span className="text-4xl font-extrabold text-gray-900">
                      ${tier.priceMonthly}
                    </span>
                    <p className="mt-4 text-sm text-gray-500">
                      {tier.description}
                    </p>
                  </div>
                  <button
                    onClick={() => {
                      if (
                        currentStripePriceId == pricePlans[0].priceId ||
                        currentStripePriceId == pricePlans[4].priceId
                      ) {
                        generateStripeSession(tier.priceId);
                      } else {
                        setOpenActionModal({
                          open: true,
                          setOpen: setOpenActionModal,
                          childComponent: (
                            <UpgradeSubscriptionTemplate
                              currentPlanName={tier.name}
                              isFree={tier.isFree}
                              onUpdateUserStripeSubscription={
                                onUpdateUserStripeSubscription
                              }
                              newPlanAmount={tier.priceMonthly}
                              newPlanType={tier.type}
                              priceId={tier.priceId}
                              setErrorModalOpen={setErrorModalOpen}
                              setOpen={setOpenActionModal}
                              setShow={setShow}
                              userId={userId}
                            />
                          ),
                        });
                      }
                    }}
                    className={classNames(
                      currentStripePriceId === tier.priceId
                        ? "bg-indigo-400 border-indigo-400"
                        : "bg-gray-800 border-gray-800",
                      "mt-8 block w-full border rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                    )}
                  >
                    {currentStripePriceId === tier.priceId
                      ? "Current plan"
                      : "Select plan"}
                  </button>
                </div>
                <div className="pt-6 pb-8 px-6">
                  <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">
                    What's included
                  </h3>
                  <ul role="list" className="mt-6 space-y-4">
                    {tier.includedFeatures.map((feature) => (
                      <li key={feature} className="flex space-x-3">
                        <CheckIcon
                          className="flex-shrink-0 h-5 w-5 text-green-500"
                          aria-hidden="true"
                        />
                        <span className="text-sm text-gray-500">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            );
          }
        })}
      </div>
      <div className="mt-12 mb-6 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-4">
        <button
          className="mt-8 block w-full bg-red-600 border rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-red-700"
          onClick={() =>
            setOpenActionModal({
              open: true,
              setOpen: setOpenActionModal,
              childComponent: (
                <CancelSubscriptionTemplate
                  cancelSubscription={cancelSubscription}
                  setOpen={setOpenActionModal}
                />
              ),
            })
          }
          disabled={disableSubmitButton}
        >
          {disableSubmitButton ? (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            "Cancel subscription"
          )}
        </button>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
    userStatus: state.auth.userStatus,
  };
};

export default connect(mapStateToProps)(SubscriptionPlans);
