export default function DashboardPriceChangesCard(props) {
  const { priceChangesSummary } = props;

  return (
    <div className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
      <dt>
        <p className="text-2xl font-medium text-gray-500 truncate">
          Product Price Changes
        </p>
      </dt>
      <dd className="grid grid-cols-2 pt-3 flex items-baseline md:pb-0 sm:pb-2">
        <p className="text-l font-semibold text-gray-500">Increased: </p>
        <p className="text-l font-semibold text-gray-900">
          {priceChangesSummary.increased}
        </p>
      </dd>
      <dd className="grid grid-cols-2 flex items-baseline md:pb-0 sm:pb-2">
        <p className="text-l font-semibold text-gray-500">Decreased: </p>
        <p className="text-l font-semibold text-gray-900">
          {priceChangesSummary.decreased}
        </p>
      </dd>
      <dd className="grid grid-cols-2 flex items-baseline md:pb-0 sm:pb-2">
        <p className="text-l font-semibold text-gray-500">Stayed same: </p>
        <p className="text-l font-semibold text-gray-900">
          {priceChangesSummary.stayedTheSame}
        </p>
      </dd>
    </div>
  );
}
