import MyAccountForm from "../../../components/forms/Settings/MyAccountForm";

export default function MyAccount(props) {
  const {
    fname,
    lname,
    email,
    loading,
    possibleTimezones,
    setOpenActionModal,
    scrapeAllProductsEnabled,
    timezone,
    website,
    actionFunc,
  } = props;

  return (
    <MyAccountForm
      actionFunc={actionFunc}
      email={email}
      fname={fname}
      lname={lname}
      loading={loading}
      possibleTimezones={possibleTimezones}
      scrapeAllProductsEnabled={scrapeAllProductsEnabled}
      setOpenActionModal={setOpenActionModal}
      timezone={timezone}
      website={website}
    />
  );
}
