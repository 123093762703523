import { Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

export default function SidePanel(props) {
  const { title, subtitle, setOpen } = props;

  return (
    <div className="py-6 px-4 bg-gray-100 sm:px-6">
      <div className="flex items-center justify-between">
        <Dialog.Title className="text-lg font-medium text-gray-900">
          {title}
        </Dialog.Title>
        <div className="ml-3 h-7 flex items-center">
          <button
            type="button"
            className="text-gray-400 hover:text-gray-500"
            onClick={() => setOpen(false)}
          >
            <span className="sr-only">Close panel</span>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </div>
      <div className="mt-1">
        <p className="text-sm text-gray-500">{subtitle}</p>
      </div>
    </div>
  );
}
