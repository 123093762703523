import { useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { ExclamationCircleIcon } from "@heroicons/react/outline";

import { useForm } from "../useForm";

import Input from "../controls/Input";

const initialFValues = {
  deleteInput: "",
};

export default function DeleteProductTemplate(props) {
  const { actionFunc, disableSubmitButton, index, itemId, message, setOpen } =
    props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("deleteInput" in fieldValues) {
      temp.deleteInput = "";
      if (fieldValues.deleteInput !== "delete") {
        temp.deleteInput = "Please type delete.";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const { values, errors, setErrors, handleInputChange, resetForm, setValues } =
    useForm(initialFValues, false, validate);

  useEffect(() => {
    setValues({ deleteInput: "" });
    setErrors({});
  }, [setErrors, setErrors]);

  const handleSubmit = () => {
    if (validate()) {
      resetForm();
      if (index >= 0) {
        actionFunc(index, itemId);
      } else {
        actionFunc(itemId);
      }
    }
  };

  return (
    <div>
      <div>
        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
          <ExclamationCircleIcon
            className="h-6 w-6 text-red-600"
            aria-hidden="true"
          />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title
            as="h3"
            className="text-lg leading-6 font-medium text-gray-900"
          >
            {message}
          </Dialog.Title>
          <div className="mt-2 sm:mx-0 md:mx-12 items-center">
            <Input
              error={errors.deleteInput}
              label={message}
              labelOn={false}
              name="deleteInput"
              onChange={handleInputChange}
              value={values.deleteInput}
              placeholder="Type delete here"
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm"
            />
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:col-start-2 sm:text-sm"
          disabled={disableSubmitButton}
          onClick={handleSubmit}
        >
          {disableSubmitButton ? (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            "Delete"
          )}
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
          onClick={() => setOpen({ open: false })}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
