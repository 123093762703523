import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/react/solid";
import {
  CursorClickIcon,
  FilterIcon,
  MailOpenIcon,
  UsersIcon,
} from "@heroicons/react/outline";
import { Popover, Transition } from "@headlessui/react";
import { useForm, Form } from "../../components/useForm";
import ErrorModal from "../../components/ModalTemplates/ErrorModal";
import Input from "../../components/controls/Input";
import { makeApiCall } from "../../shared/makeApiCall";
import Select from "react-select";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const initialFValues = {
  productId: "",
  competitors: "",
  startDate: new Date(),
  endDate: new Date(),
};

const Reports = (props) => {
  const { checkRequestError, userId } = props;

  const [chart1, setChart1] = useState({ labels: [], datasets: [] });
  const [competitors, setCompetitors] = useState([]);
  const [errorModalOpen, setErrorModalOpen] = useState({
    open: false,
    title: null,
    subtitle: null,
  });
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    handleMultipleInputChange,
  } = useForm(initialFValues, true, validate);

  const fetchAllProducts = async function (search) {
    setLoading(true);
    const authData = {
      action: "fetchProductsForDropdown",
      userId: userId,
      search: search,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setProducts(callResult.data.products);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle:
            "We could not sync with your Shopify account, please try again.",
        });
      }
      setErrorModalOpen({
        open: true,
        title: "Sorry!",
        subtitle: "We could not fetch the data, please refresh the page.",
      });
    }
    setLoading(false);
  };

  const fetchProductCompetitors = async function (productId) {
    const authData = {
      action: "fetchCompetitorsByProductId",
      userId: userId,
      productId: productId,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setCompetitors(callResult.data.competitors);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle:
            "We could not sync with your Shopify account, please try again.",
        });
      }
      setErrorModalOpen({
        open: true,
        title: "Sorry!",
        subtitle: "We could not fetch the data, please refresh the page.",
      });
    }
  };

  useEffect(() => {
    document.title = "Reports";

    fetchAllProducts("");
  }, []);

  const convertToDefEventPara = (name, value) => {
    return {
      target: {
        name,
        value,
      },
    };
  };

  const updateChart = async function (values) {
    setLoading(true);
    const authData = {
      action: "fetchProductPricingData",
      userId: userId,
      productId: values.productId,
      competitors: values.competitors,
      startDate: values.startDate,
      endDate: values.endDate,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setChart1(callResult.data.singleProductPrices);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle:
            "We could not sync with your Shopify account, please try again.",
        });
      }
      setErrorModalOpen({
        open: true,
        title: "Sorry!",
        subtitle: "We could not fetch the data, please refresh the page.",
      });
    }
    setLoading(false);
  };

  return (
    <div className="bg-gray-50">
      {/* <AdminLayout /> */}
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900">Reports</h1>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          {/* Charts starts */}
          <div className="px-4 py-6 sm:px-0 grid grid-cols-1">
            <div className="col-span-1">
              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        "text-white",
                        "group bg-yellow-400 px-4 py-2 border border-transparent shadow-sm font-medium rounded-md inline-flex items-center"
                      )}
                    >
                      <FilterIcon
                        className="-ml-1 mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                      Filter
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute z-10 transform mt-3 px-2 w-screen max-w-xs sm:px-0">
                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                          <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                            <div>
                              <label
                                htmlFor="category"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Product
                              </label>
                              <div className="mt-1">
                                <Select
                                  name="productId"
                                  options={products}
                                  onChange={(product) => {
                                    handleMultipleInputChange(
                                      { productLabel: product.label },
                                      "productId",
                                      product.value
                                    );
                                  }}
                                  placeholder=""
                                  defaultValue={{
                                    value: values.productId,
                                    label: values.productLabel,
                                  }}
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="category"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Competitors
                              </label>
                              <div className="mt-1">
                                <Select
                                  name="competitors"
                                  options={competitors}
                                  placeholder=""
                                />
                              </div>
                            </div>

                            {/* <DatePicker
                              selected={values.startDate}
                              onChange={(date) =>
                                handleInputChange(
                                  convertToDefEventPara("startDate", date)
                                )
                              }
                              customInput={
                                <Input
                                  label="Start Date"
                                  name="startDate"
                                  value={values.startDate}
                                  labelOn={true}
                                  type="text"
                                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                                />
                              }
                            />
                            <DatePicker
                              selected={values.endDate}
                              onChange={(date) =>
                                handleInputChange(
                                  convertToDefEventPara("endDate", date)
                                )
                              }
                              customInput={
                                <Input
                                  label="End Date"
                                  name="endDate"
                                  value={values.endDate}
                                  labelOn={true}
                                  type="text"
                                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                                />
                              }
                            /> */}
                            <button
                              type="button"
                              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 items-center"
                              onClick={() => {
                                updateChart(values);
                              }}
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </div>
          </div>
          {/* summary starts */}
          {/* summary ends */}
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <Line
                data={chart1}
                options={{
                  title: {
                    display: true,
                    text: "Average Rainfall per month",
                    fontSize: 20,
                  },
                  legend: {
                    display: true,
                    position: "right",
                  },
                  defaults: {
                    global: {
                      responsive: true,
                      maintainAspectRatio: false,
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
        <ErrorModal
          open={errorModalOpen.open}
          title={errorModalOpen.title}
          setOpen={setErrorModalOpen}
          subtitle={errorModalOpen.subtitle}
        />
      </main>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
  };
};

export default connect(mapStateToProps)(Reports);
