import { useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { makeApiCall } from "../../shared/makeApiCall";

import CardSetupForm from "../Templates/CardSetupForm";

const stripePromise = loadStripe(
  "pk_test_51LP6C3IARlAQy7PPHDFKd0aagDUianifqufsZ4aHnncYrY8mb4r5lPBFECAsVcUzr2vNP7MRfJGvvDQUERnrgeZa00yyOtBi42"
);

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function UpgradeSubscriptionTemplate(props) {
  const {
    currentPlanName,
    isFree,
    newPlanAmount,
    newPlanType,
    onUpdateUserStripeSubscription,
    priceId,
    setErrorModalOpen,
    setOpen,
    setShow,
    userId,
  } = props;

  const [amountDue, setAmountDue] = useState({
    amountDue: null,
    clientSecret: null,
    paymentMethodExists: true,
  });
  const [disableSubmitButton, setDisableSubmitButton] = useState(0);
  const [loadingData, setLoadingData] = useState(true);

  const getProratedAmountForUser = async function () {
    const authData = {
      action: "getProratedAmountForUser",
      userId: userId,
      priceId: priceId,
    };
    let callResult = await makeApiCall(authData);

    setLoadingData(false);
    if (callResult.success) {
      setAmountDue({
        amountDue: callResult.data.total,
        clientSecret: callResult.data.clientSecret,
        paymentMethodExists: callResult.data.paymentMethodExists,
      });
    } else {
      setErrorModalOpen({
        open: true,
        subtitle: "We could not fetch the data, please refresh the page.",
      });
    }
  };

  const makeUpdateUserStripeSubscription = async function () {
    const authData = {
      action: "updateUserStripeSubscription",
      userId: userId,
      newPriceId: priceId,
    };
    let callResult = await makeApiCall(authData);

    setLoadingData(false);
    if (callResult.success) {
      setOpen({ open: false });
      onUpdateUserStripeSubscription(callResult.data);
      setShow({ show: true, title: callResult.data.message });
    } else {
      setErrorModalOpen({
        open: true,
        subtitle: "We could not update your subscription, please try again.",
      });
    }
  };

  useEffect(() => {
    getProratedAmountForUser();
  }, []);

  return (
    <div className="w-full">
      {loadingData ? (
        <div className="w-full justify-center text-center">
          <svg
            className="animate-spin -ml-1 mr-3 h-7 w-7 text-indigo-700"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      ) : (
        <div>
          <div>
            <div className="text-center p-4">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Upgrade your subscription to {currentPlanName}?
              </h3>
            </div>
            <div className="text-center p-4 bg-gray-50">
              <h3 className="text-lg leading-6 text-gray-900 mt-2">
                {currentPlanName} plan
              </h3>
              {isFree ? (
                <h3 className="text-3xl leading-6 text-gray-900 mt-2">
                  Free forever
                </h3>
              ) : (
                <h3 className="text-3xl leading-6 text-gray-900 mt-2">
                  US${newPlanAmount}
                  <font className="text-lg">
                    /{newPlanType == 0 ? "mo" : "yr"}
                  </font>
                </h3>
              )}
            </div>
            <div className="text-center p-4">
              <h3 className="text-lg leading-6 text-gray-900 mt-2">
                Since you are already subscribed to {currentPlanName} plan, you
                will only pay the difference until the end of the current
                billing cycle.
              </h3>
              <h3 className="text-lg leading-6 text-gray-900 mt-2">
                The amount due today:{" "}
                <font className="font-medium">{amountDue.amountDue}</font>
              </h3>
              <h3 className="text-lg leading-6 text-gray-900 mt-2"></h3>
            </div>
            <div
              className={classNames(
                amountDue.paymentMethodExists ? "hidden" : "",
                "text-center p-4"
              )}
            >
              <Elements stripe={stripePromise}>
                <CardSetupForm clientSecret={amountDue.clientSecret} />
              </Elements>
            </div>
          </div>
          <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 sm:col-start-2 sm:text-sm"
              disabled={disableSubmitButton}
              onClick={() => {
                setDisableSubmitButton(true);
                makeUpdateUserStripeSubscription();
              }}
            >
              {disableSubmitButton ? (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                "Yes, upgrade my plan."
              )}
            </button>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
              onClick={() => setOpen({ open: false })}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
