import React, { useEffect, useState } from "react";

import { useForm, Form } from "../useForm";
import { makeApiCall } from "../../shared/makeApiCall";

import SidePanelHeader from "../SidePanelHeader";
import TooltipButton from "../../components/TooltipButton";

import Input from "../controls/Input";
import Select from "react-select";

import "wc-spinners/dist/hollow-dots-spinner.js";

const initialFValues = {
  itemType: 0,
  itemId: "",
  ruleId: "",
  explanation: "",
  assignLevel: 0,
  assignItemId: 0,
  isNewRule: 0,
  name: "",
  howMuch: 0,
  currencyOrPercentage: 0,
  highOrLow: 0,
  makeDefaultConfirmation: "",
};

export default function AssignPricingRuleToCompetitorForm(props) {
  const {
    checkRequestError,
    competitor,
    competitorName,
    currency,
    fetchAllRules,
    fromProductsPage,
    isEdit,
    rules,
    setOpen,
    subtitle,
    title,
    updateSingleProductCompetitor,
    userId,
  } = props;

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [explanation, setExplanation] = useState(null);
  const [submitError, setSubmitError] = useState(null);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("makeDefaultForCompetitor" in fieldValues) {
      temp.makeDefaultConfirmation = null;
      if (
        fieldValues.makeDefaultForCompetitor === "1" &&
        values.makeDefaultConfirmation !== "yes"
      ) {
        temp.makeDefaultConfirmation = "Please type yes to confirm.";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    handleMultipleInputChange,
    resetForm,
  } = useForm(initialFValues, true, validate);

  const assignCompetitorPricingRuleFunction = async function (data) {
    setSubmitError(null);
    setDisableSubmitButton(true);
    const authData = {
      action: "assignPricingRuleToCompetitor",
      userId: userId,
      data: { ...data },
    };
    let callResult = await makeApiCall(authData);
    setDisableSubmitButton(false);

    if (callResult.success) {
      if (callResult.data.refreshRules === true) {
        fetchAllRules();
      }

      if (fromProductsPage) {
        updateSingleProductCompetitor(
          callResult.data.rowId,
          callResult.data.productId,
          callResult.data.competitor,
          "Pricing rule assigned to competitor."
        );
      } else {
        updateSingleProductCompetitor(
          callResult.data.competitorId,
          callResult.data.competitor,
          "Pricing rule assigned to competitor."
        );
      }

      resetForm();
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setSubmitError("Your session expired. Please log back in.");
      }
      setSubmitError(callResult.data.subtitle);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      assignCompetitorPricingRuleFunction(values);
    }
  };

  useEffect(() => {
    if (competitor != null) {
      setValues({
        itemId: competitor.itemId,
        itemType: competitor.itemType,
        ruleId: competitor.ruleId,
        explanation: competitor.explanation,
        ruleAssignId: competitor.ruleAssignId,
        assignLevel: competitor.assignLevel,
        assignItemId: competitor.assignItemId,
        previousAssignLevel: competitor.assignLevel,
        previousAssignId: competitor.ruleAssignId,
        rowId: competitor.rowId,
        isNewRule: 0,
        name: "",
        howMuch: 0,
        currencyOrPercentage: 0,
        highOrLow: 0,
        isCustomMargin: 0,
        respectMinPlusOrMinus: 0,
        respectMinAmount: 0,
        respectMinCurrencyOrPercentage: 0,
        respectMaxPlusOrMinus: 0,
        respectMaxAmount: 0,
        respectMaxCurrencyOrPercentage: 0,
        preferenceEndWith: 0,
        makeDefaultForCompetitor: 0,
        competitorId: competitor.competitorId,
      });
      setExplanation(competitor.explanation);
    }
  }, [isEdit, competitor]);

  const convertToDefEventPara = (name, value) => {
    return {
      target: {
        name,
        value,
      },
    };
  };

  const handlePricingRuleChange = (newValue) => {
    const newValues = {
      ruleName: newValue.name,
      explanation: newValue.explanation,
    };

    handleMultipleInputChange(newValues, "ruleId", newValue.ruleId);
    setExplanation(newValue.explanation);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="flex-1 h-0 overflow-y-auto">
        <SidePanelHeader subtitle={subtitle} title={title} setOpen={setOpen} />
        <div className="flex-1 flex flex-col justify-between">
          <div className="px-4 divide-y divide-gray-500 sm:px-6">
            <div className="space-y-6 pt-6 pb-5">
              <div>
                <div className="flex space-x-2">
                  <label
                    htmlFor="rules"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Rules
                  </label>
                  <TooltipButton explanation="Choose a rule below that you have already created" />
                </div>
                <div className="mt-1">
                  <Select
                    name="rules"
                    options={rules}
                    onChange={handlePricingRuleChange}
                    defaultValue={competitor ? competitor.ruleArray : []}
                    placeholder=""
                    className="max-w-lg sm:max-w-xs"
                  />
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-900">
                  {explanation}
                </label>
              </div>
              <fieldset>
                <legend className="sr-only">Create new rule?</legend>
                <div className="relative flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="isNewRule"
                      aria-describedby="comments-description"
                      name="isNewRule"
                      type="checkbox"
                      className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      checked={values.isNewRule === "1" ? true : false}
                      onClick={(e) =>
                        handleInputChange(
                          convertToDefEventPara(
                            e.target.name,
                            e.target.checked ? "1" : "0"
                          )
                        )
                      }
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="comments"
                      className="font-medium text-gray-700"
                    >
                      Create new rule?
                    </label>
                    <span id="comments-description" className="text-gray-500">
                      <span className="sr-only">Create new rule?</span>
                    </span>
                  </div>
                </div>
              </fieldset>
              {values.isNewRule === "1" ? (
                <div>
                  <div className="flex-1 flex flex-col justify-between">
                    <div className="grid grid-cols-2 p-2">
                      <div className="sm:col-span-2">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Name
                        </h3>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">
                          Give a name to your pricing rule so its easy to
                          remember
                        </p>
                      </div>
                      <div className="sm:col-span-1">
                        <Input
                          label="Name of the rule"
                          name="name"
                          onChange={handleInputChange}
                          value={values.name}
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4">
                      <div className="grid grid-cols-6 p-2">
                        <div className="sm:col-span-6">
                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Position
                          </h3>
                          <p className="mt-1 max-w-2xl text-sm text-gray-500">
                            Choose the position you would like to be
                          </p>
                        </div>
                        <div className="sm:col-span-1">
                          <Input
                            label="How much higher or lower"
                            name="howMuch"
                            onChange={handleInputChange}
                            value={values.howMuch}
                            type="number"
                            step="0.01"
                            min="0"
                          />
                        </div>
                        <div className="sm:col-span-1 ml-0.5">
                          <select
                            id="currencyOrPercentage"
                            name="currencyOrPercentage"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                            onChange={handleInputChange}
                            value={values.currencyOrPercentage}
                          >
                            <option value={0}>{currency}</option>
                            <option value={1}>%</option>
                          </select>
                        </div>
                        <div className="sm:col-span-1 ml-0.5">
                          <select
                            id="highOrLow"
                            name="highOrLow"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                            value={values.highOrLow}
                            onChange={handleInputChange}
                          >
                            <option value={0}>higher</option>
                            <option value={1}>lower</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {values.assignLevel === 1 ? (
                <fieldset>
                  <legend className="sr-only">
                    Make default for {competitorName}?
                  </legend>
                  <div className="relative flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="makeDefaultForCompetitor"
                        aria-describedby="comments-description"
                        name="makeDefaultForCompetitor"
                        type="checkbox"
                        className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        checked={
                          values.makeDefaultForCompetitor === "1" ? true : false
                        }
                        onClick={(e) =>
                          handleInputChange(
                            convertToDefEventPara(
                              e.target.name,
                              e.target.checked ? "1" : "0"
                            )
                          )
                        }
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="comments"
                        className="font-medium text-gray-700"
                      >
                        Make default for {competitorName}?
                      </label>
                    </div>
                  </div>
                </fieldset>
              ) : null}
              {values.makeDefaultForCompetitor === "1" ? (
                <div className="grid grid-cols-2">
                  <div className="sm:col-span-2">
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      This will overwrite all product level rules for{" "}
                      <span className="text-gray-900">{competitorName}</span> on
                      product level as well. Type{" "}
                      <span className="text-gray-900">yes</span> below if you
                      are sure.
                    </p>
                  </div>
                  <div className="sm:col-span-1">
                    <Input
                      label="Are you sure you want to make it default?"
                      name="makeDefaultConfirmation"
                      error={errors.makeDefaultConfirmation}
                      onChange={handleInputChange}
                      placeholder="Type yes here"
                      value={values.makeDefaultConfirmation}
                      type="text"
                    />
                  </div>
                </div>
              ) : null}
              {submitError ? (
                <p className="text-md text-red-500">{submitError}</p>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {submitError ? (
        <div className="flex-shrink-0 px-4 py-4 flex">
          <span className="text-red-700">{submitError}</span>
        </div>
      ) : null}
      <div className="flex-shrink-0 px-4 py-4 flex justify-end">
        <button
          type="button"
          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
          onClick={() => setOpen(false)}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 items-center"
          disabled={disableSubmitButton}
        >
          {disableSubmitButton ? (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            "Save"
          )}
        </button>
      </div>
    </Form>
  );
}
