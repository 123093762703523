import React, { useEffect, useState } from "react";
import { useForm } from "../../useForm";

import Input from "../../controls/Input";
import CustomSelect from "../../controls/CustomSelect";

import { makeApiCall } from "../../../shared/makeApiCall";
import ErrorModal from "../../ModalTemplates/ErrorModal";

const initialFValues = {
  fullname: "",
  email: "",
  password: "",
  website: "",
  currency: "",
};

const currencyOptions = [
  { name: "Select currency", value: "" },
  { name: "CAD", value: 1 },
  { name: "USD", value: 2 },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Step1(props) {
  const { authSuccess, searchParams } = props;

  const code = searchParams.get("code");
  const hmac = searchParams.get("hmac");
  const shop = searchParams.get("shop");

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState({
    open: false,
    title: null,
    subtitle: null,
  });
  const [websiteInputDisabled, setWebsiteInputDisabled] = useState(false);

  const createNewAccount = async function (values) {
    const authData = {
      action: "createNewAccount",
      data: values,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setDisableSubmitButton(false);
      setTimeout(() => {
        authSuccess(
          callResult.data.userInfo.jwt,
          callResult.data.userInfo.refreshToken,
          callResult.data.userInfo.expirationDate,
          callResult.data.userInfo.userId,
          callResult.data.userInfo.fname,
          callResult.data.userInfo.lname,
          callResult.data.userInfo.email,
          callResult.data.userInfo.currency,
          callResult.data.userInfo.currencyLabel,
          callResult.data.userInfo.minValue,
          callResult.data.userInfo.website,
          callResult.data.userInfo.timezone,
          callResult.data.userInfo.scrapeAllProductsEnabled,
          callResult.data.userInfo.showOnboarding,
          callResult.data.userInfo.userStatus,
          callResult.data.userInfo.userType,
          callResult.data.userInfo.stripePriceId
        );
      }, 1000);
    } else {
      setDisableSubmitButton(false);
      setErrorModalOpen({
        open: true,
        title: "Oops.",
        subtitle: callResult.data.subtitle,
      });
    }
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("fullname" in fieldValues) {
      temp.fullname = null;
      if (fieldValues.fullname === "") {
        temp.fullname = "This field is required.";
      }
    }

    if ("email" in fieldValues) {
      temp.email = null;
      if (fieldValues.fullname === "") {
        temp.email = "This field is required.";
      }
    }

    if ("password" in fieldValues) {
      temp.password = null;
      if (fieldValues.password === "") {
        temp.password = "This field is required.";
      }

      if (fieldValues.password.length < 8) {
        temp.password = "Password must be at least 8 characters.";
      }
    }

    if ("website" in fieldValues) {
      temp.website = null;
      if (fieldValues.website === "") {
        temp.website = "This field is required.";
      }
    }

    if ("currency" in fieldValues) {
      temp.currency = null;
      if (fieldValues.currency === "") {
        temp.currency = "This field is required.";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const {
    values,
    errors,
    setErrors,
    handleInputChange,
    handleMultipleInputChange,
  } = useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableSubmitButton(true);
    if (validate()) {
      createNewAccount(values);
    } else {
      setDisableSubmitButton(false);
    }
  };

  useEffect(() => {
    if (hmac != null && shop != null) {
      const newValues = { shopifyCode: code, hmacCode: hmac };
      handleMultipleInputChange(newValues, "website", shop);
      setWebsiteInputDisabled(true);
    }
  }, [hmac, shop]);

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-5xl font-extrabold text-gray-900">
          Let's get started
        </h2>
        <p className="mt-3 text-center text-xl text-gray-600">
          Set up is fast, easy and no credit card is required!
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-xl">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <Input
                label="Full name"
                name="fullname"
                onChange={handleInputChange}
                value={values.fullname}
                labelOn={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                error={errors.fullname}
              />
            </div>
            <div>
              <Input
                label="Email address"
                name="email"
                onChange={handleInputChange}
                value={values.email}
                labelOn={true}
                type="email"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                error={errors.email}
              />
            </div>

            <div>
              <Input
                label="Password"
                name="password"
                onChange={handleInputChange}
                value={values.password}
                labelOn={true}
                type="password"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                error={errors.password}
              />
            </div>
            <div
              className={classNames(websiteInputDisabled ? "hidden" : "", "")}
            >
              <label
                htmlFor="website"
                className="block text-sm font-medium text-gray-700"
              >
                Website
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                  https://
                </span>
                <input
                  type="text"
                  name="website"
                  id="website"
                  onChange={handleInputChange}
                  value={values.website}
                  className={classNames(
                    websiteInputDisabled ? "bg-gray-50" : "",
                    "flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                  )}
                  placeholder="www.example.com"
                  disabled={websiteInputDisabled}
                />
              </div>
              {errors.website ? (
                <p className="font-medium text-red-500">{errors.website}</p>
              ) : null}
            </div>
            <div>
              <CustomSelect
                label="Currency"
                name="currency"
                onChange={handleInputChange}
                value={values.currency}
                labelOn={true}
                options={currencyOptions}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                error={errors.currency}
              />
            </div>

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                disabled={disableSubmitButton}
              >
                {disableSubmitButton ? (
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  "Create account"
                )}
              </button>
            </div>
            <div className="flex items-center justify-between">
              <p className="mt-1 text-center text-sm text-gray-600">
                By creating an account, you agree to our Terms of Service and
                acknowledge that you read our Privacy Policy.
              </p>
            </div>
          </form>
        </div>
      </div>
      <ErrorModal
        open={errorModalOpen.open}
        title={errorModalOpen.title}
        setOpen={setErrorModalOpen}
        subtitle={errorModalOpen.subtitle}
      />
    </div>
  );
}
