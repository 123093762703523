import { useState } from "react";
import PasswordForm from "../../../components/forms/Settings/PasswordForm";

export default function Password(props) {
  const { actionFunc, error, loading } = props;

  return (
    <PasswordForm actionFunc={actionFunc} error={error} loading={loading} />
  );
}
