import React, { useEffect } from "react";

import Input from "../../controls/Input";
import InputWithAddon from "../../controls/InputWithAddon";
import CustomSelect from "../../controls/CustomSelect";

import { useForm, Form } from "../../useForm";

const fieldOptions = [
  { name: "Regular price", value: 0 },
  { name: "Sales price", value: 1 },
];

const syncProductsFields = [
  { name: "No", value: 0 },
  { name: "Yes", value: 1 },
];

const platformOptions = [
  { name: "Select a platform", value: 0 },
  { name: "Shopify", value: 2 },
  { name: "WordPress", value: 3 },
  { name: "Zoey", value: 1 },
];

const initialFValues = {
  apiId: 0,
  apiUrl: "",
  apiType: "",
  apiUserName: "",
  apiPassword: "",
  apiPasswordOriginal: 0,
  field: 0,
  syncProducts: 0,
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function IntegrationsForm(props) {
  const {
    actionFunc,
    apiDetails,
    connectionLoading,
    error,
    loading,
    testApiConnection,
  } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("apiType" in fieldValues) {
      temp.apiType = null;
      if (!fieldValues.apiType > 0) {
        temp.apiType = "This field is required.";
      }
    }

    if ("apiUrl" in fieldValues) {
      temp.apiUrl = null;
      if (fieldValues.apiUrl === "") {
        temp.apiUrl = "This field is required.";
      }
    }

    if ("apiUserName" in fieldValues) {
      temp.apiUserName = null;
      if (fieldValues.apiUserName === "") {
        temp.apiUserName = "This field is required.";
      }
    }

    if ("apiPassword" in fieldValues) {
      temp.apiPassword = null;
      if (fieldValues.apiPassword === "") {
        temp.apiPassword = "This field is required.";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      actionFunc(values);
    }
  };

  useEffect(() => {
    if (apiDetails != null) {
      setValues({
        apiId: apiDetails.apiId,
        apiUrl: apiDetails.apiUrl,
        apiUserName: apiDetails.apiUserName,
        apiPassword: apiDetails.apiPassword,
        apiType: apiDetails.apiType,
        field: apiDetails.field,
        syncProducts: apiDetails.syncProducts,
      });
    }
  }, [apiDetails]);

  const getUsernameText = () => {
    var returnText = "";

    switch (values.apiType) {
      case "1":
        returnText = "Username";
        break;
      case "2":
        returnText = "API key";
        break;
      case "3":
        returnText = "Consumer key";
        break;
    }

    return returnText;
  };

  const getApiSecretText = () => {
    var returnText = "";

    switch (values.apiType) {
      case "1":
        returnText = "Password";
        break;
      case "2":
        returnText = "Admin API access token";
        break;
      case "3":
        returnText = "Consumer secret";
        break;
    }

    return returnText;
  };

  const getApiUrlText = () => {
    var returnText = "";

    switch (values.apiType) {
      case "1":
        returnText = "Api Url";
        break;
      case "2":
        returnText = "Store Link";
        break;
    }

    return returnText;
  };

  return (
    <Form
      classes="divide-y divide-gray-200 lg:col-span-9"
      onSubmit={handleSubmit}
    >
      <div className="py-6 px-4 sm:p-6 lg:pb-8">
        <div className="mt-6 grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-6">
            <CustomSelect
              label="Platform"
              name="apiType"
              onChange={handleInputChange}
              value={values.apiType}
              labelOn={true}
              options={platformOptions}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              error={errors.apiType}
            />
          </div>
        </div>
        {values.apiType > 0 && values.apiType != 2 ? (
          <div>
            <div
              className={classNames(
                values.apiType === 3 ? "hidden" : "",
                "mt-6 grid grid-cols-12 gap-6"
              )}
            >
              <div className="col-span-12 sm:col-span-6">
                <Input
                  label={getApiUrlText()}
                  name="apiUrl"
                  onChange={handleInputChange}
                  value={values.apiUrl}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  error={errors.apiUrl}
                />
              </div>
            </div>
            <div className="mt-6 grid grid-cols-12 gap-6">
              <div className="col-span-12 sm:col-span-6">
                <Input
                  label={getUsernameText()}
                  name="apiUserName"
                  onChange={handleInputChange}
                  value={values.apiUserName}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  error={errors.apiUserName}
                />
              </div>
            </div>
            <div className="mt-6 grid grid-cols-12 gap-6">
              <div className="col-span-12 sm:col-span-6">
                <Input
                  label={getApiSecretText()}
                  name="apiPassword"
                  onChange={handleInputChange}
                  value={values.apiPassword}
                  labelOn={true}
                  type="password"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  error={errors.apiPassword}
                />
              </div>
            </div>
            <div className="mt-6 grid grid-cols-12 gap-6">
              <div className="col-span-12 sm:col-span-6">
                <CustomSelect
                  label="Price field to edit"
                  name="field"
                  onChange={handleInputChange}
                  value={values.field}
                  labelOn={true}
                  options={fieldOptions}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  error={errors.field}
                />
              </div>
            </div>
            <div className="mt-6 grid grid-cols-12 gap-6">
              <div className="col-span-12 sm:col-span-6">
                <CustomSelect
                  label="Sync product costs?"
                  name="syncProducts"
                  onChange={handleInputChange}
                  value={values.syncProducts}
                  labelOn={true}
                  options={syncProductsFields}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  error={errors.syncProducts}
                />
              </div>
            </div>
            <div className="mt-6 grid grid-cols-12 gap-6">
              <div className="col-span-12 sm:col-span-6">
                <button
                  type="button"
                  className="bg-sky-700 border border-gray-300 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white bg-indigo-600 hover:bg-sky-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                  disabled={connectionLoading}
                  onClick={() =>
                    testApiConnection(
                      values.apiUrl,
                      values.apiType,
                      values.apiUserName,
                      values.apiPassword
                    )
                  }
                >
                  {connectionLoading ? (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : null}
                  Test connection
                </button>
              </div>
            </div>
          </div>
        ) : values.apiType == 2 ? (
          <div className="mt-3">
            <p>
              Please download our app on the Shopify App store and open it from
              there. It will automatically sync your Shopify account with
              PryceHub.
            </p>
          </div>
        ) : (
          ""
        )}
      </div>

      <div
        className={classNames(
          values.apiType == 2 ? "hidden" : "",
          "pt-6 divide-y divide-gray-200"
        )}
      >
        <div className="grid grid-cols-1">
          <div className="px-4 col-span-1 sm:col-span-1 flex justify-end font-medium text-red-500">
            {error ? error.subtitle : null}
          </div>
          <div className="mt-4 py-4 px-4 sm:px-6 col-span-1 sm:col-span-1 flex justify-end">
            <button
              type="submit"
              className="ml-5 bg-sky-700 border border-gray-300 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white bg-indigo-600 hover:bg-sky-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
              disabled={loading}
            >
              {loading ? (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : null}
              Save
            </button>
          </div>
        </div>
      </div>
    </Form>
  );
}
