import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { makeApiCall } from "../../../shared/makeApiCall";

import { config } from "../../../constants";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const convertStripeCash = (amount) => {
  var actualAmount = amount % 100 == 0 ? amount / 100 + ".00" : amount / 100;
  return "$" + actualAmount;
};

const getStripeDate = (date) => {
  var date = new Date(date * 1000);
  return (
    date.toLocaleString("default", { month: "long" }) +
    " " +
    date.getDate() +
    ", " +
    date.getFullYear()
  );
};

function SubscriptionPlans(props) {
  const { setErrorModalOpen, userId } = props;

  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);

  const getUserStripeInvoices = async function () {
    const authData = {
      action: "getUserStripeInvoices",
      userId: userId,
    };
    let callResult = await makeApiCall(authData);

    setLoading(false);
    if (callResult.success) {
      setInvoices(callResult.data.invoices);
    } else {
      setErrorModalOpen({
        open: true,
        subtitle: callResult.data.subtitle,
      });
    }
  };

  useEffect(() => {
    getUserStripeInvoices();
  }, []);

  return (
    <div className="lg:col-span-9 px-5">
      {/* table starts */}
      <div className="flex flex-col pt-6">
        <div className="-my-2 overflow-x-visible sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-visible border-b border-gray-200 sm:rounded-lg w-full my-5">
              <table className="w-full divide-y divide-gray-200">
                <thead className="bg-gray-50 break-words">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      style={{ width: "45%" }}
                    >
                      Invoices
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      style={{ width: "40%" }}
                    ></th>

                    <th
                      scope="col"
                      className="relative px-6 py-3"
                      style={{ width: "15%" }}
                    >
                      <span className="sr-only"></span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {loading ? (
                    ""
                  ) : invoices.length > 0 ? (
                    invoices.map((invoice, index) => (
                      <tr key={invoice.id}>
                        <td
                          className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                          style={{ width: "45%" }}
                        >
                          {getStripeDate(invoice.created)}
                        </td>
                        <td
                          className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                          style={{ width: "40%" }}
                        >
                          {convertStripeCash(invoice.amount_due)}
                        </td>

                        <td
                          className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                          style={{ width: "15%" }}
                          onClick={() =>
                            window.open(
                              config.url.API_URL +
                                "/settings/invoice/" +
                                invoice.id
                            )
                          }
                        >
                          <button className="relative inline-flex items-center px-4 py-2 rounded border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none">
                            Download PDF
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                        style={{ width: "45%" }}
                      >
                        No invoices at the moment, if there is a mistake please
                        contact support.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
    userStatus: state.auth.userStatus,
  };
};

export default connect(mapStateToProps)(SubscriptionPlans);
